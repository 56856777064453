// Angular
import { OverlayModule } from '@angular/cdk/overlay';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { GestureConfig, MatProgressSpinnerModule } from '@angular/material';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// NgBootstrap
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
// NGRX
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DataTablesModule } from 'angular-datatables';
// Hammer JS
import 'hammerjs';
import * as json from 'highlight.js/lib/languages/json';
import * as scss from 'highlight.js/lib/languages/scss';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as xml from 'highlight.js/lib/languages/xml';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { CurrencyMaskModule } from "ng2-currency-mask";
// Highlight JS
import { HighlightLanguage, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { NgxMaskModule } from 'ngx-mask';
// Perfect Scroll bar
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxPrintModule } from 'ngx-print';
import { ToastrModule } from 'ngx-toastr';
import { NgbDateCustomParserFormatter } from '../../src/app/componentes/dataPickerFormat/dataPickerFormat';
// Modules
import { AppRoutingModule } from './app-routing.module';
// Copmponents
import { AppComponent } from './app.component';
import { ComponentesModule } from './componentes/componentes.module';
import { CoreModule } from './core/core.module';
// State
import { metaReducers, reducers } from './core/reducers';
// CRUD
import { HttpUtilsService, LayoutUtilsService, TypesUtilsService } from './core/_base/crud';
// Layout Services
import { KtDialogService, LayoutConfigService, LayoutRefService, MenuAsideService, MenuConfigService, MenuHorizontalService, PageConfigService, SplashScreenService, SubheaderService } from './core/_base/layout';
// Metronic Services
import { DataTableService } from './core/_base/metronic';
// Config
import { LayoutConfig } from './core/_config/default/layout.config';
import { PipesModule } from './pipes/pipes.module';
import { AcessoRestritoGuard } from './services/guard/acesso-restrito.guard';
import { HttpService } from './services/http.service';
import { LoadingService } from './services/loading.service';
// Auth
import { AuthModule } from './views/pages/auth/auth.module';
// Partials
import { PartialsModule } from './views/partials/partials.module';
import { ThemeModule } from './views/themes/demo2/theme.module';
import { CookieService } from 'ngx-cookie-service';
import { GoogleAnalyticsService } from './services/google-analytics.service';

registerLocaleData(ptBr);
// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
}

export function hljsLanguages(): HighlightLanguage[] {
	return [
		{ name: 'typescript', func: typescript },
		{ name: 'scss', func: scss },
		{ name: 'xml', func: xml },
		{ name: 'json', func: json }
	];
}
export class NgbDateNativeAdapter extends NgbDateAdapter<Date> {
	fromModel(date: Date): NgbDateStruct {
		return (date && date.getFullYear) ? { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() } : null;
	}

	toModel(date: NgbDateStruct): Date {
		return date ? new Date(date.year, date.month - 1, date.day) : null;
	}
}

@NgModule({
	declarations: [AppComponent
	],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		FormsModule,
		HttpModule,
		AppRoutingModule,
		HttpClientModule,
		NgxPermissionsModule.forRoot(),
		PartialsModule,
		CoreModule,
		OverlayModule,
		ComponentesModule,
		StoreModule.forRoot(reducers, { metaReducers }),
		EffectsModule.forRoot([]),
		StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
		StoreDevtoolsModule.instrument(),
		AuthModule.forRoot(),
		NgbModule.forRoot(),
		NgbModalModule.forRoot(),
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		InlineSVGModule.forRoot(),
		ThemeModule,
		DataTablesModule,
		ToastrModule.forRoot(),
		NgxMaskModule.forRoot(),
		CurrencyMaskModule,
		SweetAlert2Module.forRoot(),
		PipesModule,
		RecaptchaModule,
		RecaptchaFormsModule,
		NgxPrintModule
	],
	exports: [],
	providers: [
		LayoutConfigService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		KtDialogService,
		DataTableService,
		SplashScreenService,
		{
			provide: RECAPTCHA_SETTINGS,
			useValue: {
				siteKey: '6Ld3vsMUAAAAABbxt9o6GyGanC6nnAROV_Lp9aSs',
			} as RecaptchaSettings,
		},
		{ provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
		{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		},
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService], multi: true
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: { languages: hljsLanguages }
		},
		// template services
		SubheaderService,
		MenuHorizontalService,
		MenuAsideService,
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService,
		HttpService,
		LoadingService,
		DatePipe,
		AcessoRestritoGuard,
		CookieService,
		GoogleAnalyticsService
	],
	bootstrap: [AppComponent],

})
export class AppModule {
}

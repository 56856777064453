"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
// Components
var base_component_1 = require("./base/base.component");
var error_page_component_1 = require("./content/error-page/error-page.component");
// Auth
var auth_1 = require("../../../core/auth");
var routes = [
    {
        path: '',
        component: base_component_1.BaseComponent,
        canActivate: [auth_1.AuthGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: 'app/views/pages/dashboard/dashboard.module#DashboardModule'
            },
            {
                path: 'builder',
                loadChildren: 'app/views/themes/demo2/content/builder/builder.module#BuilderModule'
            },
            { path: 'error/:type', component: error_page_component_1.ErrorPageComponent },
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
        ]
    },
];
var PagesRoutingModule = /** @class */ (function () {
    function PagesRoutingModule() {
    }
    return PagesRoutingModule;
}());
exports.PagesRoutingModule = PagesRoutingModule;

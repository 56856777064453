"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ScrollTopComponent = /** @class */ (function () {
    function ScrollTopComponent() {
        // Public properties
        this.scrollTopOptions = {
            offset: 300,
            speed: 600
        };
    }
    return ScrollTopComponent;
}());
exports.ScrollTopComponent = ScrollTopComponent;

import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsuarioService } from '../../../../../administracao/usuario/usuario.service';
import { Usuario } from '../../../../../models/usuario';
import { Router, NavigationExtras } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalLoginComponent } from '../../../../../modal/modal-login/modal-login.component';
import { ContatoService } from '../../../../../+sol/contato/contato.service';

@Component({
	selector: 'kt-user-profile2',
	templateUrl: './user-profile2.component.html',
	styleUrls: ['./user-profile2.component.scss']
})

export class UserProfile2Component implements OnInit, OnDestroy {

	usuarioAutenticado: Usuario;
	nomeUsuario: string;
	tipoContato: boolean = false;
	imgPerfil: string = "assets\\img\\user-default.png";
	tempo: number = 0;
	login: boolean = false;
	subscription: Subscription;
	exibeMeusDados: boolean = false;
	constructor(private usuarioService: UsuarioService,
				private contatoService: ContatoService,		
				private router: Router,
				public modalService: NgbModal) {
		contatoService.imgSelecionada$.subscribe(img => this.imgPerfil = img);
	}

	ngOnInit(): void {
		this.getUsuarioAutenticado();
		const token = interval(120000);
		this.subscription = token.subscribe(val => {
			if (!this.login) {
				if (this.tempo < 600000) { 
					this.getTempoAcesso()
				}
			}
		});
	}

	async getTempoAcesso() {
		this.tempo = await this.usuarioService.getTempoAcesso();
		if (this.tempo == undefined || this.tempo > 600000) {
			this.login = true
			const modalRef = this.modalService.open(ModalLoginComponent, { windowClass: 'modal-padrao', backdrop: 'static', keyboard: false });
			try {
				modalRef.componentInstance.email = this.usuarioAutenticado.conta;
				modalRef.result.then((result) => {
					if (result) {
						this.login = false
						this.getTempoAcesso();
					} else {
						this.logout();
					}
				});
			} catch (error) {
			}
		}

	}

	logout() {
		// this.usuarioService.logoff();
		if (typeof (Storage) !== 'undefined') {
			sessionStorage.clear();
		}
		this.router.navigate(['/auth/login']);
	}

	getUsuarioAutenticado() {
		this.usuarioService.getUsuarioAutenticado().then((res) => {
			this.usuarioAutenticado = res;
			this.nomeUsuario = this.usuarioAutenticado.conta;
			if (this.usuarioAutenticado.contato) {
				this.exibeMeusDados = true;
				this.nomeUsuario = this.usuarioAutenticado.contato.nome;
				this.carregarImagem(this.usuarioAutenticado.contato.referenciaFoto);
				this.tipoContato = true;
			} 
			
		});
		
	}

	carregarImagem(referenciaFoto: string) {
		this.contatoService.carregarFoto(referenciaFoto).then((res) => {
			if (res) {
				this.imgPerfil = res;
			}
		});
	}

	navegarMeusDados() {
		if (this.usuarioAutenticado.contato) {
			this.navegar("/contato/meus-dados");			
		} else {
			let navigationExtras: NavigationExtras = {
				state: {
				  param1: this.usuarioAutenticado.id
				}
			};
			this.router.navigate(["/usuario/editar/"], navigationExtras);
		}
	}

	navegarAlterarSenha() {
		if (this.usuarioAutenticado.contato) {
			this.navegar("/contato/alterar-senha");					
		} else {
			this.router.navigate(["/usuario/alterar-senha"]);
		}
	}

	navegarConcessionarias() {
		if (this.usuarioAutenticado.contato) {
			this.navegar("/contato/minhas-concessionarias");
		} 
	}

	navegar(path: string) {
		let navigationExtras: NavigationExtras = {
			state: {
			  contato: this.usuarioAutenticado.contato.id
			}
		};
		this.router.navigate([path], navigationExtras);				
	}
	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}

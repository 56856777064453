"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var PaginadorComponent = /** @class */ (function () {
    function PaginadorComponent() {
        this.changePage = new core_1.EventEmitter();
        this.pagina = 0;
        this.paginasRegistros = 0;
        this.inicio = 0;
        this.limite = 15;
        this.colecao = [];
        this.nrRegistros = 0;
    }
    PaginadorComponent.prototype.ngOnInit = function () { };
    PaginadorComponent.prototype.ngOnChanges = function (changes) {
        this.nrRegistros = changes.totalRegistros.currentValue;
        if (this.nrRegistros > 0) {
            this.iniciarComponente();
        }
    };
    PaginadorComponent.prototype.iniciarComponente = function () {
        this.colecao = [];
        this.paginasRegistros = Math.trunc(this.nrRegistros / this.limite);
        if (this.nrRegistros % this.limite != 0) {
            this.paginasRegistros++;
        }
        if (this.paginasRegistros == 0 && this.nrRegistros > 0) {
            this.paginasRegistros = 1;
        }
        if (this.pagina == 0) {
            this.pagina = 1;
        }
        if (this.pagina - 2 >= 0) {
            this.inicio = this.pagina - 2;
        }
        for (var i = this.inicio; i < (this.pagina + 2); i++) {
            this.colecao.push(i);
        }
    };
    PaginadorComponent.prototype.pesquisarPagina = function (pagina) {
        this.pagina = pagina;
        if (this.pagina < 1) {
            this.pagina = 1;
        }
        else if (this.pagina > this.paginasRegistros) {
            this.pagina = this.paginasRegistros;
        }
        else {
            this.colecao = [];
            if (this.pagina - 2 >= 0) {
                this.inicio = this.pagina - 2;
            }
            else {
                this.inicio = this.pagina;
            }
            for (var i = this.inicio; i < (this.pagina + 2); i++) {
                this.colecao.push(i);
            }
            this.changePage.emit({ paginaAtual: this.pagina });
        }
    };
    return PaginadorComponent;
}());
exports.PaginadorComponent = PaginadorComponent;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
var NumberOnlyFormControlDirective = /** @class */ (function () {
    function NumberOnlyFormControlDirective(ngControl) {
        this.ngControl = ngControl;
    }
    NumberOnlyFormControlDirective.prototype.onInputChange = function (event) {
        var initalValue = event;
        var value = null;
        if (initalValue) {
            value = initalValue.toString().replace(/[^0-9]*/g, '');
            if (value == '') {
                value = null;
            }
        }
        if (initalValue !== value) {
            this.ngControl.valueAccessor.writeValue(value);
            this.ngControl.control.setValue(value);
        }
    };
    return NumberOnlyFormControlDirective;
}());
exports.NumberOnlyFormControlDirective = NumberOnlyFormControlDirective;

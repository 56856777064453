<div class="kt-quick-search__result">
	<ng-container *ngFor="let item of data">
		<ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: item}"></ng-container>
	</ng-container>
</div>


<ng-template #itemTemplate let-item="item">
	<a routerLink="{{item.tela?.url}}" class="dropdown-item">
		<i class="kt-font {{item?.icone}}"></i>&nbsp;&nbsp;&nbsp;{{item.nome}}
	</a>
</ng-template>

<ng-template #emptyResultTemplate>
	<div class="kt-quick-search__message kt-hidden">
		{{noRecordText||'No record found'}}
	</div>
</ng-template>

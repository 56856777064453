"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./search-result.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@angular/router");
var i4 = require("./search-result.component");
var styles_SearchResultComponent = [i0.styles];
var RenderType_SearchResultComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchResultComponent, data: {} });
exports.RenderType_SearchResultComponent = RenderType_SearchResultComponent;
function View_SearchResultComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { item: 0 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit); var currVal_1 = i1.ɵnov(_v.parent, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SearchResultComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "dropdown-item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["\u00A0\u00A0\u00A0", " "]))], function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "", ((_v.context.item.tela == null) ? null : _v.context.item.tela.url), ""); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵinlineInterpolate(1, "kt-font ", ((_v.context.item == null) ? null : _v.context.item.icone), ""); _ck(_v, 2, 0, currVal_3); var currVal_4 = _v.context.item.nome; _ck(_v, 3, 0, currVal_4); }); }
function View_SearchResultComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "kt-quick-search__message kt-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.noRecordText || "No record found"); _ck(_v, 1, 0, currVal_0); }); }
function View_SearchResultComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "kt-quick-search__result"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchResultComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, [["itemTemplate", 2]], null, 0, null, View_SearchResultComponent_2)), (_l()(), i1.ɵand(0, [["emptyResultTemplate", 2]], null, 0, null, View_SearchResultComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_SearchResultComponent_0 = View_SearchResultComponent_0;
function View_SearchResultComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-search-result", [], null, null, null, View_SearchResultComponent_0, RenderType_SearchResultComponent)), i1.ɵdid(1, 49152, null, 0, i4.SearchResultComponent, [], null, null)], null, null); }
exports.View_SearchResultComponent_Host_0 = View_SearchResultComponent_Host_0;
var SearchResultComponentNgFactory = i1.ɵccf("kt-search-result", i4.SearchResultComponent, View_SearchResultComponent_Host_0, { data: "data", noRecordText: "noRecordText" }, {}, []);
exports.SearchResultComponentNgFactory = SearchResultComponentNgFactory;

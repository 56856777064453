"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var forms_1 = require("@angular/forms");
var UpdateStatusDialogComponent = /** @class */ (function () {
    function UpdateStatusDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.selectedStatusForUpdate = new forms_1.FormControl('');
        this.viewLoading = false;
        this.loadingAfterSubmit = false;
    }
    UpdateStatusDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        /* Server loading imitation. Remove this */
        this.viewLoading = true;
        setTimeout(function () {
            _this.viewLoading = false;
        }, 2500);
    };
    UpdateStatusDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    UpdateStatusDialogComponent.prototype.updateStatus = function () {
        var _this = this;
        if (this.selectedStatusForUpdate.value.length === 0) {
            return;
        }
        /* Server loading imitation. Remove this */
        this.viewLoading = true;
        this.loadingAfterSubmit = true;
        setTimeout(function () {
            _this.dialogRef.close(_this.selectedStatusForUpdate.value); // Keep only this row
        }, 2500);
    };
    return UpdateStatusDialogComponent;
}());
exports.UpdateStatusDialogComponent = UpdateStatusDialogComponent;

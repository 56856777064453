// Angular
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MenuHorizontalService } from '../../../../../core/_base/layout';
import { MenuItem } from '../../../../../models/menuItem';
import { Perfil } from '../../../../../models/perfil';
import { TipoMenuItem } from '../../../../../models/tipoMenuItem';
import { UsuarioService } from '../../../../../administracao/usuario/usuario.service';
import { Tela } from '../../../../../models/tela';

@Component({
	selector: 'kt-search-default',
	templateUrl: './search-default.component.html',
	styleUrls: ['./search-default.component.scss']
})
export class SearchDefaultComponent implements OnInit {

	// Public properties

	// Set icon class name
	@Input() icon: string = 'flaticon2-search-1';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG: boolean;

	@ViewChild('searchInput') searchInput: ElementRef;

	data: any[];
	result: MenuItem[] = [];
	loading: boolean;

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	constructor(private cdr: ChangeDetectorRef,
		private menuHorizontalService: MenuHorizontalService,
		private usuarioService: UsuarioService) {
	}

	/**
	 * On init
	 */
	ngOnInit(): void {

		this.usuarioService.getUsuarioAutenticado().then((res) => {

			let filtro: MenuItem = new MenuItem();
			filtro.perfil = new Perfil();
			filtro.perfil.id = res.perfil.id;
			filtro.tipoMenuItem = new TipoMenuItem();
			filtro.tipoMenuItem.id = 2;

			this.menuHorizontalService.pesquisar(filtro).then((res) => {
				this.result = res;
			});
		});



	}

	/**
	 * Search
	 * @param e: Event
	 */
	search(e) {
		this.data = null;
		if (e.target.value.length >= 1) {
			this.loading = true;
			setTimeout(() => {
				this.data = this.result.filter(it => {
					return it.nome.toLowerCase().includes(e.target.value.toLowerCase());
				});
				if (this.data.length == 0) {
					let filtro: MenuItem = new MenuItem();
					filtro.nome = "Tela não encontrada";
					filtro.icone = "fa fa-ban";
					this.data = [filtro];
				}
				this.loading = false;
				this.cdr.detectChanges();
			}, 500);
		}
	}

	/**
	 * Clear search
	 *
	 * @param e: Event
	 */
	clear(e) {
		this.data = null;
		this.searchInput.nativeElement.value = '';
	}


}

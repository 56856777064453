"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var autorizacao_service_1 = require("./../+autorizacao/autorizacao.service");
var generic_service_1 = require("./generic.service");
var http_service_1 = require("./http.service");
var i0 = require("@angular/core");
var i1 = require("./http.service");
var i2 = require("../+autorizacao/autorizacao.service");
var DocsService = /** @class */ (function (_super) {
    __extends(DocsService, _super);
    function DocsService(httpService, authService) {
        return _super.call(this, httpService, authService) || this;
    }
    DocsService.prototype.getUrlArquivo = function (referencia) {
        var recurso = "arquivo/getUrl";
        return this.requisitarServico(recurso, referencia);
    };
    DocsService.prototype.mostrarManualTecnico = function () {
        var recurso = "ajuda/mostrarManualTecnico";
        this.showPDF(recurso);
    };
    DocsService.prototype.mostrarManualSite = function () {
        var recurso = "ajuda/mostrarManualSite";
        this.showPDF(recurso);
    };
    DocsService.ngInjectableDef = i0.defineInjectable({ factory: function DocsService_Factory() { return new DocsService(i0.inject(i1.HttpService), i0.inject(i2.AutorizacaoService)); }, token: DocsService, providedIn: "root" });
    return DocsService;
}(generic_service_1.GenericService));
exports.DocsService = DocsService;

"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../partials/layout/subheader/subheader1/subheader1.component.ngfactory");
var i2 = require("../../../partials/layout/subheader/subheader1/subheader1.component");
var i3 = require("../../../../core/_base/layout/services/subheader.service");
var i4 = require("../../../partials/layout/subheader/subheader2/subheader2.component.ngfactory");
var i5 = require("../../../partials/layout/subheader/subheader2/subheader2.component");
var i6 = require("@angular/router");
var i7 = require("../../../../administracao/usuario/usuario.service");
var i8 = require("../../../../services/toast.service");
var i9 = require("../../../partials/layout/subheader/subheader3/subheader3.component.ngfactory");
var i10 = require("../../../partials/layout/subheader/subheader3/subheader3.component");
var i11 = require("@angular/common");
var i12 = require("./subheader.component");
var i13 = require("../../../../core/_base/layout/services/layout-config.service");
var styles_SubheaderComponent = [];
var RenderType_SubheaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SubheaderComponent, data: {} });
exports.RenderType_SubheaderComponent = RenderType_SubheaderComponent;
function View_SubheaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-subheader1", [], null, null, null, i1.View_Subheader1Component_0, i1.RenderType_Subheader1Component)), i0.ɵdid(1, 4440064, null, 0, i2.Subheader1Component, [i3.SubheaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_SubheaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-subheader2", [], null, null, null, i4.View_Subheader2Component_0, i4.RenderType_Subheader2Component)), i0.ɵdid(1, 4440064, null, 0, i5.Subheader2Component, [i6.Router, i3.SubheaderService, i7.UsuarioService, i8.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_SubheaderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-subheader3", [], null, null, null, i9.View_Subheader3Component_0, i9.RenderType_Subheader3Component)), i0.ɵdid(1, 4440064, null, 0, i10.Subheader3Component, [i3.SubheaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_SubheaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubheaderComponent_1)), i0.ɵdid(1, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubheaderComponent_2)), i0.ɵdid(3, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubheaderComponent_3)), i0.ɵdid(5, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.layout || (_co.layout === "subheader-v1")); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.layout === "subheader-v2"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.layout === "subheader-v3"); _ck(_v, 5, 0, currVal_2); }, null); }
exports.View_SubheaderComponent_0 = View_SubheaderComponent_0;
function View_SubheaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-subheader", [], null, null, null, View_SubheaderComponent_0, RenderType_SubheaderComponent)), i0.ɵdid(1, 114688, null, 0, i12.SubheaderComponent, [i13.LayoutConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SubheaderComponent_Host_0 = View_SubheaderComponent_Host_0;
var SubheaderComponentNgFactory = i0.ɵccf("kt-subheader", i12.SubheaderComponent, View_SubheaderComponent_Host_0, {}, {}, []);
exports.SubheaderComponentNgFactory = SubheaderComponentNgFactory;

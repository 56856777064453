// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
import { ContentAnimateDirective, HeaderDirective, MenuDirective, StickyDirective } from './_base/layout';
// Metronic Pipes
// Metornic Services
import { FirstLetterPipe, GetObjectPipe, JoinPipe, OffcanvasDirective, SafePipe, ScrollTopDirective, SparklineChartDirective, TabClickEventDirective, TimeElapsedPipe, WizardDirective, ToggleDirective } from './_base/metronic';
import { LoadingComponent } from './_base/layout/loading/loading.component';
import { CpfCnpjDirective } from './_base/metronic/directives/cpf-cnpj.directive';
import { UppercaseDirective } from './_base/metronic/directives/uppercase.directive';
import { NumberOnlyFormControlDirective } from './_base/metronic/directives/numbers-only-form-control.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [
		// directives
		ScrollTopDirective,
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		WizardDirective,
		MenuDirective,
		TabClickEventDirective,
		SparklineChartDirective,
		ContentAnimateDirective,
		CpfCnpjDirective,
		StickyDirective,
		UppercaseDirective,
		NumberOnlyFormControlDirective,
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		LoadingComponent,
	],
	exports: [
		// directives
		ScrollTopDirective,
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		WizardDirective,
		MenuDirective,
		TabClickEventDirective,
		SparklineChartDirective,
		ContentAnimateDirective,
		StickyDirective,
		UppercaseDirective,
		NumberOnlyFormControlDirective,
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		LoadingComponent,
		CpfCnpjDirective,
	],
	providers: []
})
export class CoreModule {
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var docs_service_1 = require("../../../../../services/docs.service");
var modal_pesquisa_perguntas_frequentes_component_1 = require("../../../../../modal/perguntas-frequentes/modal-pesquisa-perguntas-frequentes/modal-pesquisa-perguntas-frequentes.component");
var MenuAjudaComponent = /** @class */ (function () {
    /**
     * Component constructor
     */
    function MenuAjudaComponent(modalService, docsService) {
        this.modalService = modalService;
        this.docsService = docsService;
        // Public properties
        // Set icon class name
        this.icon = 'flaticon2-shopping-cart-1';
    }
    MenuAjudaComponent.prototype.ngAfterViewInit = function () {
    };
    MenuAjudaComponent.prototype.ngOnInit = function () {
    };
    MenuAjudaComponent.prototype.mostrarPerguntasFrequentes = function () {
        var modalRef = this.modalService.open(modal_pesquisa_perguntas_frequentes_component_1.ModalPesquisaPerguntasFrequentesComponent, { size: 'lg', windowClass: 'modal-padrao' });
    };
    MenuAjudaComponent.prototype.exibirManualTecnico = function () {
        this.docsService.mostrarManualTecnico();
    };
    MenuAjudaComponent.prototype.mostrarManualSite = function () {
        this.docsService.mostrarManualSite();
    };
    return MenuAjudaComponent;
}());
exports.MenuAjudaComponent = MenuAjudaComponent;

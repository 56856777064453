"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./auth-notice.component");
var i2 = require("../../../../core/auth/auth-notice/auth-notice.service");
var styles_AuthNoticeComponent = [];
var RenderType_AuthNoticeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AuthNoticeComponent, data: {} });
exports.RenderType_AuthNoticeComponent = RenderType_AuthNoticeComponent;
function View_AuthNoticeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["alertNotice", 1]], null, 1, "div", [["role", "alert"]], [[8, "hidden", 0], [8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "alert-text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.message; var currVal_1 = i0.ɵinlineInterpolate(1, "alert alert-", _co.type, ""); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.message; _ck(_v, 1, 0, currVal_2); }); }
exports.View_AuthNoticeComponent_0 = View_AuthNoticeComponent_0;
function View_AuthNoticeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-auth-notice", [], null, null, null, View_AuthNoticeComponent_0, RenderType_AuthNoticeComponent)), i0.ɵdid(1, 245760, null, 0, i1.AuthNoticeComponent, [i2.AuthNoticeService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AuthNoticeComponent_Host_0 = View_AuthNoticeComponent_Host_0;
var AuthNoticeComponentNgFactory = i0.ɵccf("kt-auth-notice", i1.AuthNoticeComponent, View_AuthNoticeComponent_Host_0, {}, { type: "type", message: "message" }, []);
exports.AuthNoticeComponentNgFactory = AuthNoticeComponentNgFactory;

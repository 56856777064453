"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BaseModel = /** @class */ (function () {
    function BaseModel() {
        // Edit
        this._isEditMode = false;
        // Log
        this._userId = 0; // Admin
    }
    return BaseModel;
}());
exports.BaseModel = BaseModel;

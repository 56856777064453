import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CnpjFormatadoPipe } from './cnpj-formatado.pipe';
import { TelefoneFormatadoPipe } from './telefone-formatado.pipe';
import { RealBrasileiroPipe } from './real-brasileiro.pipe';
import { DataTablesModule } from 'angular-datatables';
import { VigenciaDataVencimentoPipe } from './vigencia-data-vencimento.pipe';
import { DateFormat } from './date-format.pipe';
import { TiposFaleConoscoPipe } from './tipos-fale-conosco.pipe';
import { ModeloFaturamentoPipe } from './modelo-faturamento.pipe';
import { TipoFeriadoPipe } from './tipo-feriado-pipe';
import { SafePipe } from './safe.pipe';
import { StatusContratoPipe } from './status-contrato.pipe';
import { PlacaPipe } from './placa.pipe';
import { SimNaoPipe } from './sim-nao.pipe';
import { EstoqueRenavePipe } from './status-estoque.pipe';
import { NotaFiscalPipe } from './nota-fiscal.pipe';
import { TipoLogHistoricoRenave } from './tipo-log-historico-renave.pipe';
import { TipoLogEstoqueRenave } from './tipo-log-estoque-renave.pipe';
import { TiposEstabelecimentosPipe } from './tipos-estabelecimentos.pipe';

@NgModule({
  declarations: [
    CnpjFormatadoPipe,
    TelefoneFormatadoPipe,
    RealBrasileiroPipe,
    VigenciaDataVencimentoPipe,
    DateFormat,
    TiposFaleConoscoPipe,
    TiposEstabelecimentosPipe,
    ModeloFaturamentoPipe,
    TipoFeriadoPipe,
    StatusContratoPipe,
    SafePipe,
    PlacaPipe,
    SimNaoPipe,
    EstoqueRenavePipe,
    NotaFiscalPipe,
    TipoLogHistoricoRenave,
    TipoLogEstoqueRenave
  ],
  imports: [

  ],
  exports: [
    CnpjFormatadoPipe,
    TelefoneFormatadoPipe,
    RealBrasileiroPipe,
    VigenciaDataVencimentoPipe,
    DateFormat,
    TiposFaleConoscoPipe,
    TiposEstabelecimentosPipe,
    ModeloFaturamentoPipe,
    TipoFeriadoPipe,
    StatusContratoPipe,
    SafePipe,
    PlacaPipe,
    SimNaoPipe,
    EstoqueRenavePipe,
    NotaFiscalPipe,
    TipoLogHistoricoRenave,
    TipoLogEstoqueRenave
  ]
})
export class PipesModule { }

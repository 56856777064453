"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../../core/_base/metronic/directives/scroll-top.directive");
var i2 = require("./scroll-top.component");
var styles_ScrollTopComponent = [];
var RenderType_ScrollTopComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScrollTopComponent, data: {} });
exports.RenderType_ScrollTopComponent = RenderType_ScrollTopComponent;
function View_ScrollTopComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "kt-scrolltop"], ["id", "kt_scrolltop"], ["ktScrollTop", ""]], null, null, null, null, null)), i0.ɵdid(1, 4210688, null, 0, i1.ScrollTopDirective, [i0.ElementRef], { options: [0, "options"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "la la-arrow-up"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.scrollTopOptions; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_ScrollTopComponent_0 = View_ScrollTopComponent_0;
function View_ScrollTopComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-scroll-top", [], null, null, null, View_ScrollTopComponent_0, RenderType_ScrollTopComponent)), i0.ɵdid(1, 49152, null, 0, i2.ScrollTopComponent, [], null, null)], null, null); }
exports.View_ScrollTopComponent_Host_0 = View_ScrollTopComponent_Host_0;
var ScrollTopComponentNgFactory = i0.ɵccf("kt-scroll-top", i2.ScrollTopComponent, View_ScrollTopComponent_Host_0, {}, {}, []);
exports.ScrollTopComponentNgFactory = ScrollTopComponentNgFactory;

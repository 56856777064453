<div class="modal-header">
  <div class="kt-login__title">
    <h4 class="kt-font-success">Informe sua senha para revalidar a sessão</h4>
  </div>
</div>
<div class="modal-body">
  <form class="kt-form" autocomplete="anyrandomstring" (submit)="efetuarLogin()">
    <div class="kt-portlet__body" [formGroup]="form">
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon2">@</span>
          </div>
          <input readonly  type="email" autocomplete="anyrandomstring" class="form-control" placeholder="Digite seu e-mail" formControlName="email"
            maxlength="120" />
        </div>
        <div *ngFor="let validation of validacao_formulario.email">
          <h6 class="kt-font-danger"
            *ngIf="form.get('email').hasError(validation.type) && (form.get('email').dirty || form.get('email').touched)">
            {{validation.message}}</h6>
        </div>
      </div>
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon2"><i class="fa fa-key"></i></span>
          </div>
          <input type="password" class="form-control" placeholder="Digite sua senha" formControlName="atual"
          autocomplete="new-password" maxlength="255" />
        </div>
        <div *ngFor="let validation of validacao_formulario.atual">
          <h6 class="kt-font-danger"
            *ngIf="form.get('atual').hasError(validation.type) && (form.get('atual').dirty || form.get('atual').touched)">
            {{validation.message}}</h6>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="activeModal.close(false)"
        [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}" 
        class="btn btn-secundary btn-elevate ">
        Cancelar
      </button>
      <button type="submit"
        [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}" id="kt_login_signin_submit"
        class="btn btn-success btn-elevate ">
        Renovar Acesso
      </button>
    </div>
  </form>
  
</div>

// Angular
import { AfterViewInit, Component, OnInit } from '@angular/core';
// Layout
import { LayoutConfigService, MenuHorizontalService } from '../../../../../core/_base/layout';
// Metronic
import { ToggleOptions } from '../../../../../core/_base/metronic';
import { HtmlClassService } from '../../html-class.service';
import { MenuItem } from '../../../../../models/menuItem';
import { MenuTela } from '../../../../../models/menuTela';
import { Usuario } from '../../../../../models/usuario';
import { UsuarioService } from '../../../../../administracao/usuario/usuario.service';
import { Perfil } from '../../../../../models/perfil';

@Component({
	selector: 'kt-brand',
	templateUrl: './brand.component.html',
})
export class BrandComponent implements OnInit, AfterViewInit {
	// Public properties
	headerLogo: string;
	headerStickyLogo: string;
	tipoMenu: number = 1;
	usuario: Usuario = new Usuario();

	administracao: MenuTela[];

	toggleOptions: ToggleOptions = {
		target: 'body',
		targetState: 'kt-aside--minimize',
		togglerState: 'kt-aside__brand-aside-toggler--active'
	};

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 * @param htmlClassService: HtmlClassService
	 */
	constructor(private layoutConfigService: LayoutConfigService,
		public htmlClassService: HtmlClassService,
		public usuarioService: UsuarioService,
		public menuHorizontalService: MenuHorizontalService
	) {
		this.carregarMenu();
		this.menuHorizontalService.loadMenu();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.buscarUsuarioAutenticado();
		
		this.headerLogo = this.layoutConfigService.getLogo();
		this.headerStickyLogo = this.layoutConfigService.getStickyLogo();
	}

	buscarUsuarioAutenticado() {
		this.usuario.perfil = new Perfil()
		this.usuarioService.getUsuarioAutenticado().then((res) => {
			this.usuario = res;
		});
	}

	carregarMenu(): any {
		this.menuHorizontalService.getListaAdministracao(this.tipoMenu).then((res) => {
			this.administracao = res.submenu as MenuTela[];			
		});
	}

	/**
	 * On destroy
	 */
	ngAfterViewInit(): void {
	}
}

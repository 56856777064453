"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var i0 = require("@angular/core");
var AuthNoticeService = /** @class */ (function () {
    function AuthNoticeService() {
        this.onNoticeChanged$ = new rxjs_1.BehaviorSubject(null);
    }
    AuthNoticeService.prototype.setNotice = function (message, type) {
        var notice = {
            message: message,
            type: type
        };
        this.onNoticeChanged$.next(notice);
    };
    AuthNoticeService.ngInjectableDef = i0.defineInjectable({ factory: function AuthNoticeService_Factory() { return new AuthNoticeService(); }, token: AuthNoticeService, providedIn: "root" });
    return AuthNoticeService;
}());
exports.AuthNoticeService = AuthNoticeService;

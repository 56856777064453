"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// RxJS
var rxjs_1 = require("rxjs");
var LayoutRefService = /** @class */ (function () {
    function LayoutRefService() {
        // Public properties
        this.layoutRefs$ = new rxjs_1.BehaviorSubject({});
        this.layoutRefs = {};
    }
    /**
     * Add element to Ref
     *
     * @param name: any
     * @param element: any
     */
    LayoutRefService.prototype.addElement = function (name, element) {
        var obj = {};
        obj[name] = element;
        this.layoutRefs = Object.assign({}, this.layoutRefs, obj);
        this.layoutRefs$.next(this.layoutRefs);
    };
    return LayoutRefService;
}());
exports.LayoutRefService = LayoutRefService;

<!-- begin:: Header Topbar -->
<div class="kt-header__topbar">
	<!-- <kt-menu-notificacao [bgImage]="'./assets/media/misc/bg-1.jpg'" [useSVG]="true"></kt-menu-notificacao> -->
	<!--begin: My Cart -->
	<kt-menu-ajuda [bgImage]="'./assets/media/misc/bg-1.jpg'" [useSVG]="true"></kt-menu-ajuda>
	<!--end: My Cart -->
	<!--begin: Notifications -->
	<!-- <kt-notification [bgImage]="'./assets/media/misc/bg-1.jpg'" [pulse]="true" [skin]="'dark'" [icon]="'./assets/media/icons/svg/Code/Compiling.svg'" [useSVG]="true"></kt-notification> -->
	<!--end: Notifications -->
	<!--begin: Quick actions -->

	<!--end: Quick actions -->

	

	<!--begin: User bar -->
	<kt-user-profile2></kt-user-profile2>
	<!--end: User bar -->
</div>
<!-- end:: Header Topbar -->

<!-- begin:: Page -->
<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
	<div class="kt-grid kt-grid--hor kt-grid--root">
		<!-- begin:: Header Mobile -->
		<kt-header-mobile></kt-header-mobile>
		<kt-loading ></kt-loading>
		<!-- end:: Header Mobile -->

		<!-- begin::Body -->
		<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
			<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
				<!-- begin:: Header -->
				<kt-header></kt-header>
				<!-- end:: Header -->

				<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-grid--stretch">
					<div class="kt-container kt-body kt-grid kt-grid--ver" id="kt_body">
						<!-- begin:: Aside Left -->
						<ng-container *ngIf="asideDisplay">
							<button class="kt-aside-close" id="kt_aside_close_btn"><i class="la la-close"></i></button>
							<kt-aside-left></kt-aside-left>
						</ng-container>
						<!-- end:: Aside Left -->

						<!-- begin:: Content -->
						<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
							<!-- begin:: Content Head -->
							<kt-subheader *ngIf="subheaderDisplay"></kt-subheader>
							<!-- end:: Content Head -->

							<!-- begin:: Content Body -->
							<div ktContentAnimate class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
								<router-outlet></router-outlet>
							</div>
							<!-- end:: Content Body -->
						</div>
						<!-- end:: Content -->
					</div>
				</div>

				<kt-footer></kt-footer>
			</div>
		</div>
		<!-- end:: Body -->

		<!-- <kt-quick-panel></kt-quick-panel> -->
		<kt-scroll-top></kt-scroll-top>
		<!-- <kt-sticky-toolbar></kt-sticky-toolbar> -->
	</div>
</ng-container>
<!-- end:: Page -->

<ng-template #blankLayout>
	<router-outlet></router-outlet>
</ng-template>

// Angular
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

export interface WizardOptions {
    startStep: 1,
    manualStepForward: false
}

/**
 * Toggle
 */
@Directive({
    selector: '[ktWizard]',
    exportAs: 'ktWizard'
})
export class WizardDirective implements AfterViewInit {
    // Public properties
    @Input() options: WizardOptions;
    wizard: any;

	/**
	 * Directive constructor
	 * @param el: ElementRef
	 */
    constructor(private el: ElementRef) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * After view init
	 */
    ngAfterViewInit(): void {
        this.wizard = new KTWizard(this.el.nativeElement, {
            startStep: 1,
            manualStepForward: false
        });
    }

    goLast() {
        this.wizard.goLast();
    }

    getStep() {
        this.wizard.getStep();
    }

    goFirst() {
        this.wizard.goFirst();
    }

    goNext() {
        this.wizard.goNext();
    }
    goTo(t: number) {
        this.wizard.goTo(t);
    }
    currentStep(): any {
        return this.wizard.currentStep;
    }
    isFirstStep(): Boolean {
        return this.wizard.isFirstStep();
    }
    isLastStep(): Boolean {
        return this.wizard.isLastStep();
    }
    on() {
        this.wizard.on();
    }
    stop() {
        this.wizard.stop();
    }
    start() {
        this.wizard.start();
    }
    totalSteps(): any {
        return this.wizard.totalSteps();
    }
    one() {
        this.wizard.one();
    }
}

// Angular
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocsService } from '../../../../../services/docs.service';
import { ModalPesquisaPerguntasFrequentesComponent } from '../../../../../modal/perguntas-frequentes/modal-pesquisa-perguntas-frequentes/modal-pesquisa-perguntas-frequentes.component';

@Component({
	selector: 'kt-menu-ajuda',
	templateUrl: './menu-ajuda.component.html',
	styleUrls: ['./menu-ajuda.component.scss']
})
export class MenuAjudaComponent implements OnInit, AfterViewInit {
	// Public properties

	// Set icon class name
	@Input() icon: string = 'flaticon2-shopping-cart-1';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG: boolean;

	// Set bg image path
	@Input() bgImage: string;

	/**
	 * Component constructor
	 */
	constructor(private modalService: NgbModal,
		private docsService: DocsService) {
	}

	ngAfterViewInit(): void {
	}


	ngOnInit(): void {
	}

	mostrarPerguntasFrequentes() {
		const modalRef = this.modalService.open(ModalPesquisaPerguntasFrequentesComponent, { size: 'lg', windowClass: 'modal-padrao' });
	}

	exibirManualTecnico() {
		this.docsService.mostrarManualTecnico();
	}

	mostrarManualSite() {
		this.docsService.mostrarManualSite();
	}
}

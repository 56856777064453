<!-- begin:: Content Head -->
<div class="kt-subheader kt-grid__item" [hidden]="subheaderService.disabled$ | async" id="kt_subheader">
	<div class="kt-subheader__main">
		<h3 *ngIf="title" class="kt-subheader__title">{{title}}</h3>
		<span class="kt-subheader__separator kt-subheader__separator--v"></span>
		<span *ngIf="desc" class="kt-subheader__desc">{{desc}}</span>
	</div>
	<div class="kt-subheader__toolbar">
		<div class="kt-subheader__wrapper">

			<a (click)="entrada()" href="javascript:;" [className]="(router.url !== '/solicitacao/entrada' && router.url !== '/solicitacao/ite-entrada' ) ? 
																'btn btn-label-success btn-bold btn-icon-h' : 
																'btn btn-label-muted btn-bold btn-icon-h disabled'">
				<span class="tamanho-icone">+</span> Entrada em Estoque
			</a>

			<a (click)="saida()" href="javascript:;" [className]="(router.url !== '/solicitacao/saida' && router.url !== '/solicitacao/ite-saida') ? 
																'btn btn-label-warning btn-bold btn-icon-h' : 
																'btn btn-label-muted btn-bold btn-icon-h disabled'">
				<span class="tamanho-icone">-</span> Saída de Estoque
			</a>			

		</div>
	</div>
</div>
<!-- end:: Content Head -->
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
// Translate
var core_2 = require("@ngx-translate/core");
// NGRX
var store_1 = require("@ngrx/store");
// Auth
var auth_1 = require("../../../../core/auth/");
var rxjs_1 = require("rxjs");
var confirm_password_validator_1 = require("./confirm-password.validator");
var RegisterComponent = /** @class */ (function () {
    /**
     * Component constructor
     *
     * @param authNoticeService: AuthNoticeService
     * @param translate: TranslateService
     * @param router: Router
     * @param auth: AuthService
     * @param store: Store<AppState>
     * @param fb: FormBuilder
     * @param cdr
     */
    function RegisterComponent(authNoticeService, translate, router, 
    //private auth: AuthService,
    store, fb, cdr) {
        this.authNoticeService = authNoticeService;
        this.translate = translate;
        this.router = router;
        this.store = store;
        this.fb = fb;
        this.cdr = cdr;
        this.loading = false;
        this.errors = [];
        this.unsubscribe = new rxjs_1.Subject();
    }
    /*
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */
    /**
     * On init
     */
    RegisterComponent.prototype.ngOnInit = function () {
        this.initRegisterForm();
    };
    /*
    * On destroy
    */
    RegisterComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.loading = false;
    };
    /**
     * Form initalization
     * Default params, validators
     */
    RegisterComponent.prototype.initRegisterForm = function () {
        this.registerForm = this.fb.group({
            fullname: ['', forms_1.Validators.compose([
                    forms_1.Validators.required,
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.maxLength(100)
                ])
            ],
            email: ['', forms_1.Validators.compose([
                    forms_1.Validators.required,
                    forms_1.Validators.email,
                    forms_1.Validators.minLength(3),
                    // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                    forms_1.Validators.maxLength(320)
                ]),
            ],
            username: ['', forms_1.Validators.compose([
                    forms_1.Validators.required,
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.maxLength(100)
                ]),
            ],
            password: ['', forms_1.Validators.compose([
                    forms_1.Validators.required,
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.maxLength(100)
                ])
            ],
            confirmPassword: ['', forms_1.Validators.compose([
                    forms_1.Validators.required,
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.maxLength(100)
                ])
            ],
            agree: [false, forms_1.Validators.compose([forms_1.Validators.required])]
        }, {
            validator: confirm_password_validator_1.ConfirmPasswordValidator.MatchPassword
        });
    };
    /**
     * Form Submit
     */
    RegisterComponent.prototype.submit = function () {
        /*const controls = this.registerForm.controls;

        // check form
        if (this.registerForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return;
        }

        this.loading = true;

        if (!controls['agree'].value) {
            // you must agree the terms and condition
            // checkbox cannot work inside mat-form-field https://github.com/angular/material2/issues/7891
            this.authNoticeService.setNotice('You must agree the terms and condition', 'danger');
            return;
        }

        const _user: User = new User();
        _user.clear();
        _user.email = controls['email'].value;
        _user.username = controls['username'].value;
        _user.fullname = controls['fullname'].value;
        _user.password = controls['password'].value;
        _user.roles = [];
        this.auth.register(_user).pipe(
            tap(user => {
                if (user) {
                    this.store.dispatch(new Register({authToken: user.accessToken}));
                    // pass notice message to the login page
                    this.authNoticeService.setNotice(this.translate.instant('AUTH.REGISTER.SUCCESS'), 'success');
                    this.router.navigateByUrl('/auth/login');
                } else {
                    this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
                }
            }),
            takeUntil(this.unsubscribe),
            finalize(() => {
                this.loading = false;
                this.cdr.detectChanges();
            })
        ).subscribe();
        */
    };
    /**
     * Checking control validation
     *
     * @param controlName: string => Equals to formControlName
     * @param validationType: string => Equals to valitors name
     */
    RegisterComponent.prototype.isControlHasError = function (controlName, validationType) {
        var control = this.registerForm.controls[controlName];
        if (!control) {
            return false;
        }
        var result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    };
    return RegisterComponent;
}());
exports.RegisterComponent = RegisterComponent;

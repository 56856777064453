"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
// Layout
var layout_1 = require("../../../../../core/_base/layout");
var html_class_service_1 = require("../../html-class.service");
var usuario_1 = require("../../../../../models/usuario");
var usuario_service_1 = require("../../../../../administracao/usuario/usuario.service");
var perfil_1 = require("../../../../../models/perfil");
var BrandComponent = /** @class */ (function () {
    /**
     * Component constructor
     *
     * @param layoutConfigService: LayoutConfigService
     * @param htmlClassService: HtmlClassService
     */
    function BrandComponent(layoutConfigService, htmlClassService, usuarioService, menuHorizontalService) {
        this.layoutConfigService = layoutConfigService;
        this.htmlClassService = htmlClassService;
        this.usuarioService = usuarioService;
        this.menuHorizontalService = menuHorizontalService;
        this.tipoMenu = 1;
        this.usuario = new usuario_1.Usuario();
        this.toggleOptions = {
            target: 'body',
            targetState: 'kt-aside--minimize',
            togglerState: 'kt-aside__brand-aside-toggler--active'
        };
        this.carregarMenu();
        this.menuHorizontalService.loadMenu();
    }
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */
    /**
     * On init
     */
    BrandComponent.prototype.ngOnInit = function () {
        this.buscarUsuarioAutenticado();
        this.headerLogo = this.layoutConfigService.getLogo();
        this.headerStickyLogo = this.layoutConfigService.getStickyLogo();
    };
    BrandComponent.prototype.buscarUsuarioAutenticado = function () {
        var _this = this;
        this.usuario.perfil = new perfil_1.Perfil();
        this.usuarioService.getUsuarioAutenticado().then(function (res) {
            _this.usuario = res;
        });
    };
    BrandComponent.prototype.carregarMenu = function () {
        var _this = this;
        this.menuHorizontalService.getListaAdministracao(this.tipoMenu).then(function (res) {
            _this.administracao = res.submenu;
        });
    };
    /**
     * On destroy
     */
    BrandComponent.prototype.ngAfterViewInit = function () {
    };
    return BrandComponent;
}());
exports.BrandComponent = BrandComponent;

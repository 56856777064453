import { Injectable } from '@angular/core';
import { GenericService } from '../../services/generic.service';
import { HttpService } from '../../services/http.service';
import { AutorizacaoService } from '../../+autorizacao/autorizacao.service';
import { Usuario } from '../../models/usuario';
import { Retorno } from '../../models/retorno';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService extends GenericService {

    constructor(httpService: HttpService, authService: AutorizacaoService) {
        super(httpService, authService);
    }

    logoff() {
        let recurso = "auth/logoff";
        return this.requisitarServico(recurso);
    }

    getUsuarioAutenticado(): Promise<Usuario> {
        let recurso = "usuario/getUsuarioAutenticado"
        return this.requisitarServico(recurso);
    }

    pesquisarPorId(usuario: Usuario): Promise<Usuario> {
        let recurso = "usuario/editar"
        return this.requisitarServico(recurso, usuario);
    }

    pesquisar(usuario: Usuario, pagina?: number): Promise<Retorno> {
        let recurso = "usuario/pesquisar";
        let params = this.getPagina(pagina);
        return this.requisitarServico(recurso, usuario, params);
    }

    salvar(usuario: Usuario): Promise<Usuario> {
        let recurso = "usuario/salvar"
        return this.requisitarServico(recurso, usuario);
    }

    ativar(usuario: Usuario): Promise<Usuario> {
        let recurso = "usuario/ativar"
        return this.requisitarServico(recurso, usuario);
    }

    desativar(usuario: Usuario): Promise<Usuario> {
        let recurso = "usuario/desativar"
        return this.requisitarServico(recurso, usuario);
    }

    imprimir(formato: String) {
        let recurso = "usuario/relatorio/" + formato;
        return this.requisitarServico(recurso);
    }

    async alterarSenha(senha: string): Promise<Usuario> {
        let recurso = "auth/alterarSenha";
        return this.requisitarServico(recurso, senha);
    }

    verificarPermissao(nomeMetodo: string, nomeController: string): Promise<boolean> {
        let recurso = "usuario/verificar-permissao";
        let params = "&nomeMetodo=" + nomeMetodo + "&nomeController=/" + nomeController;
        return this.requisitarServicoWithoutLoading(recurso, null, params);
    }

    verificarPermissaoConcessionaria(nomeMetodo: string, nomeController: string, idConcessionaria: number): Promise<boolean> {
        let recurso = "usuario/verificar-permissao";
        let params = "&nomeMetodo=" + nomeMetodo + "&nomeController=/" + nomeController;
        return this.requisitarServicoConcessionaria(recurso, null, idConcessionaria, params);
    }
    getTempoAcesso(): Promise<number> {
        let recurso = "usuario/getTempoAcesso";
        return this.requisitarServicoWithoutLoading(recurso);
    }
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
// RxJS
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
// CRUD
var crud_1 = require("../../_base/crud");
var API_PRODUCTREMARKS_URL = 'api/productRemarks';
// Fake REST API (Mock)
// This code emulates server calls
var ProductRemarksService = /** @class */ (function () {
    function ProductRemarksService(http, httpUtils) {
        this.http = http;
        this.httpUtils = httpUtils;
    }
    // CREATE =>  POST: add a new product remark to the server
    ProductRemarksService.prototype.createProductRemark = function (productRemark) {
        // Note: Add headers if needed (tokens/bearer)
        var httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post(API_PRODUCTREMARKS_URL, productRemark, { headers: httpHeaders });
    };
    // READ
    ProductRemarksService.prototype.getAllProductRemarksByProductId = function (productId) {
        return this.http
            .get(API_PRODUCTREMARKS_URL)
            .pipe(operators_1.map(function (productRemarks) {
            return productRemarks.filter(function (rem) { return rem.carId === productId; });
        }));
    };
    ProductRemarksService.prototype.getProductRemarkById = function (productRemarkId) {
        return this.http.get(API_PRODUCTREMARKS_URL + ("/" + productRemarkId));
    };
    ProductRemarksService.prototype.findProductRemarks = function (queryParams, productId) {
        var _this = this;
        return this.getAllProductRemarksByProductId(productId).pipe(operators_1.mergeMap(function (res) {
            var result = _this.httpUtils.baseFilter(res, queryParams, []);
            return rxjs_1.of(result);
        }));
    };
    // UPDATE => PUT: update the product remark
    ProductRemarksService.prototype.updateProductRemark = function (productRemark) {
        // Note: Add headers if needed (tokens/bearer)
        var httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.put(API_PRODUCTREMARKS_URL, productRemark, {
            headers: httpHeaders
        });
    };
    // DELETE => delete the product remark
    ProductRemarksService.prototype.deleteProductRemark = function (productRemarkId) {
        var url = API_PRODUCTREMARKS_URL + "/" + productRemarkId;
        return this.http.delete(url);
    };
    ProductRemarksService.prototype.deleteProductRemarks = function (ids) {
        if (ids === void 0) { ids = []; }
        var tasks$ = [];
        var length = ids.length;
        // tslint:disable-next-line:prefer-const
        for (var i = 0; i < length; i++) {
            tasks$.push(this.deleteProductRemark(ids[i]));
        }
        return rxjs_1.forkJoin(tasks$);
    };
    return ProductRemarksService;
}());
exports.ProductRemarksService = ProductRemarksService;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// RxJS
var rxjs_1 = require("rxjs");
var KtDialogService = /** @class */ (function () {
    // Public properties
    function KtDialogService() {
        this.currentState = new rxjs_1.BehaviorSubject(false);
        this.ktDialog = new KTDialog({ 'type': 'loader', 'placement': 'top center', 'message': 'Loading ...' });
    }
    KtDialogService.prototype.show = function () {
        this.currentState.next(true);
        this.ktDialog.show();
    };
    KtDialogService.prototype.hide = function () {
        this.currentState.next(false);
        this.ktDialog.hide();
    };
    KtDialogService.prototype.checkIsShown = function () {
        return this.currentState.value;
    };
    return KtDialogService;
}());
exports.KtDialogService = KtDialogService;

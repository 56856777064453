"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
// RxJS
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
// CRUD
var crud_1 = require("../../_base/crud");
var lodash_1 = require("lodash");
var API_PRODUCTS_URL = 'api/products';
// Fake REST API (Mock)
// This method emulates server calls
var ProductsService = /** @class */ (function () {
    function ProductsService(http, httpUtils) {
        this.http = http;
        this.httpUtils = httpUtils;
        this.lastFilter$ = new rxjs_1.BehaviorSubject(new crud_1.QueryParamsModel({}, 'asc', '', 0, 10));
    }
    // CREATE =>  POST: add a new product to the server
    ProductsService.prototype.createProduct = function (product) {
        var httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post(API_PRODUCTS_URL, product, { headers: httpHeaders });
    };
    // READ
    ProductsService.prototype.getAllProducts = function () {
        return this.http.get(API_PRODUCTS_URL);
    };
    ProductsService.prototype.getProductById = function (productId) {
        return this.http.get(API_PRODUCTS_URL + ("/" + productId));
    };
    ProductsService.prototype.findProducts = function (queryParams) {
        var _this = this;
        return this.getAllProducts().pipe(operators_1.mergeMap(function (res) {
            var result = _this.httpUtils.baseFilter(res, queryParams, ['status', 'condition']);
            return rxjs_1.of(result);
        }));
    };
    // UPDATE => PUT: update the product on the server
    ProductsService.prototype.updateProduct = function (product) {
        // Note: Add headers if needed (tokens/bearer)
        var httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.put(API_PRODUCTS_URL, product, { headers: httpHeaders });
    };
    // UPDATE Status
    // Comment this when you start work with real server
    // This code imitates server calls
    ProductsService.prototype.updateStatusForProduct = function (products, status) {
        var _this = this;
        var tasks$ = [];
        lodash_1.each(products, function (element) {
            var _product = Object.assign({}, element);
            _product.status = status;
            tasks$.push(_this.updateProduct(_product));
        });
        return rxjs_1.forkJoin(tasks$);
    };
    // DELETE => delete the product from the server
    ProductsService.prototype.deleteProduct = function (productId) {
        var url = API_PRODUCTS_URL + "/" + productId;
        return this.http.delete(url);
    };
    ProductsService.prototype.deleteProducts = function (ids) {
        if (ids === void 0) { ids = []; }
        var tasks$ = [];
        var length = ids.length;
        // tslint:disable-next-line:prefer-const
        for (var i = 0; i < length; i++) {
            tasks$.push(this.deleteProduct(ids[i]));
        }
        return rxjs_1.forkJoin(tasks$);
    };
    return ProductsService;
}());
exports.ProductsService = ProductsService;

import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-paginador',
  templateUrl: './paginador.component.html',
  styleUrls: ['./paginador.component.css']
})
export class PaginadorComponent implements OnInit, OnChanges {

  @Input() totalRegistros: Observable<number>;
  @Output() changePage = new EventEmitter();
  
  pagina: number = 0;
  paginasRegistros: number = 0;
  inicio: number = 0;
  @Input() limite: number = 15;
  colecao: number[] = [];
  nrRegistros: number = 0;
  
  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {    
    this.nrRegistros = changes.totalRegistros.currentValue;
    if (this.nrRegistros > 0) {
      this.iniciarComponente();
    } 
  }

  iniciarComponente() {
    this.colecao = [];
    this.paginasRegistros = Math.trunc(this.nrRegistros / this.limite);
    if (this.nrRegistros % this.limite != 0) {
      this.paginasRegistros++;
    }
    if (this.paginasRegistros == 0 && this.nrRegistros > 0) {
      this.paginasRegistros = 1;
    }
    if (this.pagina == 0) {
      this.pagina = 1;
    }
    if (this.pagina - 2 >= 0) {
      this.inicio = this.pagina - 2;
    } 
    for(let i = this.inicio; i < (this.pagina+2); i++) {
      this.colecao.push(i);
    }
  }

  pesquisarPagina(pagina: number) {
    this.pagina = pagina;
    if (this.pagina < 1) {
      this.pagina = 1;
    } else if (this.pagina > this.paginasRegistros) {
      this.pagina = this.paginasRegistros;
    } else {
      this.colecao = [];
      if(this.pagina - 2 >= 0) {
        this.inicio = this.pagina - 2;
      } else {
        this.inicio = this.pagina;
      }
      for(let i = this.inicio; i < (this.pagina+2); i++) {
        this.colecao.push(i);
      }
      this.changePage.emit({paginaAtual: this.pagina})
    }
  }

}

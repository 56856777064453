"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%] {\n  height: 100%; }\n\n.kt-error-v403[_ngcontent-%COMP%] {\n  background-position: center;\n  background-repeat: no-repeat;\n  background-attachment: fixed;\n  background-size: cover; }\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9Vc2Vycy90aGlhZ29vbGl2ZWlyYS9Eb2N1bWVudHMvQ3NvTmNrL1NvbC9jc29fc29sX2dpdC9yZW5hdmUvc3JjL2FwcC92aWV3cy9wYXJ0aWFscy9jb250ZW50L2dlbmVyYWwvZXJyb3IvZXJyb3IuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDQyxZQUFZLEVBQUE7O0FBR2I7RUFDSSwyQkFBMkI7RUFDM0IsNEJBQTRCO0VBQzVCLDRCQUE0QjtFQUM1QixzQkFBc0IsRUFBQSIsImZpbGUiOiJzcmMvYXBwL3ZpZXdzL3BhcnRpYWxzL2NvbnRlbnQvZ2VuZXJhbC9lcnJvci9lcnJvci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcblx0aGVpZ2h0OiAxMDAlO1xufVxuXG4ua3QtZXJyb3ItdjQwMyB7XG4gICAgYmFja2dyb3VuZC1wb3NpdGlvbjogY2VudGVyO1xuICAgIGJhY2tncm91bmQtcmVwZWF0OiBuby1yZXBlYXQ7XG4gICAgYmFja2dyb3VuZC1hdHRhY2htZW50OiBmaXhlZDtcbiAgICBiYWNrZ3JvdW5kLXNpemU6IGNvdmVyO1xufSJdfQ== */"];
exports.styles = styles;



//export const URL_SERVIDOR = "http://localhost:8082/sol/";
export const URL_SERVIDOR = "https://frcolg.hospedagemweb.net/sol/";
// export const URL_SERVIDOR = "https://centralsol.com.br/sol/";


export const environment = {
	production: false,
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
// RxJS
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
// NGRX
var store_1 = require("@ngrx/store");
var auth_selectors_1 = require("../_selectors/auth.selectors");
var lodash_1 = require("lodash");
var ModuleGuard = /** @class */ (function () {
    function ModuleGuard(store, router) {
        this.store = store;
        this.router = router;
    }
    ModuleGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        var moduleName = route.data['moduleName'];
        if (!moduleName) {
            return rxjs_1.of(false);
        }
        return this.store
            .pipe(store_1.select(auth_selectors_1.currentUserPermissions), operators_1.map(function (permissions) {
            var _perm = lodash_1.find(permissions, function (elem) {
                return elem.title.toLocaleLowerCase() === moduleName.toLocaleLowerCase();
            });
            return _perm ? true : false;
        }), operators_1.tap(function (hasAccess) {
            if (!hasAccess) {
                _this.router.navigateByUrl('/error/403');
            }
        }));
    };
    return ModuleGuard;
}());
exports.ModuleGuard = ModuleGuard;

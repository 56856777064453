"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
// NGRX
var store_1 = require("@ngrx/store");
var auth_selectors_1 = require("../_selectors/auth.selectors");
var AuthGuard = /** @class */ (function () {
    function AuthGuard(store, router) {
        this.store = store;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.store
            .pipe(store_1.select(auth_selectors_1.isLoggedIn), operators_1.tap(function (loggedIn) {
            if (!loggedIn) {
                _this.router.navigateByUrl('/auth/login');
            }
        }));
    };
    return AuthGuard;
}());
exports.AuthGuard = AuthGuard;

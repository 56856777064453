"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
// RxJS
var rxjs_1 = require("rxjs");
// Object-Path
var objectPath = require("object-path");
// Service
var menu_config_service_1 = require("./menu-config.service");
var http_service_1 = require("../../../../services/http.service");
var generic_service_1 = require("../../../../services/generic.service");
var autorizacao_service_1 = require("../../../../+autorizacao/autorizacao.service");
var MenuHorizontalService = /** @class */ (function (_super) {
    __extends(MenuHorizontalService, _super);
    /**
     * Service constructor
     *
     * @param menuConfigService: MenuConfigServcie
     */
    function MenuHorizontalService(httpService, authService, menuConfigService) {
        var _this = _super.call(this, httpService, authService) || this;
        _this.menuConfigService = menuConfigService;
        // Public properties
        _this.menuList$ = new rxjs_1.BehaviorSubject({});
        _this.loadMenu();
        return _this;
    }
    /**
     * Load menu list
     */
    MenuHorizontalService.prototype.loadMenu = function () {
        var _this = this;
        this.preencherMenuHorizontal(1).then(function (res) {
            var menuItems = objectPath.get(res, 'items');
            _this.menuList$.next(menuItems);
        });
        this.menuList$.next([]);
    };
    MenuHorizontalService.prototype.preencherMenuHorizontal = function (tipoMenuItem) {
        var recurso = "menuItem/preencherMenuHorizontal";
        var params = "&tipoMenuItem=" + tipoMenuItem;
        return this.requisitarServico(recurso, null, params);
    };
    MenuHorizontalService.prototype.getListaAdministracao = function (tipoMenuItem) {
        var recurso = "menuItem/getListaAdministracao";
        var params = "&tipoMenuItem=" + tipoMenuItem;
        return this.requisitarServico(recurso, null, params);
    };
    MenuHorizontalService.prototype.pesquisar = function (menuItem) {
        var recurso = "menuItem/pesquisar";
        return this.requisitarServico(recurso, menuItem);
    };
    return MenuHorizontalService;
}(generic_service_1.GenericService));
exports.MenuHorizontalService = MenuHorizontalService;

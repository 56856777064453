"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var FetchEntityDialogComponent = /** @class */ (function () {
    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<FetchEntityDialogComponent>,
     * @param data: any
     */
    function FetchEntityDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    /**
     * Close dialog with false result
     */
    FetchEntityDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    /** UI */
    /**
     * Returns CSS Class Name by status type
     * @param status: number
     */
    FetchEntityDialogComponent.prototype.getItemCssClassByStatus = function (status) {
        if (status === void 0) { status = 0; }
        switch (status) {
            case 0: return 'success';
            case 1: return 'metal';
            case 2: return 'danger';
            default: return 'success';
        }
    };
    return FetchEntityDialogComponent;
}());
exports.FetchEntityDialogComponent = FetchEntityDialogComponent;

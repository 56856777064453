"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var i0 = require("@angular/core");
var i1 = require("@angular/router");
var GoogleAnalyticsService = /** @class */ (function () {
    function GoogleAnalyticsService(router) {
        this.router = router;
    }
    GoogleAnalyticsService.prototype.event = function (eventName, params) {
        gtag('event', eventName, params);
    };
    GoogleAnalyticsService.prototype.init = function () {
        this.listenForRouteChanges();
    };
    GoogleAnalyticsService.prototype.listenForRouteChanges = function () {
        this.router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationEnd) {
                gtag('config', 'UA-166936111-1', {
                    'page_path': event.urlAfterRedirects,
                });
            }
        });
    };
    GoogleAnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleAnalyticsService_Factory() { return new GoogleAnalyticsService(i0.inject(i1.Router)); }, token: GoogleAnalyticsService, providedIn: "root" });
    return GoogleAnalyticsService;
}());
exports.GoogleAnalyticsService = GoogleAnalyticsService;

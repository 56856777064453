"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var generic_service_1 = require("../../services/generic.service");
var http_service_1 = require("../../services/http.service");
var _services_1 = require("../../core/auth/_services");
var rxjs_1 = require("rxjs");
var i0 = require("@angular/core");
var i1 = require("../../services/http.service");
var i2 = require("../../+autorizacao/autorizacao.service");
var ContatoService = /** @class */ (function (_super) {
    __extends(ContatoService, _super);
    function ContatoService(httpService, authService) {
        var _this = _super.call(this, httpService, authService) || this;
        _this.imagemPerfil = new rxjs_1.Subject();
        _this.imgSelecionada$ = _this.imagemPerfil.asObservable();
        return _this;
    }
    ContatoService.prototype.setImagemPerfil = function (imagem) {
        this.imagemPerfil.next(imagem);
    };
    ContatoService.prototype.salvar = function (contato) {
        var recurso = "contatoRenave/salvar";
        return this.requisitarServico(recurso, contato);
    };
    ContatoService.prototype.pesquisarPorId = function (contato) {
        var recurso = "contatoRenave/pesquisar-por-id";
        return this.requisitarServico(recurso, contato);
    };
    ContatoService.prototype.ativar = function (contato) {
        var recurso = "contatoRenave/ativar";
        return this.requisitarServico(recurso, contato);
    };
    ContatoService.prototype.desativar = function (contato) {
        var recurso = "contatoRenave/desativar";
        return this.requisitarServico(recurso, contato);
    };
    ContatoService.prototype.pesquisarContatoPorNomeEConcessionaria = function (contato, idConcessionaria, pagina) {
        var recurso = "contatoRenave/pesquisar-contato-por-nome-e-concessionaria";
        var params = this.getPagina(pagina);
        return this.requisitarServicoConcessionaria(recurso, contato, idConcessionaria, params);
    };
    ContatoService.prototype.pesquisarAssociados = function (contato, pagina) {
        var recurso = "concessionaria-contato-perfil/pesquisar-associados";
        var params = this.getPagina(pagina);
        return this.requisitarServico(recurso, contato, params);
    };
    ContatoService.prototype.editar = function (contato) {
        var recurso = "contatoRenave/editar";
        return this.requisitarServico(recurso, contato);
    };
    ContatoService.prototype.associar = function (contato) {
        var recurso = "concessionaria-contato-perfil/associar";
        return this.requisitarServico(recurso, contato);
    };
    ContatoService.prototype.desassociar = function (contato) {
        var recurso = "concessionaria-contato-perfil/desassociar";
        return this.requisitarServico(recurso, contato);
    };
    ContatoService.prototype.atualizarFoto = function (contato) {
        var recurso = "contato/atualizar-foto";
        return this.requisitarServico(recurso, contato);
    };
    ContatoService.prototype.carregarFoto = function (referencia) {
        var recurso = "contato/carregar-foto";
        return this.requisitarServico(recurso, referencia);
    };
    ContatoService.prototype.pesquisarLog = function (logContato) {
        var recurso = "contato/pesquisar-log";
        return this.requisitarServico(recurso, logContato);
    };
    ContatoService.prototype.pesquisarConcessionariaContatoPerfil = function (contato, pagina) {
        var recurso = "concessionaria-contato-perfil/pesquisar";
        var params = this.getPagina(pagina);
        return this.requisitarServico(recurso, contato, params);
    };
    ContatoService.prototype.pesquisarCertificados = function (certificado, pagina) {
        var recurso = "certificado-renave/pesquisar";
        var params = this.getPagina(pagina);
        return this.requisitarServicoConcessionaria(recurso, certificado, certificado.estabelecimento.id, params);
    };
    ContatoService.prototype.cadastrarCertificado = function (certificado) {
        var recurso = "certificado-renave/cadastrar";
        return this.requisitarServicoConcessionaria(recurso, certificado, certificado.estabelecimento.id);
    };
    ContatoService.prototype.downloadCertificado = function (referencia) {
        var recurso = "certificado-renave/download";
        return this.requisitarCertificado(recurso, referencia);
    };
    ContatoService.prototype.ativarCertificado = function (certificado) {
        var recurso = "certificado-renave/ativar";
        return this.requisitarServico(recurso, certificado);
    };
    ContatoService.prototype.desativarCertificado = function (certificado) {
        var recurso = "certificado-renave/desativar";
        return this.requisitarServico(recurso, certificado);
    };
    ContatoService.ngInjectableDef = i0.defineInjectable({ factory: function ContatoService_Factory() { return new ContatoService(i0.inject(i1.HttpService), i0.inject(i2.AutorizacaoService)); }, token: ContatoService, providedIn: "root" });
    return ContatoService;
}(generic_service_1.GenericService));
exports.ContatoService = ContatoService;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _services_1 = require("../core/auth/_services");
var http_service_1 = require("./http.service");
var parametro_1 = require("../models/parametro");
var i0 = require("@angular/core");
var i1 = require("./http.service");
var i2 = require("../+autorizacao/autorizacao.service");
var GenericService = /** @class */ (function () {
    function GenericService(httpService, auth) {
        this.httpService = httpService;
        this.auth = auth;
    }
    GenericService.prototype.requisitarExcel = function (recurso, entidade, params, extensao) {
        var parametro = new parametro_1.Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);
        this.httpService.downloadExcel(parametro, this.auth.getToken(), extensao);
    };
    GenericService.prototype.requisitarPDF = function (recurso, entidade, params) {
        var parametro = new parametro_1.Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);
        this.httpService
            .downloadPDF(parametro, this.auth.getToken());
    };
    GenericService.prototype.requisitarZip = function (recurso, entidade, params) {
        var parametro = new parametro_1.Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);
        this.httpService.downloadZip(parametro, this.auth.getToken());
    };
    GenericService.prototype.requisitarXml = function (recurso, entidade, params) {
        var parametro = new parametro_1.Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);
        this.httpService.downloadXml(parametro, this.auth.getToken());
    };
    GenericService.prototype.showPDF = function (recurso, entidade, params) {
        var parametro = new parametro_1.Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);
        this.httpService.showPDF(parametro, this.auth.getToken());
    };
    GenericService.prototype.requisitarServicoWithoutLoading = function (recurso, entidade, params) {
        var parametro = new parametro_1.Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);
        return this.httpService.sendWithoutLoading(parametro, this.auth.getToken()).then(function (res) {
            return res;
        }).catch(function (res) { return Promise.reject(res); });
    };
    GenericService.prototype.requisitarServico = function (recurso, entidade, params) {
        var parametro = new parametro_1.Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);
        return this.httpService.send(parametro, this.auth.getToken()).then(function (res) {
            return res;
        }).catch(function (res) { return Promise.reject(res); });
    };
    GenericService.prototype.requisitarCertificado = function (recurso, entidade, params) {
        var parametro = new parametro_1.Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);
        this.httpService.downloadCertificado(parametro, this.auth.getToken());
    };
    GenericService.prototype.requisitarServicoConcessionaria = function (recurso, entidade, idConcessionaria, params) {
        var parametro = new parametro_1.Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.idConcessionaria = idConcessionaria;
        parametro.params = this.getParams(params);
        return this.httpService.send(parametro, this.auth.getToken()).then(function (res) {
            return res;
        }).catch(function (res) { return Promise.reject(res); });
    };
    GenericService.prototype.getPagina = function (pagina) {
        return (pagina != null) && (pagina != undefined) ? "&pagina=" + pagina.toString() : "&pagina=1";
    };
    GenericService.prototype.getLimite = function (limite) {
        return (limite != null) && (limite != undefined) ? "&limite=" + limite.toString() : "&limite=15";
    };
    GenericService.prototype.getParams = function (params) {
        return (params != null) && (params != undefined) ? params : "";
    };
    GenericService.ngInjectableDef = i0.defineInjectable({ factory: function GenericService_Factory() { return new GenericService(i0.inject(i1.HttpService), i0.inject(i2.AutorizacaoService)); }, token: GenericService, providedIn: "root" });
    return GenericService;
}());
exports.GenericService = GenericService;

"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]   .kt-aside[_ngcontent-%COMP%] {\n  height: 100%; }\n\n[_nghost-%COMP%]   .kt-menu__link-text[_ngcontent-%COMP%] {\n  white-space: nowrap; }\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9Vc2Vycy90aGlhZ29vbGl2ZWlyYS9Eb2N1bWVudHMvQ3NvTmNrL1NvbC9jc29fc29sX2dpdC9yZW5hdmUvc3JjL2FwcC92aWV3cy90aGVtZXMvZGVtbzIvYXNpZGUvYXNpZGUtbGVmdC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUVFLFlBQVksRUFBQTs7QUFGZDtFQU9FLG1CQUFtQixFQUFBIiwiZmlsZSI6InNyYy9hcHAvdmlld3MvdGhlbWVzL2RlbW8yL2FzaWRlL2FzaWRlLWxlZnQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG5cdC5rdC1hc2lkZSB7XG5cdFx0aGVpZ2h0OiAxMDAlO1xuXHR9XG5cblx0Ly8gZml4ZWQgdGV4dCBsaW5lIGJyZWFrIGlzc3VlIG9uIG1pbmltaXplZCBhc2lkZSBob3ZlclxuXHQua3QtbWVudV9fbGluay10ZXh0IHtcblx0XHR3aGl0ZS1zcGFjZTogbm93cmFwO1xuXHR9XG59XG4iXX0= */"];
exports.styles = styles;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
/**
 * Setup off Convas
 */
var OffcanvasDirective = /** @class */ (function () {
    /**
     * Directive Constructor
     * @param el: ElementRef
     */
    function OffcanvasDirective(el) {
        this.el = el;
    }
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */
    /**
     * After view init
     */
    OffcanvasDirective.prototype.ngAfterViewInit = function () {
        this.offcanvas = new KTOffcanvas(this.el.nativeElement, this.options);
    };
    /**
     * Returns the offCanvas
     */
    OffcanvasDirective.prototype.getOffcanvas = function () {
        return this.offcanvas;
    };
    return OffcanvasDirective;
}());
exports.OffcanvasDirective = OffcanvasDirective;

import { Injectable } from '@angular/core';

import { AutorizacaoService } from './../+autorizacao/autorizacao.service';
import { GenericService } from './generic.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DocsService extends GenericService {

  constructor(httpService: HttpService, authService: AutorizacaoService) {
    super(httpService, authService);
  }

  getUrlArquivo(referencia: string): Promise<string> {
    let recurso = "arquivo/getUrl"
    return this.requisitarServico(recurso, referencia);
  }

  mostrarManualTecnico(): any {
    let recurso = "ajuda/mostrarManualTecnico";
    this.showPDF(recurso);
  }
  mostrarManualSite(): any {
    let recurso = "ajuda/mostrarManualSite";
    this.showPDF(recurso);
  }

}

import { Injectable } from '@angular/core';
import { Usuario } from '../models/usuario';
import { Parametro } from '../models/parametro';
import { HttpService } from '../services/http.service';
import { CookieService } from 'ngx-cookie-service';
import { EncrDecrService } from '../services/encr-decr.service';
import { Senha } from '../models/senha';

@Injectable({
  providedIn: 'root'
})
export class AutorizacaoService {

  private tokenKey: string = 'app_token';
  isLoggedIn: boolean = false;

  constructor(private httpService: HttpService) { }

  efetuarLogin(formLogin: string): Promise<Usuario> {
    let parametro: Parametro = new Parametro();
    parametro.acao = "auth";
    parametro.entidade = formLogin;
    parametro.params = "";
    return this.httpService.send(parametro)
      .then((res) => { return res as Usuario; })
      .catch((res) => Promise.reject(res));
  }

  enviarNovaSenha(usuario: Usuario): Promise<Usuario> {
    let parametro: Parametro = new Parametro();
    parametro.acao = "auth/enviarNovaSenhaRenave";
    parametro.entidade = usuario;
    parametro.params = "";
    return this.httpService.send(parametro)
      .then((res) => {
        return res;
      })
      .catch((res) => Promise.reject(res));
  }

  enviarLinkRedefinirSenha(usuario: Usuario): Promise<Usuario> {
    let parametro: Parametro = new Parametro();
    parametro.acao = "auth/enviarLinkRedefinirSenha";
    parametro.entidade = usuario;
    parametro.params = "";
    return this.httpService.send(parametro).then((res) => {
      return res as Usuario;
    }).catch((res) => Promise.reject(res));
  }

  redefinirSenha(senha: Senha, token: string) {
    // let parametro: Parametro = new Parametro();
    // parametro.acao = "auth/redefinirSenha";
    // parametro.entidade = senha;
    // parametro.params = "";
    // return this.httpService.sendRedefinirSenha(parametro, token).then((res) => {
    //   this.armazenarTokenEncryptedStorage(res.token)
    //   return res as Usuario;
    // }).catch((res) => Promise.reject(res));
  }

  // armazenarTokenEncryptedStorage(token) {
  //   if (typeof (Storage) !== 'undefined') {
  //     var encrypted = this.encrDecrService.set('123456$#@$^@1ERF', token);
  //     sessionStorage.setItem(this.tokenKey, JSON.stringify(encrypted));
  //   }
  // }

  isAutenticado(): boolean {
    if (typeof (Storage) !== 'undefined') {
      if (sessionStorage.getItem(this.tokenKey) === null
        || sessionStorage.getItem(this.tokenKey) === undefined
        || sessionStorage.getItem(this.tokenKey) === '') {
        return false;
      }
    }
    return true;
  }

  download(referencia: string): Promise<string> {
    let parametro: Parametro = new Parametro();
    parametro.acao = "auth/download";
    parametro.entidade = referencia;
    return this.httpService.send(parametro).then((res) => {
      return res as string;
    }).catch((res) => Promise.reject(res));
  }

  getToken() {
    return JSON.parse(sessionStorage.getItem(this.tokenKey));
  }

  logout(): void {
    this.isLoggedIn = false;
  }

}

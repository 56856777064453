"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
/**
 * Returns only first letter of string
 */
var FirstLetterPipe = /** @class */ (function () {
    function FirstLetterPipe() {
    }
    /**
     * Transform
     *
     * @param value: any
     * @param args: any
     */
    FirstLetterPipe.prototype.transform = function (value, args) {
        return value.split(' ').map(function (n) { return n[0]; }).join('');
    };
    return FirstLetterPipe;
}());
exports.FirstLetterPipe = FirstLetterPipe;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SocialNetworks = /** @class */ (function () {
    function SocialNetworks() {
    }
    SocialNetworks.prototype.clear = function () {
        this.linkedIn = '';
        this.facebook = '';
        this.twitter = '';
        this.instagram = '';
    };
    return SocialNetworks;
}());
exports.SocialNetworks = SocialNetworks;

import { Perfil } from "./perfil";
import { TipoMenuItem } from "./tipoMenuItem";
import { Tela } from "./tela";

export class MenuItem {
    
    id: number;
    modulo: number;
    icone: string;
    nome: string;
    menuItemPai: MenuItem;
    perfil: Perfil;
    tipoMenuItem: TipoMenuItem;
    tela: Tela;
    ativo: boolean;
    menuPrincipal: string;
    
    constructor() {
        
    }

}   
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
// Object-Path
var objectPath = require("object-path");
/**
 * Configure menu
 */
var MenuDirective = /** @class */ (function () {
    /**
     * Directive Constructor
     * @param el: ElementRef
     */
    function MenuDirective(el) {
        this.el = el;
    }
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */
    /**
     * After view init
     */
    MenuDirective.prototype.ngAfterViewInit = function () {
        this.setupOptions();
        this.menu = new KTMenu(this.el.nativeElement, this.options);
    };
    /**
     * Return the menu
     */
    MenuDirective.prototype.getMenu = function () {
        return this.menu;
    };
    /**
     * Setup menu options
     */
    MenuDirective.prototype.setupOptions = function () {
        // init aside menu
        var menuDesktopMode = 'accordion';
        if (this.el.nativeElement.getAttribute('data-ktmenu-dropdown') === '1') {
            menuDesktopMode = 'dropdown';
        }
        if (typeof objectPath.get(this.options, 'submenu.desktop') === 'object') {
            objectPath.set(this.options, 'submenu.desktop.default', menuDesktopMode);
        }
    };
    return MenuDirective;
}());
exports.MenuDirective = MenuDirective;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var toast_service_1 = require("../../services/toast.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var forms_1 = require("@angular/forms");
var auth_1 = require("../../core/auth");
var _services_1 = require("../../core/auth/_services");
var ModalLoginComponent = /** @class */ (function () {
    function ModalLoginComponent(router, fb, activatedRoute, toastService, activeModal, authNoticeService, autorizacaoService, modalService) {
        this.router = router;
        this.fb = fb;
        this.activatedRoute = activatedRoute;
        this.toastService = toastService;
        this.activeModal = activeModal;
        this.authNoticeService = authNoticeService;
        this.autorizacaoService = autorizacaoService;
        this.modalService = modalService;
        this.loading = false;
        this.validacao_formulario = {
            'email': [
                { type: 'required', message: 'Campo obrigatório' },
                { type: 'email', message: 'Digite um e-mail válido' }
            ],
            'atual': [
                { type: 'required', message: 'Campo obrigatório' },
            ]
        };
    }
    ModalLoginComponent.prototype.ngOnInit = function () {
        this.configurarFormulario();
        this.form.get('email').setValue(this.email);
    };
    ModalLoginComponent.prototype.efetuarLogin = function () {
        var _this = this;
        try {
            if (this.form.valid && !this.loading) {
                this.loading = true;
                this.autorizacaoService.efetuarLogin(this.form.value).then(function (res) {
                    if (typeof (Storage) !== 'undefined') {
                        sessionStorage.setItem("app_token", JSON.stringify(res.token));
                    }
                    _this.loading = false;
                    _this.activeModal.close(true);
                }).catch(function (error) {
                    _this.toastService.error(error.message);
                    _this.loading = false;
                });
            }
            else {
                this.validateAllFormFields(this.form);
            }
        }
        catch (error) {
        }
    };
    ModalLoginComponent.prototype.configurarFormulario = function () {
        this.form = this.fb.group({
            email: [null, [forms_1.Validators.required, forms_1.Validators.email]],
            atual: [null, forms_1.Validators.required]
        });
    };
    ModalLoginComponent.prototype.validateAllFormFields = function (formGroup) {
        var _this = this;
        Object.keys(formGroup.controls).forEach(function (field) {
            var control = formGroup.get(field);
            if (control instanceof forms_1.FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof forms_1.FormGroup) {
                _this.validateAllFormFields(control);
            }
        });
    };
    return ModalLoginComponent;
}());
exports.ModalLoginComponent = ModalLoginComponent;

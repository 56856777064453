import { Injectable } from '@angular/core';
import { GenericService } from '../../services/generic.service';
import { HttpService } from '../../services/http.service';
import { AutorizacaoService } from '../../core/auth/_services';
import { Contato } from '../../models/contato';
import { Retorno } from '../../models/retorno';
import { ConcessionariaContatoPerfil } from '../../models/concessionaria-contato-perfil';
import { Subject } from 'rxjs';
import { LogContato } from '../../models/log-contato';
import { Certificado } from '../../models/certificado';

@Injectable({
  providedIn: 'root'
})
export class ContatoService extends GenericService {

  private imagemPerfil = new Subject<string>();
  imgSelecionada$ = this.imagemPerfil.asObservable();

  constructor(httpService: HttpService, authService: AutorizacaoService) {
    super(httpService, authService);
  }

  setImagemPerfil(imagem: string) {
    this.imagemPerfil.next(imagem);
  }
  
  salvar(contato: Contato): Promise<Contato> {
    let recurso = "contatoRenave/salvar";
    return this.requisitarServico(recurso, contato);
  }

  pesquisarPorId(contato: Contato): Promise<Contato> {
    let recurso = "contatoRenave/pesquisar-por-id";
    return this.requisitarServico(recurso, contato);
  }

  ativar(contato: Contato): Promise<Contato> {
    let recurso = "contatoRenave/ativar";
    return this.requisitarServico(recurso, contato);
  }

  desativar(contato: Contato): Promise<Contato> {
    let recurso = "contatoRenave/desativar";
    return this.requisitarServico(recurso, contato);
  }

  pesquisarContatoPorNomeEConcessionaria(contato: Contato, idConcessionaria: number, pagina?: number): Promise<Retorno> {
    let recurso = "contatoRenave/pesquisar-contato-por-nome-e-concessionaria";
    let params = this.getPagina(pagina);
    return this.requisitarServicoConcessionaria(recurso, contato, idConcessionaria, params);
  }


  pesquisarAssociados(contato: ConcessionariaContatoPerfil, pagina?: number): Promise<Retorno> {
    let recurso = "concessionaria-contato-perfil/pesquisar-associados";
    let params = this.getPagina(pagina);
    return this.requisitarServico(recurso, contato, params);
  }

  editar(contato: Contato): Promise<Contato> {
    let recurso = "contatoRenave/editar"
    return this.requisitarServico(recurso, contato);
  }

  associar(contato: ConcessionariaContatoPerfil): Promise<Retorno> {
    let recurso = "concessionaria-contato-perfil/associar";
    return this.requisitarServico(recurso, contato);
  }

  desassociar(contato: ConcessionariaContatoPerfil): Promise<Retorno> {
    let recurso = "concessionaria-contato-perfil/desassociar";
    return this.requisitarServico(recurso, contato);
  }

  atualizarFoto(contato: Contato): Promise<string> {
    let recurso = "contato/atualizar-foto";
    return this.requisitarServico(recurso, contato);
  }

  carregarFoto(referencia: string): Promise<string> {
    let recurso = "contato/carregar-foto";
    return this.requisitarServico(recurso, referencia);
  }  

  pesquisarLog(logContato: LogContato): Promise<Retorno> {
    let recurso = "contato/pesquisar-log";
    return this.requisitarServico(recurso, logContato);
  }  

  pesquisarConcessionariaContatoPerfil(contato: ConcessionariaContatoPerfil, pagina?: number): Promise<Retorno> {
    let recurso = "concessionaria-contato-perfil/pesquisar";
    let params = this.getPagina(pagina);
    return this.requisitarServico(recurso, contato, params);
  }

  pesquisarCertificados(certificado: Certificado, pagina?: number): Promise<Retorno> {
    let recurso = "certificado-renave/pesquisar";
    let params = this.getPagina(pagina);
    return this.requisitarServicoConcessionaria(recurso, certificado, certificado.estabelecimento.id, params);
  }   

  cadastrarCertificado(certificado: Certificado) {
    let recurso = "certificado-renave/cadastrar";
    return this.requisitarServicoConcessionaria(recurso, certificado, certificado.estabelecimento.id);
  }    

  downloadCertificado(referencia: string) {
    let recurso = "certificado-renave/download";
    return this.requisitarCertificado(recurso, referencia);
  }    

  ativarCertificado(certificado: Certificado) {
    let recurso = "certificado-renave/ativar";
    return this.requisitarServico(recurso, certificado);
  }    

  desativarCertificado(certificado: Certificado) {
    let recurso = "certificado-renave/desativar";
    return this.requisitarServico(recurso, certificado);
  }      


}

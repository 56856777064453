"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var i0 = require("@angular/core");
var LoadingService = /** @class */ (function () {
    function LoadingService() {
        this.status = new rxjs_1.Subject();
        this._active = false;
    }
    Object.defineProperty(LoadingService.prototype, "active", {
        get: function () {
            return this._active;
        },
        set: function (v) {
            this._active = v;
            this.status.next(v);
        },
        enumerable: true,
        configurable: true
    });
    LoadingService.prototype.start = function () {
        this.active = true;
    };
    LoadingService.prototype.stop = function () {
        this.active = false;
    };
    LoadingService.ngInjectableDef = i0.defineInjectable({ factory: function LoadingService_Factory() { return new LoadingService(); }, token: LoadingService, providedIn: "root" });
    return LoadingService;
}());
exports.LoadingService = LoadingService;

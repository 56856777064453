"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var autorizacao_service_1 = require("../../+autorizacao/autorizacao.service");
var AcessoRestritoGuard = /** @class */ (function () {
    function AcessoRestritoGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    AcessoRestritoGuard.prototype.canActivate = function (route, state) {
        if (this.authService.isAutenticado()) {
            return Promise.resolve(true);
        }
        else {
            this.router.navigate(['/auth/login']);
            return Promise.resolve(false);
        }
    };
    return AcessoRestritoGuard;
}());
exports.AcessoRestritoGuard = AcessoRestritoGuard;

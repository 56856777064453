"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]   .kt-quick-search__category[_ngcontent-%COMP%]:first-child {\n  margin-top: 0; }\n\n.dropdown-item[_ngcontent-%COMP%] {\n  font-size: 1.04rem;\n  padding-bottom: 0.5rem;\n  color: #6c7293; }\n\n.dropdown-item[_ngcontent-%COMP%]:hover {\n  background: #f9f9fc;\n  color: #18a899; }\n\n.dropdown-item[_ngcontent-%COMP%]:active {\n  background: #f9f9fc;\n  color: #18a899; }\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9Vc2Vycy90aGlhZ29vbGl2ZWlyYS9Eb2N1bWVudHMvQ3NvTmNrL1NvbC9jc29fc29sX2dpdC9yZW5hdmUvc3JjL2FwcC92aWV3cy9wYXJ0aWFscy9sYXlvdXQvc2VhcmNoLXJlc3VsdC9zZWFyY2gtcmVzdWx0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBRUUsYUFBYSxFQUFBOztBQUlmO0VBQ0Msa0JBQWtCO0VBQ2xCLHNCQUFzQjtFQUN0QixjQUNELEVBQUE7O0FBRUE7RUFDQyxtQkFBbUI7RUFDbkIsY0FBYyxFQUFBOztBQUVmO0VBQ0MsbUJBQW1CO0VBQ25CLGNBQWMsRUFBQSIsImZpbGUiOiJzcmMvYXBwL3ZpZXdzL3BhcnRpYWxzL2xheW91dC9zZWFyY2gtcmVzdWx0L3NlYXJjaC1yZXN1bHQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG5cdC5rdC1xdWljay1zZWFyY2hfX2NhdGVnb3J5OmZpcnN0LWNoaWxkIHtcblx0XHRtYXJnaW4tdG9wOiAwO1xuXHR9XG59XG5cbi5kcm9wZG93bi1pdGVtIHtcblx0Zm9udC1zaXplOiAxLjA0cmVtO1xuXHRwYWRkaW5nLWJvdHRvbTogMC41cmVtO1xuXHRjb2xvcjogIzZjNzI5M1xufVxuXG4uZHJvcGRvd24taXRlbTpob3ZlciB7XG5cdGJhY2tncm91bmQ6ICNmOWY5ZmM7XG5cdGNvbG9yOiAjMThhODk5O1xufVxuLmRyb3Bkb3duLWl0ZW06YWN0aXZlIHtcblx0YmFja2dyb3VuZDogI2Y5ZjlmYztcdFxuXHRjb2xvcjogIzE4YTg5OTtcbn0iXX0= */"];
exports.styles = styles;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var usuario_service_1 = require("../../../../../administracao/usuario/usuario.service");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var modal_login_component_1 = require("../../../../../modal/modal-login/modal-login.component");
var contato_service_1 = require("../../../../../+sol/contato/contato.service");
var UserProfile2Component = /** @class */ (function () {
    function UserProfile2Component(usuarioService, contatoService, router, modalService) {
        var _this = this;
        this.usuarioService = usuarioService;
        this.contatoService = contatoService;
        this.router = router;
        this.modalService = modalService;
        this.tipoContato = false;
        this.imgPerfil = "assets\\img\\user-default.png";
        this.tempo = 0;
        this.login = false;
        this.exibeMeusDados = false;
        contatoService.imgSelecionada$.subscribe(function (img) { return _this.imgPerfil = img; });
    }
    UserProfile2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.getUsuarioAutenticado();
        var token = rxjs_1.interval(120000);
        this.subscription = token.subscribe(function (val) {
            if (!_this.login) {
                if (_this.tempo < 600000) {
                    _this.getTempoAcesso();
                }
            }
        });
    };
    UserProfile2Component.prototype.getTempoAcesso = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, modalRef;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.usuarioService.getTempoAcesso()];
                    case 1:
                        _a.tempo = _b.sent();
                        if (this.tempo == undefined || this.tempo > 600000) {
                            this.login = true;
                            modalRef = this.modalService.open(modal_login_component_1.ModalLoginComponent, { windowClass: 'modal-padrao', backdrop: 'static', keyboard: false });
                            try {
                                modalRef.componentInstance.email = this.usuarioAutenticado.conta;
                                modalRef.result.then(function (result) {
                                    if (result) {
                                        _this.login = false;
                                        _this.getTempoAcesso();
                                    }
                                    else {
                                        _this.logout();
                                    }
                                });
                            }
                            catch (error) {
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    UserProfile2Component.prototype.logout = function () {
        // this.usuarioService.logoff();
        if (typeof (Storage) !== 'undefined') {
            sessionStorage.clear();
        }
        this.router.navigate(['/auth/login']);
    };
    UserProfile2Component.prototype.getUsuarioAutenticado = function () {
        var _this = this;
        this.usuarioService.getUsuarioAutenticado().then(function (res) {
            _this.usuarioAutenticado = res;
            _this.nomeUsuario = _this.usuarioAutenticado.conta;
            if (_this.usuarioAutenticado.contato) {
                _this.exibeMeusDados = true;
                _this.nomeUsuario = _this.usuarioAutenticado.contato.nome;
                _this.carregarImagem(_this.usuarioAutenticado.contato.referenciaFoto);
                _this.tipoContato = true;
            }
        });
    };
    UserProfile2Component.prototype.carregarImagem = function (referenciaFoto) {
        var _this = this;
        this.contatoService.carregarFoto(referenciaFoto).then(function (res) {
            if (res) {
                _this.imgPerfil = res;
            }
        });
    };
    UserProfile2Component.prototype.navegarMeusDados = function () {
        if (this.usuarioAutenticado.contato) {
            this.navegar("/contato/meus-dados");
        }
        else {
            var navigationExtras = {
                state: {
                    param1: this.usuarioAutenticado.id
                }
            };
            this.router.navigate(["/usuario/editar/"], navigationExtras);
        }
    };
    UserProfile2Component.prototype.navegarAlterarSenha = function () {
        if (this.usuarioAutenticado.contato) {
            this.navegar("/contato/alterar-senha");
        }
        else {
            this.router.navigate(["/usuario/alterar-senha"]);
        }
    };
    UserProfile2Component.prototype.navegarConcessionarias = function () {
        if (this.usuarioAutenticado.contato) {
            this.navegar("/contato/minhas-concessionarias");
        }
    };
    UserProfile2Component.prototype.navegar = function (path) {
        var navigationExtras = {
            state: {
                contato: this.usuarioAutenticado.contato.id
            }
        };
        this.router.navigate([path], navigationExtras);
    };
    UserProfile2Component.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return UserProfile2Component;
}());
exports.UserProfile2Component = UserProfile2Component;

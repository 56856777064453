"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var http_1 = require("@angular/http");
var padrao_exception_1 = require("../models/padrao-exception");
var loading_service_1 = require("./loading.service");
var environment_1 = require("../../environments/environment");
var file_saver_1 = require("file-saver");
var toast_service_1 = require("./toast.service");
var tratar_falha_retorno_files_1 = require("../util/tratar-falha-retorno-files");
var HttpService = /** @class */ (function () {
    function HttpService(http, loading, datePipe, toastService) {
        this.http = http;
        this.loading = loading;
        this.datePipe = datePipe;
        this.toastService = toastService;
        this.headers = new http_1.Headers({ 'Content-Type': 'application/json' });
    }
    HttpService.prototype.showPDF = function (parametro, token) {
        return this.http.post(environment_1.URL_SERVIDOR + parametro.acao + this.getToken(token) + parametro.params, JSON.stringify(parametro), {
            method: http_1.RequestMethod.Post,
            responseType: http_1.ResponseContentType.ArrayBuffer,
            headers: this.headers
        }).subscribe(function (res) {
            var fileURL = URL.createObjectURL(new Blob([res.blob()], { type: 'application/pdf' }));
            window.open(fileURL, '_blank');
        });
    };
    HttpService.prototype.downloadExcel = function (parametro, token, extensao) {
        var ex = (extensao == undefined) ? "xlsx" : extensao;
        this.download(parametro, ex, token);
    };
    HttpService.prototype.downloadPDF = function (parametro, token) {
        this.download(parametro, "pdf", token);
    };
    HttpService.prototype.downloadCertificado = function (parametro, token) {
        this.download(parametro, "pfx", token);
    };
    HttpService.prototype.downloadZip = function (parametro, token) {
        this.download(parametro, "zip", token);
    };
    HttpService.prototype.downloadXml = function (parametro, token) {
        this.download(parametro, "xml", token);
    };
    HttpService.prototype.getTipoArquivo = function (tipo) {
        if (tipo === 'pdf') {
            return 'pdf';
        }
        else if (tipo === 'pfx') {
            return 'x-pkcs12';
        }
        else if (tipo === 'xls') {
            return 'vnd.ms-excel';
        }
        else if (tipo === 'zip') {
            return 'zip';
        }
        else if (tipo === 'xlsx') {
            return 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        }
        else if (tipo === 'xml') {
            return 'xml';
        }
    };
    HttpService.prototype.sendWithoutLoading = function (parametro, token) {
        var _this = this;
        return this.http
            .post(environment_1.URL_SERVIDOR + parametro.acao + "?modulo=3" + this.getToken(token) + parametro.params, JSON.stringify(parametro), { headers: this.headers })
            .toPromise()
            .then(function (response) { return _this.handleSucess(response.json(), true); })
            .catch(function (error) { return _this.handleError(error, true); });
    };
    HttpService.prototype.sendLoading = function (parametro, token) {
        var _this = this;
        return this.http
            .post(environment_1.URL_SERVIDOR + parametro.acao + "?modulo=3" + this.getToken(token) +
            this.getIdConcessionaria(parametro.idConcessionaria) + parametro.params, JSON.stringify(parametro), { headers: this.headers })
            .toPromise()
            .then(function (response) { return _this.handleSucess(response.json()); })
            .catch(function (error) { return _this.handleError(error); });
    };
    HttpService.prototype.send = function (parametro, token) {
        this.loading.start();
        return this.sendLoading(parametro, token);
    };
    HttpService.prototype.getToken = function (token) {
        return (token != null) && (token != undefined) ? "&token=" + token : "";
    };
    HttpService.prototype.getIdConcessionaria = function (idConcessionaria) {
        return (idConcessionaria != null) && (idConcessionaria != undefined) ? "&idConcessionaria=" + idConcessionaria : "";
    };
    HttpService.prototype.showFile = function (file, extensao) {
        var tipoAplicacao = 'application/' + this.getTipoArquivo(extensao);
        var byteArray = new Uint8Array(file);
        var blob = new Blob([byteArray], { type: tipoAplicacao });
        var filename = extensao + "_" + this.datePipe.transform(new Date(), "yyyyMMddhhmmss") + '.' + extensao;
        file_saver_1.saveAs(blob, filename);
    };
    HttpService.prototype.download = function (parametro, extensao, token) {
        var _this = this;
        var tipoAplicacao = 'application/' + this.getTipoArquivo(extensao);
        this.loading.start();
        this.http.post(environment_1.URL_SERVIDOR + parametro.acao + "?modulo=3" + this.getToken(token) + parametro.params, JSON.stringify(parametro), {
            method: http_1.RequestMethod.Post,
            responseType: http_1.ResponseContentType.Blob,
            headers: this.headers
        }).subscribe(function (response) {
            var blob = new Blob([response.blob()], { type: tipoAplicacao });
            var filename = extensao + "_" + _this.datePipe.transform(new Date(), "yyyyMMddhhmmss") + '.' + extensao;
            file_saver_1.saveAs(blob, filename);
            _this.loading.stop();
            _this.toastService.success("Operação realizada com sucesso");
        }, function (error) {
            new tratar_falha_retorno_files_1.TratarFalhaRetornoFiles().tratarRetorno(_this.toastService, error.status);
            _this.loading.stop();
        });
    };
    HttpService.prototype.handleSucess = function (response, withoutLoading) {
        if (!withoutLoading) {
            this.loading.stop();
        }
        if (response.codRetorno == 200) {
            if (response.count) {
                return Promise.resolve(response);
            }
            return Promise.resolve(response.objeto);
        }
        else {
            if (response.codRetorno == 511) {
                sessionStorage.clear();
            }
            return Promise.reject(new padrao_exception_1.PadraoException(response.msgRetorno, response.codRetorno, response.objeto));
        }
    };
    HttpService.prototype.handleError = function (error, withoutLoading) {
        if (!withoutLoading) {
            this.loading.stop();
        }
        var errMsg;
        if (error instanceof Error) {
            return Promise.reject(error);
        }
        else if (error instanceof Response) {
            var body = error.json() || '';
            var err = body || JSON.stringify(body);
            errMsg = error.status + " - " + (error.statusText || '') + " " + err;
        }
        else {
            errMsg = error.message ? error.message : 'Operação indisponível no momento';
        }
        return Promise.reject(new padrao_exception_1.PadraoException(errMsg));
    };
    HttpService.prototype.sendDownload = function (parametro, token) {
        var _this = this;
        this.loading.start();
        this.http
            .post(environment_1.URL_SERVIDOR + parametro.acao + "?modulo=3" + this.getToken(token) + parametro.params, JSON.stringify(parametro), {
            method: http_1.RequestMethod.Post,
            responseType: http_1.ResponseContentType.ArrayBuffer,
            headers: this.headers
        }).subscribe(function (response) {
            var blob = new Blob([response.blob()], { type: "pdf" });
            var filename = "pdf_" + _this.datePipe.transform(new Date(), "yyyyMMddhhmmss") + '.pdf';
            file_saver_1.saveAs(blob, filename);
            _this.loading.stop();
            _this.toastService.success("Operação realizada com sucesso");
        }, function (error) {
            _this.loading.stop();
            _this.toastService.error(new TextDecoder("utf-8").decode(new Uint8Array(error._body)));
        });
    };
    return HttpService;
}());
exports.HttpService = HttpService;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
// Layout
var layout_1 = require("./core/_base/layout");
// Metronic
var metronic_1 = require("./core/_base/metronic");
// language list
var en_1 = require("./core/_config/i18n/en");
var ch_1 = require("./core/_config/i18n/ch");
var es_1 = require("./core/_config/i18n/es");
var jp_1 = require("./core/_config/i18n/jp");
var de_1 = require("./core/_config/i18n/de");
var fr_1 = require("./core/_config/i18n/fr");
var google_analytics_service_1 = require("./services/google-analytics.service");
var AppComponent = /** @class */ (function () {
    /**
     * Component constructor
     *
     * @param translationService: TranslationService
     * @param router: Router
     * @param layoutConfigService: LayoutCongifService
     * @param splashScreenService: SplashScreenService
     */
    function AppComponent(translationService, router, layoutConfigService, splashScreenService, viewContainerRef, analytics) {
        this.translationService = translationService;
        this.router = router;
        this.layoutConfigService = layoutConfigService;
        this.splashScreenService = splashScreenService;
        this.viewContainerRef = viewContainerRef;
        this.analytics = analytics;
        // Public properties
        this.title = 'Metronic';
        this.unsubscribe = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
        // register translations
        this.translationService.loadTranslations(en_1.locale, ch_1.locale, es_1.locale, jp_1.locale, de_1.locale, fr_1.locale);
    }
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */
    /**
     * On init
     */
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.analytics.init();
        // enable/disable loader
        this.loader = this.layoutConfigService.getConfig('loader.enabled');
        var model = this.layoutConfigService.getConfig();
        model.header.self.fixed.desktop.mode = "menu"; //topbar //menu  //all
        this.layoutConfigService.setConfig(model, true);
        var routerSubscription = this.router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationEnd) {
                // hide splash screen
                _this.splashScreenService.hide();
                // scroll to top on every route change
                window.scrollTo(0, 0);
            }
        });
        this.unsubscribe.push(routerSubscription);
    };
    /**
     * On Destroy
     */
    AppComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.forEach(function (sb) { return sb.unsubscribe(); });
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
var animations_1 = require("@angular/animations");
var SplashScreenService = /** @class */ (function () {
    /**
     * Service constctuctor
     *
     * @param animationBuilder: AnimationBuilder
     */
    function SplashScreenService(animationBuilder) {
        this.animationBuilder = animationBuilder;
    }
    /**
     * Init
     *
     * @param element: ElementRef
     */
    SplashScreenService.prototype.init = function (element) {
        this.el = element;
    };
    /**
     * Hide
     */
    SplashScreenService.prototype.hide = function () {
        var _this = this;
        if (this.stopped) {
            return;
        }
        var player = this.animationBuilder.build([
            animations_1.style({ opacity: '1' }),
            animations_1.animate(800, animations_1.style({ opacity: '0' }))
        ]).create(this.el.nativeElement);
        player.onDone(function () {
            if (typeof _this.el.nativeElement.remove === 'function') {
                _this.el.nativeElement.remove();
            }
            else {
                _this.el.nativeElement.style.display = 'none';
            }
            _this.stopped = true;
        });
        setTimeout(function () { return player.play(); }, 300);
    };
    return SplashScreenService;
}());
exports.SplashScreenService = SplashScreenService;

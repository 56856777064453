"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
// Object-Path
var objectPath = require("object-path");
/**
 * Returns object from parent object
 */
var GetObjectPipe = /** @class */ (function () {
    function GetObjectPipe() {
    }
    /**
     * Transform
     *
     * @param value: any
     * @param args: any
     */
    GetObjectPipe.prototype.transform = function (value, args) {
        return objectPath.get(value, args);
    };
    return GetObjectPipe;
}());
exports.GetObjectPipe = GetObjectPipe;

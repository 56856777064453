"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
var layout_1 = require("../../../../core/_base/layout");
var FooterComponent = /** @class */ (function () {
    /**
     * Component constructor
     *
     * @param layoutConfigService: LayouConfigService
     */
    function FooterComponent(layoutConfigService) {
        this.layoutConfigService = layoutConfigService;
        // Public properties
        this.today = Date.now();
    }
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */
    /**
     * On init
     */
    FooterComponent.prototype.ngOnInit = function () {
        var config = this.layoutConfigService.getConfig();
    };
    return FooterComponent;
}());
exports.FooterComponent = FooterComponent;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var pt_1 = require("@angular/common/locales/pt");
// NgBootstrap
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
// Hammer JS
require("hammerjs");
var json = require("highlight.js/lib/languages/json");
var scss = require("highlight.js/lib/languages/scss");
var typescript = require("highlight.js/lib/languages/typescript");
var xml = require("highlight.js/lib/languages/xml");
var ng_recaptcha_1 = require("ng-recaptcha");
// Highlight JS
var ngx_highlightjs_1 = require("ngx-highlightjs");
// Perfect Scroll bar
var ngx_perfect_scrollbar_1 = require("ngx-perfect-scrollbar");
// Layout Services
var layout_1 = require("./core/_base/layout");
// Config
var layout_config_1 = require("./core/_config/default/layout.config");
var i0 = require("@angular/core");
var i1 = require("@ng-bootstrap/ng-bootstrap");
common_1.registerLocaleData(pt_1.default);
// tslint:disable-next-line:class-name
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    wheelSpeed: 0.5,
    swipeEasing: true,
    minScrollbarLength: 40,
    maxScrollbarLength: 300,
};
function initializeLayoutConfig(appConfig) {
    // initialize app by loading default demo layout config
    return function () {
        if (appConfig.getConfig() === null) {
            appConfig.loadConfigs(new layout_config_1.LayoutConfig().configs);
        }
    };
}
exports.initializeLayoutConfig = initializeLayoutConfig;
function hljsLanguages() {
    return [
        { name: 'typescript', func: typescript },
        { name: 'scss', func: scss },
        { name: 'xml', func: xml },
        { name: 'json', func: json }
    ];
}
exports.hljsLanguages = hljsLanguages;
var NgbDateNativeAdapter = /** @class */ (function (_super) {
    __extends(NgbDateNativeAdapter, _super);
    function NgbDateNativeAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NgbDateNativeAdapter.prototype.fromModel = function (date) {
        return (date && date.getFullYear) ? { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() } : null;
    };
    NgbDateNativeAdapter.prototype.toModel = function (date) {
        return date ? new Date(date.year, date.month - 1, date.day) : null;
    };
    NgbDateNativeAdapter.ngInjectableDef = i0.defineInjectable({ factory: i1.ɵi, token: NgbDateNativeAdapter, providedIn: "root" });
    return NgbDateNativeAdapter;
}(ng_bootstrap_1.NgbDateAdapter));
exports.NgbDateNativeAdapter = NgbDateNativeAdapter;
var ɵ0 = {
    siteKey: '6Ld3vsMUAAAAABbxt9o6GyGanC6nnAROV_Lp9aSs',
}, ɵ1 = DEFAULT_PERFECT_SCROLLBAR_CONFIG, ɵ2 = { languages: hljsLanguages };
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
exports.AppModule = AppModule;

"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]     .kt-loading-bar {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  width: 100%; }\n  [_nghost-%COMP%]     .kt-loading-bar .progress-bar {\n    background-color: #3d4aed; }\n  [_nghost-%COMP%]     .kt-header__topbar-item {\n  height: 100%; }\n  @media (min-width: 1025px) {\n  [_nghost-%COMP%]     .kt-header__topbar, [_nghost-%COMP%]     .kt-header__topbar-item-wrapper {\n    height: 100%; }\n  [_nghost-%COMP%]     .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link {\n    background-color: #1dc9b7; } }\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9Vc2Vycy90aGlhZ29vbGl2ZWlyYS9Eb2N1bWVudHMvQ3NvTmNrL1NvbC9jc29fc29sX2dpdC9yZW5hdmUvc3JjL2FwcC92aWV3cy90aGVtZXMvZGVtbzIvaGVhZGVyL2hlYWRlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUdHLGtCQUFrQjtFQUNsQixNQUFNO0VBQ04sT0FBTztFQUNQLFFBQVE7RUFDUixXQUFXLEVBQUE7RUFQZDtJQVVJLHlCQUF5QixFQUFBO0VBVjdCO0VBZUcsWUFBWSxFQUFBO0VBR2I7RUFsQkY7SUFvQkksWUFBWSxFQUFBO0VBcEJoQjtJQXVCSSx5QkFBeUIsRUFBQSxFQUN6QiIsImZpbGUiOiJzcmMvYXBwL3ZpZXdzL3RoZW1lcy9kZW1vMi9oZWFkZXIvaGVhZGVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuXHQ6Om5nLWRlZXAge1xuXHRcdC5rdC1sb2FkaW5nLWJhciB7XG5cdFx0XHRwb3NpdGlvbjogYWJzb2x1dGU7XG5cdFx0XHR0b3A6IDA7XG5cdFx0XHRsZWZ0OiAwO1xuXHRcdFx0cmlnaHQ6IDA7XG5cdFx0XHR3aWR0aDogMTAwJTtcblxuXHRcdFx0LnByb2dyZXNzLWJhciB7XG5cdFx0XHRcdGJhY2tncm91bmQtY29sb3I6ICMzZDRhZWQ7XG5cdFx0XHR9XG5cdFx0fVxuXG5cdFx0Lmt0LWhlYWRlcl9fdG9wYmFyLWl0ZW0ge1xuXHRcdFx0aGVpZ2h0OiAxMDAlO1xuXHRcdH1cblxuXHRcdEBtZWRpYSAobWluLXdpZHRoOiAxMDI1cHgpIHtcblx0XHRcdC5rdC1oZWFkZXJfX3RvcGJhciwgLmt0LWhlYWRlcl9fdG9wYmFyLWl0ZW0td3JhcHBlciB7XG5cdFx0XHRcdGhlaWdodDogMTAwJTtcblx0XHRcdH1cblx0XHRcdC5rdC1oZWFkZXItbWVudSAua3QtbWVudV9fbmF2Pi5rdC1tZW51X19pdGVtLmt0LW1lbnVfX2l0ZW0tLWhlcmU+Lmt0LW1lbnVfX2xpbmt7XG5cdFx0XHRcdGJhY2tncm91bmQtY29sb3I6ICMxZGM5Yjc7XG5cdFx0XHR9XG5cdFx0fVxuXHR9XG59XG5cbiJdfQ== */"];
exports.styles = styles;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ngx_toastr_1 = require("ngx-toastr");
var common_1 = require("@angular/common");
var ToastService = /** @class */ (function () {
    function ToastService(toastr, location) {
        this.toastr = toastr;
        this.location = location;
        this.options = {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            closeButton: false,
            progressBar: false,
            messageClass: 'toast-message'
        };
    }
    ToastService.prototype.success = function (mensagem, titulo, extra) {
        this.toastr.success(mensagem, titulo || "Sucesso", extra || this.options);
    };
    ToastService.prototype.error = function (mensagem, titulo, extra) {
        this.toastr.error(mensagem, titulo || "Erro", extra || this.options);
    };
    ToastService.prototype.info = function (mensagem, titulo, extra) {
        this.toastr.info(mensagem, titulo || "Informação", extra || this.options);
    };
    ToastService.prototype.warning = function (mensagem, titulo, extra) {
        this.toastr.warning(mensagem, titulo || "Alerta", extra || this.options);
    };
    ToastService.prototype.goBack = function () {
        this.location.back();
    };
    return ToastService;
}());
exports.ToastService = ToastService;

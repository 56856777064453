export class PadraoException extends Error {
    
    codigoErro: number;
    objeto: Object;
    
    constructor(message?: string, codigoErro?: number, objeto?: Object) {
        super(message);
        this.codigoErro = codigoErro;
        this.objeto = objeto;
    }
    
}
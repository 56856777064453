"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./subheader2.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./subheader2.component");
var i4 = require("@angular/router");
var i5 = require("../../../../../core/_base/layout/services/subheader.service");
var i6 = require("../../../../../administracao/usuario/usuario.service");
var i7 = require("../../../../../services/toast.service");
var styles_Subheader2Component = [i0.styles];
var RenderType_Subheader2Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Subheader2Component, data: {} });
exports.RenderType_Subheader2Component = RenderType_Subheader2Component;
function View_Subheader2Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "kt-subheader__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_Subheader2Component_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "kt-subheader__desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.desc; _ck(_v, 1, 0, currVal_0); }); }
function View_Subheader2Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "kt-subheader kt-grid__item"], ["id", "kt_subheader"]], [[8, "hidden", 0]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "kt-subheader__main"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Subheader2Component_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "kt-subheader__separator kt-subheader__separator--v"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Subheader2Component_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 9, "div", [["class", "kt-subheader__toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "kt-subheader__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "a", [["href", "javascript:;"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.entrada() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "tamanho-icone"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["+"])), (_l()(), i1.ɵted(-1, null, [" Entrada em Estoque "])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "a", [["href", "javascript:;"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saida() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [["class", "tamanho-icone"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["-"])), (_l()(), i1.ɵted(-1, null, [" Sa\u00EDda de Estoque "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.desc; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.subheaderService.disabled$)); _ck(_v, 0, 0, currVal_0); var currVal_3 = (((_co.router.url !== "/solicitacao/entrada") && (_co.router.url !== "/solicitacao/ite-entrada")) ? "btn btn-label-success btn-bold btn-icon-h" : "btn btn-label-muted btn-bold btn-icon-h disabled"); _ck(_v, 10, 0, currVal_3); var currVal_4 = (((_co.router.url !== "/solicitacao/saida") && (_co.router.url !== "/solicitacao/ite-saida")) ? "btn btn-label-warning btn-bold btn-icon-h" : "btn btn-label-muted btn-bold btn-icon-h disabled"); _ck(_v, 14, 0, currVal_4); }); }
exports.View_Subheader2Component_0 = View_Subheader2Component_0;
function View_Subheader2Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-subheader2", [], null, null, null, View_Subheader2Component_0, RenderType_Subheader2Component)), i1.ɵdid(1, 4440064, null, 0, i3.Subheader2Component, [i4.Router, i5.SubheaderService, i6.UsuarioService, i7.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_Subheader2Component_Host_0 = View_Subheader2Component_Host_0;
var Subheader2ComponentNgFactory = i1.ɵccf("kt-subheader2", i3.Subheader2Component, View_Subheader2Component_Host_0, {}, {}, []);
exports.Subheader2ComponentNgFactory = Subheader2ComponentNgFactory;

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../services/toast.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AuthNoticeService } from '../../core/auth';
import { AutorizacaoService } from '../../core/auth/_services';

@Component({
  selector: 'modal-login',
  styles: [`
  td{
    word-wrap:break-word;
 }
`],
  templateUrl: './modal-login.component.html'
})
export class ModalLoginComponent implements OnInit {
  loading: boolean = false;
  form: FormGroup;
  @Input() email: string
  constructor(public router: Router,
    private fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public toastService: ToastService,
    public activeModal: NgbActiveModal,
    private authNoticeService: AuthNoticeService,
    private autorizacaoService: AutorizacaoService,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    this.configurarFormulario();
    this.form.get('email').setValue(this.email);
  }

  efetuarLogin() {
    try {
      if (this.form.valid && !this.loading) {
        this.loading = true;
        this.autorizacaoService.efetuarLogin(this.form.value).then((res) => {
          if (typeof (Storage) !== 'undefined') {
            sessionStorage.setItem("app_token", JSON.stringify(res.token));
          }
          this.loading = false;
          this.activeModal.close(true);
        }).catch((error) => {
          this.toastService.error(error.message);
          this.loading = false;
        });
      } else {
        this.validateAllFormFields(this.form);
      }
    } catch (error) {

    }
  }

  configurarFormulario() {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      atual: [null, Validators.required]
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  validacao_formulario = {
    'email': [
      { type: 'required', message: 'Campo obrigatório' },
      { type: 'email', message: 'Digite um e-mail válido' }
    ],
    'atual': [
      { type: 'required', message: 'Campo obrigatório' },
    ]
  }
}

import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestMethod, ResponseContentType } from '@angular/http';
import { Parametro } from '../models/parametro';
import { PadraoException } from '../models/padrao-exception';
import { Retorno } from '../models/retorno';
import { LoadingService } from './loading.service';

import { URL_SERVIDOR } from '../../environments/environment';
import { saveAs } from 'file-saver';
import { ToastService } from './toast.service';
import { TratarFalhaRetornoFiles } from '../util/tratar-falha-retorno-files';


@Injectable()
export class HttpService {

    constructor(private http: Http,
        private loading: LoadingService,
        private datePipe: DatePipe,
        private toastService: ToastService) { }

    private headers = new Headers({ 'Content-Type': 'application/json' });

    showPDF(parametro: Parametro, token?: string): any {
        return this.http.post(URL_SERVIDOR + parametro.acao + this.getToken(token) + parametro.params, JSON.stringify(parametro), {
            method: RequestMethod.Post,
            responseType: ResponseContentType.ArrayBuffer,
            headers: this.headers
        }).subscribe((res) => {
            const fileURL = URL.createObjectURL(new Blob([res.blob()], { type: 'application/pdf' }));
            window.open(fileURL, '_blank');
        });
    }

    downloadExcel(parametro: Parametro, token?: string, extensao?: string) {
        let ex = (extensao == undefined) ? "xlsx" : extensao;
        this.download(parametro, ex, token);
    }

    downloadPDF(parametro: Parametro, token?: string) {
        this.download(parametro, "pdf", token);
    }

    downloadCertificado(parametro: Parametro, token?: string) {
        this.download(parametro, "pfx", token);
    }

    downloadZip(parametro: Parametro, token?: string) {
        this.download(parametro, "zip", token);
    }

    downloadXml(parametro: Parametro, token?: string) {
        this.download(parametro, "xml", token);
    }

    getTipoArquivo(tipo: string): string {
        if (tipo === 'pdf') {
            return 'pdf';
        } else if (tipo === 'pfx') {
            return 'x-pkcs12';
        } else if ( tipo === 'xls') {
            return 'vnd.ms-excel';
        } else if (tipo === 'zip') {
            return 'zip';
        } else if ( tipo === 'xlsx') {
            return 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        } else if ( tipo === 'xml') {
            return 'xml';
        }
    }

    sendWithoutLoading(parametro: Parametro, token?: string): Promise<any> {
        return this.http
            .post(URL_SERVIDOR + parametro.acao + "?modulo=3" + this.getToken(token) + parametro.params, JSON.stringify(parametro), { headers: this.headers })
            .toPromise()
            .then(response => this.handleSucess(response.json() as Retorno, true))
            .catch(error => this.handleError(error, true));
    }

    sendLoading(parametro: Parametro, token?: string): Promise<any> {
        return this.http
            .post(URL_SERVIDOR + parametro.acao + "?modulo=3" + this.getToken(token) +
            this.getIdConcessionaria(parametro.idConcessionaria) + parametro.params, JSON.stringify(parametro), { headers: this.headers })
            .toPromise()
            .then(response => this.handleSucess(response.json() as Retorno))
            .catch(error => this.handleError(error));
    }

    send(parametro: Parametro, token?: string): Promise<any> {
        this.loading.start();
        return this.sendLoading(parametro, token);
    }

    getToken(token?: string): string {
        return (token != null) && (token != undefined) ? "&token=" + token : "";
    }

    getIdConcessionaria(idConcessionaria?: number): string {
        return (idConcessionaria != null) && (idConcessionaria != undefined) ? "&idConcessionaria=" + idConcessionaria : "";
    }


    showFile(file: any, extensao: string) {
        let tipoAplicacao: string = 'application/' + this.getTipoArquivo(extensao);
        var byteArray = new Uint8Array(file);
        var blob = new Blob([byteArray], { type: tipoAplicacao });
        var filename = extensao + "_" + this.datePipe.transform(new Date(), "yyyyMMddhhmmss") + '.' + extensao;
        saveAs(blob, filename);        
    }

    private download(parametro: Parametro, extensao: string, token?: string) {
        let tipoAplicacao: string = 'application/' + this.getTipoArquivo(extensao);
        this.loading.start();
        this.http.post(URL_SERVIDOR + parametro.acao + "?modulo=3" + this.getToken(token) + parametro.params, JSON.stringify(parametro), {
            method: RequestMethod.Post,
            responseType: ResponseContentType.Blob,
            headers: this.headers
        }).subscribe(
            response => {
                var blob = new Blob([response.blob()], { type: tipoAplicacao });
                var filename = extensao + "_" + this.datePipe.transform(new Date(), "yyyyMMddhhmmss") + '.' + extensao;
                saveAs(blob, filename);
                this.loading.stop();
                this.toastService.success("Operação realizada com sucesso");
            },
            error => {
                new TratarFalhaRetornoFiles().tratarRetorno(this.toastService, error.status);
                this.loading.stop();
            }
        );
    }

    private handleSucess(response: Retorno, withoutLoading?: boolean): Promise<any> {
        if (!withoutLoading) {
            this.loading.stop();
        }
        if (response.codRetorno == 200) {
            if (response.count) {
                return Promise.resolve(response as any);
            }
            return Promise.resolve(response.objeto as any);
        } else {
            if (response.codRetorno == 511) {
                sessionStorage.clear();
            }
            return Promise.reject(new PadraoException(response.msgRetorno, response.codRetorno, response.objeto));
        }
    }

    private handleError(error: Response | any, withoutLoading?: boolean) {
        if (!withoutLoading) {
            this.loading.stop();
        }
        let errMsg: string;
        if (error instanceof Error) {
            return Promise.reject(error);
        } else if (error instanceof Response) {
            const body = error.json() || '';
            const err = body || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : 'Operação indisponível no momento';
        }
        return Promise.reject(new PadraoException(errMsg));
    }

    sendDownload(parametro: Parametro, token?: string) {
        this.loading.start();
        this.http
            .post(URL_SERVIDOR + parametro.acao + "?modulo=3" + this.getToken(token) + parametro.params, JSON.stringify(parametro), {
                method: RequestMethod.Post,
                responseType: ResponseContentType.ArrayBuffer,
                headers: this.headers
            }
            ).subscribe(response => {
                var blob = new Blob([response.blob()], { type: "pdf" });
                var filename = "pdf_" + this.datePipe.transform(new Date(), "yyyyMMddhhmmss") + '.pdf';
                saveAs(blob, filename);
                this.loading.stop();
                this.toastService.success("Operação realizada com sucesso");
            },
                error => {
                    this.loading.stop();
                    this.toastService.error(new TextDecoder("utf-8").decode(new Uint8Array(error._body)));
                });
    }

}
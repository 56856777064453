import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

declare var gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {

    constructor(private router: Router) {

    }

    public event(eventName: string, params: {}) {
        gtag('event', eventName, params);
    }

    public init() {
        this.listenForRouteChanges();
    }

    private listenForRouteChanges() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                gtag('config', 'UA-166936111-1', {
                    'page_path': event.urlAfterRedirects,
                });
            }
        });
    }
}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
// Module components
var auth_component_1 = require("./auth.component");
var login_component_1 = require("./login/login.component");
var register_component_1 = require("./register/register.component");
var forgot_password_component_1 = require("./forgot-password/forgot-password.component");
// Auth
var auth_1 = require("../../../core/auth");
var routes = [
    {
        path: '',
        component: auth_component_1.AuthComponent,
        children: [
            {
                path: '',
                redirectTo: 'login',
                pathMatch: 'full'
            },
            {
                path: 'login',
                component: login_component_1.LoginComponent
            },
            {
                path: 'register',
                component: register_component_1.RegisterComponent
            },
            {
                path: 'forgot-password',
                component: forgot_password_component_1.ForgotPasswordComponent,
            }
        ]
    }
];
var AuthModule = /** @class */ (function () {
    function AuthModule() {
    }
    AuthModule.forRoot = function () {
        return {
            ngModule: AuthModule,
            providers: [
                //AuthService,
                auth_1.AuthGuard
            ]
        };
    };
    return AuthModule;
}());
exports.AuthModule = AuthModule;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var API_DATATABLE_URL = 'api/cars';
var DataTableService = /** @class */ (function () {
    /**
     * Service Constructor
     *
     * @param http: HttpClient
     */
    function DataTableService(http) {
        this.http = http;
    }
    /**
     * Returns data from fake server
     */
    DataTableService.prototype.getAllItems = function () {
        return this.http.get(API_DATATABLE_URL);
    };
    return DataTableService;
}());
exports.DataTableService = DataTableService;

<div ngbDropdown placement="bottom-right" class="kt-header__topbar-item kt-header__topbar-item--user">
	<div ngbDropdownToggle class="kt-header__topbar-wrapper">
		<span class="kt-header__topbar-welcome kt-hidden-mobile">Olá,</span>
		<span class="kt-header__topbar-username kt-hidden-mobile">{{ nomeUsuario }}</span>
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
			viewBox="0 0 24 24" version="1.1" class="kt-svg-icon kt-svg-icon--success">
			<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<polygon id="Shape" points="0 0 24 0 24 24 0 24" />
				<path
					d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
					id="Mask" fill="#000000" fill-rule="nonzero" opacity="0.3" />
				<path
					d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
					id="Mask-Copy" fill="#000000" fill-rule="nonzero" />
			</g>
		</svg>
	</div>
	<div ngbDropdownMenu
		class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
		<!--begin: Head -->

		<div class="kt-user-card kt-user-card--skin-light kt-notification-item-padding-x"
			[ngStyle]="{'background-image': 'url(./assets/media/misc/bg-4.jpg)'}">
			<div class="kt-mycart__info">
				<div class="kt-avatar kt-avatar--outline kt-avatar--circle-" id="kt_apps_user_add_avatar">
					<div class="kt-avatar__holder"><img src="{{imgPerfil}}" height="74px" width="74px"></div>
				</div>
			</div>
			<div class="kt-user-card__name">
				<span class="font-nome-usuario">{{ nomeUsuario }}</span>
			</div>				
		</div>
		<!--end: Head -->

		<!--begin: Navigation -->
		<div class="kt-notification">
			<a (click)="navegarMeusDados()" class="kt-notification__item" *ngIf="exibeMeusDados" >
				<div class="kt-notification__item-icon">
					<i class="flaticon2-calendar-3 kt-font-success"></i>
				</div>
				<div class="kt-notification__item-details">
					<div class="kt-notification__item-title kt-font-bold">
						Meus dados
					</div>
					<div class="kt-notification__item-time">
						Informações cadastrais do usuário
					</div>
				</div>
			</a>
			
			<a (click)="navegarAlterarSenha()" href="javascript:;" class="kt-notification__item">
				<div class="kt-notification__item-icon">
					<i class="fa fa-key kt-font-warning"></i>
				</div>
				<div class="kt-notification__item-details">
					<div class="kt-notification__item-title kt-font-bold">
						Alterar Senha
					</div>
					<div class="kt-notification__item-time">
						Mantenha sua senha protegida
					</div>
				</div>
			</a>
			
			<a *ngIf="tipoContato" (click)="navegarConcessionarias()" href="javascript:;" class="kt-notification__item">
				<div class="kt-notification__item-icon">
					<i class="fa fa-building kt-font-brand"></i>
				</div>
				<div class="kt-notification__item-details">
					<div class="kt-notification__item-title kt-font-bold">
						Estabelecimentos
					</div>
					<div class="kt-notification__item-time">
						Lista de estabelecimentos associados
					</div>
				</div>
			</a>
			<!--
			<a routerLink="profile" href="javascript:;" class="kt-notification__item">
				<div class="kt-notification__item-icon">
					<i class="flaticon2-hourglass kt-font-brand"></i>
				</div>
				<div class="kt-notification__item-details">
					<div class="kt-notification__item-title kt-font-bold">
						My Tasks
					</div>
					<div class="kt-notification__item-time">
						latest tasks and projects
					</div>
				</div>
			</a>
		-->
			<div class="kt-notification__custom">
				<a  href="javascript:;" (click)="logout()" class="btn btn-outline-success btn-upper btn-sm btn-bold">
					Sair
				</a>
			</div>
		</div>
		<!--end: Navigation -->

		
	</div>
</div>
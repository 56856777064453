import { Directive, ElementRef, HostListener, } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[numbersOnlyFormControl]',
})
export class NumberOnlyFormControlDirective {
  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])  
  onInputChange(event) {
    const initalValue = event;
    let value = null;
    if (initalValue) {
      value = initalValue.toString().replace(/[^0-9]*/g, '')
      if (value == '') {
        value = null;
      }
    }
    if (initalValue !== value) {
      this.ngControl.valueAccessor.writeValue(value);
      this.ngControl.control.setValue(value);
    }
  }

}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var perguntas_frequentes_1 = require("../../../models/perguntas-frequentes");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var ModalRespostaPerguntasFrequentesComponent = /** @class */ (function () {
    function ModalRespostaPerguntasFrequentesComponent(activeModal) {
        this.activeModal = activeModal;
    }
    ModalRespostaPerguntasFrequentesComponent.prototype.ngOnInit = function () {
    };
    ModalRespostaPerguntasFrequentesComponent.prototype.pesquisar = function () { };
    return ModalRespostaPerguntasFrequentesComponent;
}());
exports.ModalRespostaPerguntasFrequentesComponent = ModalRespostaPerguntasFrequentesComponent;

// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Service
import { MenuConfigService } from './menu-config.service';
import { MenuItem } from '../../../../models/menuItem';
import { HttpService } from '../../../../services/http.service';
import { GenericService } from '../../../../services/generic.service';
import { AutorizacaoService } from '../../../../+autorizacao/autorizacao.service';

@Injectable()
export class MenuHorizontalService extends GenericService {
	// Public properties
	menuList$: BehaviorSubject<any> = new BehaviorSubject({});
	
	/**
	 * Service constructor
	 *
	 * @param menuConfigService: MenuConfigServcie
	 */
	constructor(httpService: HttpService,
		authService: AutorizacaoService,
		private menuConfigService: MenuConfigService) {
		super(httpService, authService);
		this.loadMenu();
	}

	/**
	 * Load menu list
	 */
	loadMenu() {
		this.preencherMenuHorizontal(1).then((res) => {
			const menuItems = objectPath.get(res, 'items');
			this.menuList$.next(menuItems);			
		});
		this.menuList$.next([]);
	}
	preencherMenuHorizontal(tipoMenuItem: number): Promise<any> {
		let recurso = "menuItem/preencherMenuHorizontal";
		let params = "&tipoMenuItem=" + tipoMenuItem;
		return this.requisitarServico(recurso, null, params);
	}
	getListaAdministracao(tipoMenuItem: number): Promise<any> {
		let recurso = "menuItem/getListaAdministracao";
		let params = "&tipoMenuItem=" + tipoMenuItem;
		return this.requisitarServico(recurso, null, params);
	}

	pesquisar(menuItem: MenuItem): Promise<MenuItem[]> {
		let recurso = "menuItem/pesquisar";
		return this.requisitarServico(recurso, menuItem);
	}

}

<div class="modal-header">
  <h4 class="modal-title"><b>Perguntas Frequentes</b></h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="kt-portlet__body">
    <div class="col-lg-9">
      <label class="col-form-label">Descrição</label>
      <input type="text" class="form-control" [(ngModel)]="filtro.titulo">
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="pesquisar()">
    Pesquisar
  </button>
</div>
<div class="kt-portlet kt-portlet--height-fluid">
  <div class="kt-portlet__body" style="padding-top: 0px;">
    <table datatable [dtOptions]="dtOptions" class="row-border table-striped table-hover">
      <thead>
        <tr>
          <th>Perguntas</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let obj of listaPerguntasFrequentes">
          <td><a (click)="mostrarResposta(obj)">{{ obj.titulo }}</a></td>
        </tr>
      </tbody>
    </table>
    <div>
      <app-paginador [totalRegistros]="qtdPerguntasFrequentes" (changePage)="onChangePage($event)"></app-paginador>
    </div>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="activeModal.close('Close click')">Fechar</button>
  </div> -->
</div>
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Auth
var auth_1 = require("../../../../auth");
// ECommerce
var e_commerce_1 = require("../../../../e-commerce");
// Models
var cars_1 = require("./fake-db/cars");
var FakeApiService = /** @class */ (function () {
    /**
     * Service Constructore
     */
    function FakeApiService() {
    }
    /**
     * Create Fake DB and API
     */
    FakeApiService.prototype.createDb = function () {
        // tslint:disable-next-line:class-name
        var db = {
            // auth module
            users: auth_1.AuthDataContext.users,
            roles: auth_1.AuthDataContext.roles,
            permissions: auth_1.AuthDataContext.permissions,
            // e-commerce
            // customers
            customers: e_commerce_1.ECommerceDataContext.customers,
            // products
            products: e_commerce_1.ECommerceDataContext.cars,
            productRemarks: e_commerce_1.ECommerceDataContext.remarks,
            productSpecs: e_commerce_1.ECommerceDataContext.carSpecs,
            // orders
            orders: e_commerce_1.ECommerceDataContext.orders,
            // data-table
            cars: cars_1.CarsDb.cars
        };
        return db;
    };
    return FakeApiService;
}());
exports.FakeApiService = FakeApiService;

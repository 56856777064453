"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var sweetalert2_1 = require("sweetalert2");
var ControlGeneric = /** @class */ (function () {
    function ControlGeneric(router, activatedRoute, toastService) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.toastService = toastService;
        this.dtOptions = {};
        this.alertConfirmConfigurationDefault = {
            title: "Deseja excluir esse registro?",
            type: 'warning',
            confirmButtonText: "Sim",
            confirmButtonColor: "#1dc9b7",
            cancelButtonText: "Não",
            cancelButtonColor: "#dc3545",
            showCancelButton: true
        };
        this.alertErrorConfigurationDefault = {
            type: 'error',
            confirmButtonColor: "#dc3545"
        };
        this.alertSuccessConfigurationDefault = {
            title: "Operação realizada com sucesso",
            type: 'success',
            confirmButtonColor: "#1dc9b7"
        };
        this.alertConfirmImpressaoDefault = {
            type: 'warning',
            confirmButtonText: "Imprimir",
            confirmButtonColor: "#1dc9b7",
            cancelButtonText: "Cancelar",
            cancelButtonColor: "#dc3545",
            showCancelButton: true
        };
        this.dtOptions = {
            paging: false,
            responsive: true,
            searching: false,
            ordering: false,
            info: false,
            language: {
                emptyTable: "",
                zeroRecords: ""
            }
        };
    }
    ControlGeneric.prototype.ngOnDestroy = function () {
        if (this.sub != undefined) {
            this.sub.unsubscribe();
        }
    };
    ControlGeneric.prototype.tratarExcecao = function (error, voltar) {
        if (error.message != '') {
            this.toastService.error(error.message);
        }
        if (error.codigoErro == 511) {
            this.router.navigate(['/auth/login']);
        }
        if (voltar) {
            this.toastService.goBack();
        }
    };
    ControlGeneric.prototype.noWhitespaceValidator = function (control) {
        var isWhitespace = (control.value || '').trim().length === 0;
        var isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    };
    ControlGeneric.prototype.swalQuestion = function (pergunta) {
        this.alertConfirmConfigurationDefault.title = pergunta;
        return sweetalert2_1.default.fire(this.alertConfirmConfigurationDefault).then(function (resposta) {
            return Promise.resolve(resposta.value);
        });
    };
    ControlGeneric.prototype.swalError = function (mensagem, icone) {
        this.alertErrorConfigurationDefault.title = mensagem;
        sweetalert2_1.default.fire(this.alertErrorConfigurationDefault);
    };
    ControlGeneric.prototype.enviarParametros = function (path, parametro) {
        var navigationExtras = {
            state: {
                param1: parametro
            }
        };
        this.router.navigate([path], navigationExtras);
    };
    ControlGeneric.prototype.swalQuestionWithObs = function (pergunta, obs) {
        this.alertConfirmConfigurationDefault.title = pergunta;
        this.alertConfirmConfigurationDefault.footer = obs;
        return sweetalert2_1.default.fire(this.alertConfirmConfigurationDefault).then(function (resposta) {
            return Promise.resolve(resposta.value);
        });
    };
    return ControlGeneric;
}());
exports.ControlGeneric = ControlGeneric;

import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingService } from '../../../../services/loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  public active: boolean = false;
  loadingSubscription: Subscription;

  constructor(private loading: LoadingService) {
    this.loadingSubscription = this.loading.status.subscribe((status: boolean) => {
      setTimeout(() => this.active = status);
    });
  }

  ngOnInit() { }

}

"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./app.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./views/partials/layout/splash-screen/splash-screen.component.ngfactory");
var i3 = require("./views/partials/layout/splash-screen/splash-screen.component");
var i4 = require("./core/_base/layout/services/layout-config.service");
var i5 = require("./core/_base/layout/services/splash-screen.service");
var i6 = require("@angular/router");
var i7 = require("@angular/common");
var i8 = require("./app.component");
var i9 = require("./core/_base/metronic/services/translation.service");
var i10 = require("./services/google-analytics.service");
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
exports.RenderType_AppComponent = RenderType_AppComponent;
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-splash-screen", [], null, null, null, i2.View_SplashScreenComponent_0, i2.RenderType_SplashScreenComponent)), i1.ɵdid(1, 114688, null, 0, i3.SplashScreenComponent, [i1.ElementRef, i4.LayoutConfigService, i5.SplashScreenService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.loader; _ck(_v, 3, 0, currVal_0); }, null); }
exports.View_AppComponent_0 = View_AppComponent_0;
function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "body", [["kt-root", ""]], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i8.AppComponent, [i9.TranslationService, i6.Router, i4.LayoutConfigService, i5.SplashScreenService, i1.ViewContainerRef, i10.GoogleAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AppComponent_Host_0 = View_AppComponent_Host_0;
var AppComponentNgFactory = i1.ɵccf("body[kt-root]", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
exports.AppComponentNgFactory = AppComponentNgFactory;

import { NivelPerfil } from "./nivel-perfil";

export class Perfil {
    id: number;
    nome: string;
    ativo: boolean;
    manutencao: boolean;
    telaAcao: string[];
    nivelPerfil: NivelPerfil;
    contato: boolean;
    modulo: number;
}
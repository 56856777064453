"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]     .kt-header__topbar > :last-child:not([role=\"tooltip\"]) .kt-header__topbar-item {\n  margin-right: 0 !important; }\n\n[_nghost-%COMP%]     .kt-header__topbar .kt-header__topbar-item {\n  margin-right: 0.36rem !important; }\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9Vc2Vycy90aGlhZ29vbGl2ZWlyYS9Eb2N1bWVudHMvQ3NvTmNrL1NvbC9jc29fc29sX2dpdC9yZW5hdmUvc3JjL2FwcC92aWV3cy90aGVtZXMvZGVtbzIvaGVhZGVyL3RvcGJhci90b3BiYXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFNSywwQkFBMEIsRUFBQTs7QUFOL0I7RUFVSSxnQ0FBZ0MsRUFBQSIsImZpbGUiOiJzcmMvYXBwL3ZpZXdzL3RoZW1lcy9kZW1vMi9oZWFkZXIvdG9wYmFyL3RvcGJhci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcblx0OjpuZy1kZWVwIHtcblx0XHQvLyBvdmVycmlkZSBkZWZhdWx0IHZlcnNpb24gc3R5bGVcblx0XHQua3QtaGVhZGVyX190b3BiYXIge1xuXHRcdFx0PiA6bGFzdC1jaGlsZDpub3QoW3JvbGU9XCJ0b29sdGlwXCJdKSB7XG5cdFx0XHRcdC5rdC1oZWFkZXJfX3RvcGJhci1pdGVtIHtcblx0XHRcdFx0XHRtYXJnaW4tcmlnaHQ6IDAgIWltcG9ydGFudDtcblx0XHRcdFx0fVxuXHRcdFx0fVxuXHRcdFx0Lmt0LWhlYWRlcl9fdG9wYmFyLWl0ZW0ge1xuXHRcdFx0XHRtYXJnaW4tcmlnaHQ6IDAuMzZyZW0gIWltcG9ydGFudDtcblx0XHRcdH1cblx0XHR9XG5cdH1cbn1cbiJdfQ== */"];
exports.styles = styles;

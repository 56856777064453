import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentesModule } from '../componentes/componentes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from '../pipes/pipes.module';
import { CoreModule } from '../core/core.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { DataTablesModule } from 'angular-datatables';
import { ModalAvisoComponent } from './modal-aviso/modal-aviso.component';
import { ModalLoginComponent } from './modal-login/modal-login.component';
import { ModalPesquisaPerguntasFrequentesComponent } from './perguntas-frequentes/modal-pesquisa-perguntas-frequentes/modal-pesquisa-perguntas-frequentes.component';
import { ModalConsultaAptidaoComponent } from './modal-consulta-aptidao/modal-consulta-aptidao.component';
import { ModalRespostaPerguntasFrequentesComponent } from './perguntas-frequentes/modal-resposta-perguntas-frequentes/modal-resposta-perguntas-frequentes.component';
import { ModalMarcaModeloComponent } from './modal-marca-modelo/modal-marca-modelo.component';
import { ModalPesquisaIdAutorizacaoComponent } from './modal-pesquisa-id-autorizacao/modal-pesquisa-id-autorizacao.component';
import { ModalRejeicaoMontadoraComponent } from './modal-rejeicao-montadora/modal-rejeicao-montadora.component';
import { ModalDevolucaoMontadoraComponent } from './modal-devolucao-montadora/modal-devolucao-montadora.component';
import { DetalheImportacaoArquivoComponent } from './detalhe-importacao-arquivo/detalhe-importacao-arquivo.component';
import { ModalPesquisaIdAutorizacaoIteComponent } from './modal-pesquisa-id-autorizacao-ite/modal-pesquisa-id-autorizacao-ite.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ComponentesModule,
    NgbModule,
    PipesModule,
    CoreModule,
    FormsModule,
    CurrencyMaskModule,
    DataTablesModule
  ],
  declarations: [
    ModalAvisoComponent, 
    ModalLoginComponent,
    ModalPesquisaPerguntasFrequentesComponent,
    ModalRespostaPerguntasFrequentesComponent,
    ModalConsultaAptidaoComponent,
    ModalMarcaModeloComponent,
    ModalPesquisaIdAutorizacaoComponent,
    ModalRejeicaoMontadoraComponent,
    ModalDevolucaoMontadoraComponent,
    DetalheImportacaoArquivoComponent,
    ModalPesquisaIdAutorizacaoIteComponent
  ],
  exports: [
    ModalAvisoComponent,
    ModalLoginComponent,
    ModalPesquisaPerguntasFrequentesComponent
  ],
  entryComponents: [

  ],
  providers: []

})
export class ModalModule { }

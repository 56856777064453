import { Router, ActivatedRoute } from "@angular/router";

import { ControlGeneric } from './control-generic';
import { ToastService } from '../services/toast.service';

export abstract class PesquisaGenerica extends ControlGeneric {

    paginaAtual = 1;
    exibeTabela = false;

    constructor(public router: Router,
                public activatedRoute: ActivatedRoute,
                public toastService: ToastService) { 
            super(router, activatedRoute, toastService);
    }

    abstract pesquisar(pagina?: number);

    onChangePage(paginador) {
        this.paginaAtual = paginador.paginaAtual;
        this.pesquisar(paginador.paginaAtual);
    }
}
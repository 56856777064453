"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_component_1 = require("./views/pages/auth/auth.component");
var base_component_1 = require("./views/themes/demo2/base/base.component");
var acesso_restrito_guard_1 = require("./services/guard/acesso-restrito.guard");
var routes = [
    {
        path: '', component: base_component_1.BaseComponent,
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/+dashboard/dashboard.module#DashboardModule' },
            { path: 'perfil', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/manutencao/perfil/perfil.module#PerfilModule' },
            { path: 'acao', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/manutencao/acao/acao.module#AcaoModule' },
            { path: 'tela', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/manutencao/tela/tela.module#TelaModule' },
            { path: 'menu', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/manutencao/menu/menu.module#MenuModule' },
            { path: 'usuario', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/administracao/usuario/usuario.module#UsuarioModule' },
            { path: 'aviso', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/administracao/aviso/aviso.module#AvisoModule' },
            { path: 'perguntas-frequentes', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/administracao/perguntas-frequentes/perguntas-frequentes.module#PerguntasFrequentesModule' },
            { path: 'contato', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/+sol/contato/contato.module#ContatoModule' },
            { path: 'concessionaria', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/+sol/concessionaria/concessionaria.module#ConcessionariaModule' },
            { path: 'grupo-concessionaria', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/administracao/grupo-concessionaria/grupo-concessionaria.module#GrupoConcessionariaModule' },
            { path: 'solicitacao', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/+sol/solicitacao/solicitacao.module#SolicitacaoModule' },
            { path: 'estoque', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/+sol/estoque/estoque.module#EstoqueModule' },
            { path: 'consulta', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/+sol/consulta/consulta.module#ConsultaModule' },
            { path: 'impressao-crlve', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/+sol/impressao-crlve/impressao-crlve.module#ImpressaoCrlveModule' },
            { path: 'impressao-atpve', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/+sol/impressao-atpv-e/impressao-atpve.module#ImpressaoAtpveModule' },
            { path: 'atpv-e', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/+sol/atpv-e/atpv-e.module#AtpvEModule' },
            { path: 'importacao', canActivate: [acesso_restrito_guard_1.AcessoRestritoGuard], loadChildren: 'app/+sol/importacao/importacao.module#ImportacaoModule' },
        ]
    },
    { path: 'auth', component: auth_component_1.AuthComponent, loadChildren: 'app/+autorizacao/autorizacao.module#AutorizacaoModule' },
    { path: '**', redirectTo: 'dashboard' },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
exports.AppRoutingModule = AppRoutingModule;

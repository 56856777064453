"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// RxJS
var rxjs_1 = require("rxjs");
// Object path
var objectPath = require("object-path");
// Services
var menu_config_service_1 = require("./menu-config.service");
var MenuAsideService = /** @class */ (function () {
    /**
     * Service Constructor
     *
     * @param menuConfigService: MenuConfigService
     * @param store: Store<AppState>
     */
    function MenuAsideService(menuConfigService) {
        this.menuConfigService = menuConfigService;
        this.menuList$ = new rxjs_1.BehaviorSubject([]);
        this.loadMenu();
    }
    /**
     * Load menu
     */
    MenuAsideService.prototype.loadMenu = function () {
        // get menu list
        var menuItems = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
        this.menuList$.next(menuItems);
    };
    return MenuAsideService;
}());
exports.MenuAsideService = MenuAsideService;

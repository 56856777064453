// Angular
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Layout
import { SubheaderService } from '../../../../../core/_base/layout';
import { Breadcrumb } from '../../../../../core/_base/layout/services/subheader.service';
import { Router } from '@angular/router';
import { UsuarioService } from '../../../../../administracao/usuario/usuario.service';
import { ToastService } from '../../../../../services/toast.service';
import { Usuario } from '../../../../../models/usuario';

@Component({
	selector: 'kt-subheader2',
	templateUrl: './subheader2.component.html',
	styleUrls: ['./subheader2.component.scss']
})
export class Subheader2Component implements OnInit, OnDestroy, AfterViewInit {
	// Public properties
	today: number = Date.now();
	title: string = '';
	desc: string = '';
	breadcrumbs: Breadcrumb[] = [];
	ativo: boolean = true;
	usuarioAutenticado: Usuario;

	// Private properties
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param subheaderService: SubheaderService
	 */
	constructor(public router: Router, 
				public subheaderService: SubheaderService,
				private usuarioService: UsuarioService,
				private toastService: ToastService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.getUsuarioAutenticado();
	}


	getUsuarioAutenticado() {
		this.usuarioService.getUsuarioAutenticado().then((res) => {
			this.usuarioAutenticado = res;
		});
		
	}

	/**
	 * After view init
	 */
	ngAfterViewInit(): void {
		this.subscriptions.push(this.subheaderService.title$.subscribe(bt => {
			// breadcrumbs title sometimes can be undefined
			if (bt) {
				Promise.resolve(null).then(() => {
					this.title = bt.title;
					this.desc = bt.desc;
				});
			}
		}));

		this.subscriptions.push(this.subheaderService.breadcrumbs$.subscribe(bc => {
			Promise.resolve(null).then(() => {
				this.breadcrumbs = bc;
			});
		}));
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

	entrada() {
		if(this.usuarioAutenticado && this.usuarioAutenticado.perfil && this.usuarioAutenticado.perfil.id == 21){
			this.router.navigate(['solicitacao/ite-entrada']);
		}else{
			this.router.navigate(['solicitacao/entrada']);
		}
		
		// this.usuarioService.verificarPermissao("enviar", "envio-contrato").then(() => {
		// 	this.router.navigate(['contratos/cadastro']);
		// }).catch((error) => {
		//   this.tratarExcecao(error);
		// });
	}

	saida() {
		if(this.usuarioAutenticado && this.usuarioAutenticado.perfil && this.usuarioAutenticado.perfil.id == 21){
			this.router.navigate(['solicitacao/ite-saida']);
		}else{
			this.router.navigate(['solicitacao/saida']);
		}
	
		// this.usuarioService.verificarPermissao("enviar", "envio-contrato").then(() => {
		// 	this.router.navigate(['contratos/cadastro']);
		// }).catch((error) => {
		//   this.tratarExcecao(error);
		// });
	}

	tratarExcecao(error) {
		if (error.message != '') {
			this.toastService.error(error.message);
		}
		if (error.codigoErro == 511) {
			this.router.navigate(['/auth/login']);
		}
	}	

}

"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".dropdown-menu.dropdown-menu-lg[_ngcontent-%COMP%] {\n  width: 420px; }\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9Vc2Vycy90aGlhZ29vbGl2ZWlyYS9Eb2N1bWVudHMvQ3NvTmNrL1NvbC9jc29fc29sX2dpdC9yZW5hdmUvc3JjL2FwcC92aWV3cy9wYXJ0aWFscy9sYXlvdXQvdG9wYmFyL3NlYXJjaC1kZWZhdWx0L3NlYXJjaC1kZWZhdWx0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksWUFBWSxFQUFBIiwiZmlsZSI6InNyYy9hcHAvdmlld3MvcGFydGlhbHMvbGF5b3V0L3RvcGJhci9zZWFyY2gtZGVmYXVsdC9zZWFyY2gtZGVmYXVsdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5kcm9wZG93bi1tZW51LmRyb3Bkb3duLW1lbnUtbGcge1xuICAgIHdpZHRoOiA0MjBweDtcbn1cdCJdfQ== */"];
exports.styles = styles;

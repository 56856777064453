"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
// RxJS
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var ActionNotificationComponent = /** @class */ (function () {
    /**
     * Component constructor
     *
     * @param data: any
     */
    function ActionNotificationComponent(data) {
        this.data = data;
    }
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */
    /**
     * On init
     */
    ActionNotificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.data.showUndoButton || (this.data.undoButtonDuration >= this.data.duration)) {
            return;
        }
        this.delayForUndoButton(this.data.undoButtonDuration).subscribe(function () {
            _this.data.showUndoButton = false;
        });
    };
    /*
     *	Returns delay
     *
     * @param timeToDelay: any
     */
    ActionNotificationComponent.prototype.delayForUndoButton = function (timeToDelay) {
        return rxjs_1.of('').pipe(operators_1.delay(timeToDelay));
    };
    /**
     * Dismiss with Action
     */
    ActionNotificationComponent.prototype.onDismissWithAction = function () {
        this.data.snackBar.dismiss();
    };
    /**
     * Dismiss
     */
    ActionNotificationComponent.prototype.onDismiss = function () {
        this.data.snackBar.dismiss();
    };
    return ActionNotificationComponent;
}());
exports.ActionNotificationComponent = ActionNotificationComponent;

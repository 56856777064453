"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// RxJS
var rxjs_1 = require("rxjs");
var MenuConfigService = /** @class */ (function () {
    /**
     * Service Constructor
     */
    function MenuConfigService() {
        // register on config changed event and set default config
        this.onConfigUpdated$ = new rxjs_1.Subject();
    }
    /**
     * Returns the menuConfig
     */
    MenuConfigService.prototype.getMenus = function () {
        return this.menuConfig;
    };
    /**
     * Load config
     *
     * @param config: any
     */
    MenuConfigService.prototype.loadConfigs = function (config) {
        this.menuConfig = config;
        this.onConfigUpdated$.next(this.menuConfig);
    };
    return MenuConfigService;
}());
exports.MenuConfigService = MenuConfigService;

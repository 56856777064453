import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MensagensComponent } from './mensagens/mensagens.component';
import { TreeViewComponent } from './tree-view/tree-view.component';
import { NestableCustomComponent } from './nestable-custom/nestable-custom.component';
import { PaginadorComponent } from './paginador/paginador.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { GraficoBarrasComponent } from './grafico-barras/grafico-barras.component';
import { NgApexchartsModule } from "ng-apexcharts";

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    DataTablesModule,
    NgApexchartsModule
  ],
  declarations: [
    TreeViewComponent, 
    MensagensComponent, 
    NestableCustomComponent, 
    PaginadorComponent, GraficoBarrasComponent, 
    
  ],
  exports: [
    TreeViewComponent, 
    MensagensComponent, 
    NestableCustomComponent, 
    PaginadorComponent,
    GraficoBarrasComponent
  ],
})
export class ComponentesModule { }

"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./topbar.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../partials/layout/topbar/menu-ajuda/menu-ajuda.component.ngfactory");
var i3 = require("../../../../partials/layout/topbar/menu-ajuda/menu-ajuda.component");
var i4 = require("@ng-bootstrap/ng-bootstrap");
var i5 = require("../../../../../services/docs.service");
var i6 = require("../../../../partials/layout/topbar/user-profile2/user-profile2.component.ngfactory");
var i7 = require("../../../../partials/layout/topbar/user-profile2/user-profile2.component");
var i8 = require("../../../../../administracao/usuario/usuario.service");
var i9 = require("../../../../../+sol/contato/contato.service");
var i10 = require("@angular/router");
var i11 = require("./topbar.component");
var styles_TopbarComponent = [i0.styles];
var RenderType_TopbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopbarComponent, data: {} });
exports.RenderType_TopbarComponent = RenderType_TopbarComponent;
function View_TopbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "kt-header__topbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "kt-menu-ajuda", [], null, null, null, i2.View_MenuAjudaComponent_0, i2.RenderType_MenuAjudaComponent)), i1.ɵdid(2, 4308992, null, 0, i3.MenuAjudaComponent, [i4.NgbModal, i5.DocsService], { useSVG: [0, "useSVG"], bgImage: [1, "bgImage"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "kt-user-profile2", [], null, null, null, i6.View_UserProfile2Component_0, i6.RenderType_UserProfile2Component)), i1.ɵdid(4, 245760, null, 0, i7.UserProfile2Component, [i8.UsuarioService, i9.ContatoService, i10.Router, i4.NgbModal], null, null)], function (_ck, _v) { var currVal_0 = true; var currVal_1 = "./assets/media/misc/bg-1.jpg"; _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 4, 0); }, null); }
exports.View_TopbarComponent_0 = View_TopbarComponent_0;
function View_TopbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-topbar", [], null, null, null, View_TopbarComponent_0, RenderType_TopbarComponent)), i1.ɵdid(1, 49152, null, 0, i11.TopbarComponent, [], null, null)], null, null); }
exports.View_TopbarComponent_Host_0 = View_TopbarComponent_Host_0;
var TopbarComponentNgFactory = i1.ɵccf("kt-topbar", i11.TopbarComponent, View_TopbarComponent_Host_0, {}, {}, []);
exports.TopbarComponentNgFactory = TopbarComponentNgFactory;

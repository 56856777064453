"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PermissionEffects = /** @class */ (function () {
    /*@Effect()
    loadAllPermissions$ = this.actions$
        .pipe(
            ofType<AllPermissionsRequested>(PermissionActionTypes.AllPermissionsRequested),
            mergeMap(() => this.auth.getAllPermissions()),
            map((result: Permission[]) => {
                return  new AllPermissionsLoaded({
                    permissions: result
                });
            })
          );

    @Effect()
    init$: Observable<Action> = defer(() => {
        return of(new AllPermissionsRequested());
    });

    constructor(private actions$: Actions, private auth: AuthService) { }
    */
    function PermissionEffects() {
    }
    return PermissionEffects;
}());
exports.PermissionEffects = PermissionEffects;

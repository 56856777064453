<!-- begin:: Brand -->
<div class="kt-header__brand kt-grid__item" [ngClass]="htmlClassService.getClasses('brand', true)" id="kt_header_brand">
	<div class="kt-header__brand-logo">
		<a href="javascript:;" routerLink="/dashboard">
			<img alt="logo" [attr.src]="headerLogo" class="kt-header__brand-logo-default" />
			<img alt="logo" [attr.src]="headerStickyLogo" class="kt-header__brand-logo-sticky" />
		</a>
	</div>
	<div *ngIf="usuario.perfil?.nivelPerfil?.id == 9" class="kt-header__brand-nav">
		<div ngbDropdown class="dropdown">
			<button ngbDropdownToggle type="button" class="btn btn-default dropdown-toggle kt-font-success">
				Administração
			</button>
			<div ngbDropdownMenu class="dropdown-menu dropdown-menu-lg">
				<ul class="kt-nav kt-nav--bold kt-nav--md-space kt-nav--v3 kt-margin-t-20 kt-margin-b-20">
					<li *ngFor="let menu of administracao" class="kt-nav__item" routerLinkActive="active">
						<a  class="kt-nav__link"  href="javascript:;" routerLink="{{menu.page}}" title="{{menu.title}}">
							<span class="kt-nav__link-icon"><i [ngClass]="menu.icon ? menu.icon :'fa fa-genderless'"></i></span>
							<span class="kt-nav__link-text">{{menu.title}}</span>
						</a>

					</li>
				</ul>
			</div>
		</div>
	</div>
	<div *ngIf="usuario.perfil?.manutencao" class="kt-header__brand-nav">
		<div ngbDropdown class="dropdown">
			<button ngbDropdownToggle type="button" class="btn btn-default dropdown-toggle kt-font-success">
				Manutenção
			</button>
			<div ngbDropdownMenu class="dropdown-menu dropdown-menu-lg">
				<ul class="kt-nav kt-nav--bold kt-nav--md-space kt-nav--v3 kt-margin-t-20 kt-margin-b-20">
					<li class="kt-nav__item">
						<a class="kt-nav__link" routerLink="/menu" title="Menu" href="javascript:;">
							<span class="kt-nav__link-icon"><i class="flaticon2-menu"></i></span>
							<span class="kt-nav__link-text">Menu</span>
						</a>
					</li>
					<li class="kt-nav__item">
						<a class="kt-nav__link" routerLink="/tela" title="Tela" href="javascript:;">
							<span class="kt-nav__link-icon"><i class="flaticon2-website"></i></span>
							<span class="kt-nav__link-text">Tela</span>
						</a>
					</li>
					<li class="kt-nav__item">
						<a class="kt-nav__link" routerLink="/acao" title="Ação" href="javascript:;">
							<span class="kt-nav__link-icon"><i class="flaticon2-settings"></i></span>
							<span class="kt-nav__link-text">Ação</span>
						</a>
					</li>
					<li class="kt-nav__item">
						<a class="kt-nav__link" routerLink="/perfil" title="Perfil" href="javascript:;">
							<span class="kt-nav__link-icon"><i class="flaticon2-avatar"></i></span>
							<span class="kt-nav__link-text">Perfil</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
<!-- end:: Brand -->
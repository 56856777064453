import { Alerta } from "../models/alerta";
import { ToastrService } from "ngx-toastr";
import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
@Injectable()
export class ToastService {

    options: any = {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        closeButton: false,
        progressBar: false,
        messageClass: 'toast-message'

    }

    constructor(private toastr: ToastrService,
        private location: Location) {
    }

    success(mensagem: string, titulo?: string, extra?: any) {
        this.toastr.success(mensagem, titulo || "Sucesso", extra || this.options);
    }
    error(mensagem: string, titulo?: string, extra?: any) {
        this.toastr.error(mensagem, titulo || "Erro", extra || this.options);
    }
    info(mensagem: string, titulo?: string, extra?: any) {
        this.toastr.info(mensagem, titulo || "Informação", extra || this.options);
    }
    warning(mensagem: string, titulo?: string, extra?: any) {
        this.toastr.warning(mensagem, titulo || "Alerta", extra || this.options);
    }

    goBack(){
        this.location.back();
    }
}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
/**
 * Toggle
 */
var ToggleDirective = /** @class */ (function () {
    /**
     * Directive constructor
     * @param el: ElementRef
     */
    function ToggleDirective(el) {
        this.el = el;
    }
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */
    /**
     * After view init
     */
    ToggleDirective.prototype.ngAfterViewInit = function () {
        this.toggle = new KTToggle(this.el.nativeElement, this.options);
    };
    return ToggleDirective;
}());
exports.ToggleDirective = ToggleDirective;

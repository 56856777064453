"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
/**
 * Listen Tab click
 */
var TabClickEventDirective = /** @class */ (function () {
    /**
     * Directive Constructor
     * @param el: ElementRef
     * @param render: Renderer2
     */
    function TabClickEventDirective(el, render) {
        this.el = el;
        this.render = render;
    }
    /**
     * A directive handler the tab click event for active tab
     * @param target
     */
    TabClickEventDirective.prototype.onClick = function (target) {
        // remove previous active tab
        var parent = target.closest('[role="tablist"]');
        var activeLink = parent.querySelector('[role="tab"].active');
        if (activeLink) {
            this.render.removeClass(activeLink, 'active');
        }
        // set active tab
        var link = target.closest('[role="tab"]');
        if (link) {
            this.render.addClass(link, 'active');
        }
    };
    return TabClickEventDirective;
}());
exports.TabClickEventDirective = TabClickEventDirective;

"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./footer.component");
var i3 = require("../../../../core/_base/layout/services/layout-config.service");
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
exports.RenderType_FooterComponent = RenderType_FooterComponent;
function View_FooterComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "kt-footer kt-footer--extended kt-grid__item"], ["id", "kt_footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "kt-footer__bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "kt-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [["class", "kt-footer__wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 7, "div", [["class", "kt-footer__logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "a", [["href", "https://www.ncksa.com.br"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "img", [["alt", "Logo"], ["src", "./assets/media/logos/logo-2-sm.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "kt-footer__copyright"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", "\u00A0\u00A9\u00A0 "])), i0.ɵppd(10, 2), (_l()(), i0.ɵeld(11, 0, null, null, 1, "a", [["class", "kt-font-success"], ["href", "https://www.ncksa.com.br"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["NCK Todos os direitos reservados"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v, 0), _co.today, "yyyy")); _ck(_v, 9, 0, currVal_0); }); }
exports.View_FooterComponent_0 = View_FooterComponent_0;
function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 114688, null, 0, i2.FooterComponent, [i3.LayoutConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_FooterComponent_Host_0 = View_FooterComponent_Host_0;
var FooterComponentNgFactory = i0.ɵccf("kt-footer", i2.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
exports.FooterComponentNgFactory = FooterComponentNgFactory;

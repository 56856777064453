"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
// Layout
var layout_1 = require("../../../../../core/_base/layout");
var router_1 = require("@angular/router");
var usuario_service_1 = require("../../../../../administracao/usuario/usuario.service");
var toast_service_1 = require("../../../../../services/toast.service");
var Subheader2Component = /** @class */ (function () {
    /**
     * Component constructor
     *
     * @param subheaderService: SubheaderService
     */
    function Subheader2Component(router, subheaderService, usuarioService, toastService) {
        this.router = router;
        this.subheaderService = subheaderService;
        this.usuarioService = usuarioService;
        this.toastService = toastService;
        // Public properties
        this.today = Date.now();
        this.title = '';
        this.desc = '';
        this.breadcrumbs = [];
        this.ativo = true;
        // Private properties
        this.subscriptions = [];
    }
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */
    /**
     * On init
     */
    Subheader2Component.prototype.ngOnInit = function () {
        this.getUsuarioAutenticado();
    };
    Subheader2Component.prototype.getUsuarioAutenticado = function () {
        var _this = this;
        this.usuarioService.getUsuarioAutenticado().then(function (res) {
            _this.usuarioAutenticado = res;
        });
    };
    /**
     * After view init
     */
    Subheader2Component.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.subscriptions.push(this.subheaderService.title$.subscribe(function (bt) {
            // breadcrumbs title sometimes can be undefined
            if (bt) {
                Promise.resolve(null).then(function () {
                    _this.title = bt.title;
                    _this.desc = bt.desc;
                });
            }
        }));
        this.subscriptions.push(this.subheaderService.breadcrumbs$.subscribe(function (bc) {
            Promise.resolve(null).then(function () {
                _this.breadcrumbs = bc;
            });
        }));
    };
    /**
     * On destroy
     */
    Subheader2Component.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sb) { return sb.unsubscribe(); });
    };
    Subheader2Component.prototype.entrada = function () {
        if (this.usuarioAutenticado && this.usuarioAutenticado.perfil && this.usuarioAutenticado.perfil.id == 21) {
            this.router.navigate(['solicitacao/ite-entrada']);
        }
        else {
            this.router.navigate(['solicitacao/entrada']);
        }
        // this.usuarioService.verificarPermissao("enviar", "envio-contrato").then(() => {
        // 	this.router.navigate(['contratos/cadastro']);
        // }).catch((error) => {
        //   this.tratarExcecao(error);
        // });
    };
    Subheader2Component.prototype.saida = function () {
        if (this.usuarioAutenticado && this.usuarioAutenticado.perfil && this.usuarioAutenticado.perfil.id == 21) {
            this.router.navigate(['solicitacao/ite-saida']);
        }
        else {
            this.router.navigate(['solicitacao/saida']);
        }
        // this.usuarioService.verificarPermissao("enviar", "envio-contrato").then(() => {
        // 	this.router.navigate(['contratos/cadastro']);
        // }).catch((error) => {
        //   this.tratarExcecao(error);
        // });
    };
    Subheader2Component.prototype.tratarExcecao = function (error) {
        if (error.message != '') {
            this.toastService.error(error.message);
        }
        if (error.codigoErro == 511) {
            this.router.navigate(['/auth/login']);
        }
    };
    return Subheader2Component;
}());
exports.Subheader2Component = Subheader2Component;

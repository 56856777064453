"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]   .kt-subheader__desc[_ngcontent-%COMP%] {\n  margin: 0; }\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9Vc2Vycy90aGlhZ29vbGl2ZWlyYS9Eb2N1bWVudHMvQ3NvTmNrL1NvbC9jc29fc29sX2dpdC9yZW5hdmUvc3JjL2FwcC92aWV3cy9wYXJ0aWFscy9sYXlvdXQvc3ViaGVhZGVyL3N1YmhlYWRlcjEvc3ViaGVhZGVyMS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUVFLFNBQVMsRUFBQSIsImZpbGUiOiJzcmMvYXBwL3ZpZXdzL3BhcnRpYWxzL2xheW91dC9zdWJoZWFkZXIvc3ViaGVhZGVyMS9zdWJoZWFkZXIxLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuXHQua3Qtc3ViaGVhZGVyX19kZXNjIHtcblx0XHRtYXJnaW46IDA7XG5cdH1cbn1cbiJdfQ== */"];
exports.styles = styles;

import { Perfil } from "./perfil";
import { Contato } from "./contato";

export class Usuario {
    id: number;
    nome: string;
    contato: Contato;    
    conta: string;
    cpf: string;
    ativo: boolean;
    excluido: boolean;
    perfil: Perfil;
    token: string;
    primeiroAcesso: boolean;
    master: boolean;
    email: string;
    status: string;
    termoMaster: boolean;
    institucional: boolean;
    
    constructor() {
        this.perfil = new Perfil();
    }    

}

import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { AutorizacaoService } from "../../+autorizacao/autorizacao.service";

@Injectable()
export class AcessoRestritoGuard implements CanActivate {

    constructor(private authService: AutorizacaoService,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>  {
        if (this.authService.isAutenticado()) {
            return Promise.resolve(true);
        } else {
            this.router.navigate(['/auth/login']);
            return Promise.resolve(false);
        }
    }

}

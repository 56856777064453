
import { PesquisaGenerica } from '../../../util/pesquisa-generica';
import { PerguntasFrequentes } from '../../../models/perguntas-frequentes';
import { PerguntasFrequentesService } from '../../../administracao/perguntas-frequentes/perguntas-frequentes.service';
import { ToastService } from '../../../services/toast.service';
import { DatePipe } from '@angular/common';
import { OnInit, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'kt-modal-resposta-perguntas-frequentes',
  templateUrl: './modal-resposta-perguntas-frequentes.component.html'
})

export class ModalRespostaPerguntasFrequentesComponent implements OnInit {
  filtro: PerguntasFrequentes;
  listaPerguntasFrequentes: PerguntasFrequentes[];

  constructor(public activeModal: NgbActiveModal) {
  }
  @Input() pergunta: PerguntasFrequentes
  ngOnInit() {
  }
  pesquisar() { }

}

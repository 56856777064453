"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var loading_service_1 = require("../../../../services/loading.service");
var LoadingComponent = /** @class */ (function () {
    function LoadingComponent(loading) {
        var _this = this;
        this.loading = loading;
        this.active = false;
        this.loadingSubscription = this.loading.status.subscribe(function (status) {
            setTimeout(function () { return _this.active = status; });
        });
    }
    LoadingComponent.prototype.ngOnInit = function () { };
    return LoadingComponent;
}());
exports.LoadingComponent = LoadingComponent;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var generic_service_1 = require("../../services/generic.service");
var http_service_1 = require("../../services/http.service");
var _services_1 = require("../../core/auth/_services");
var i0 = require("@angular/core");
var i1 = require("../../services/http.service");
var i2 = require("../../+autorizacao/autorizacao.service");
var PerguntasFrequentesService = /** @class */ (function (_super) {
    __extends(PerguntasFrequentesService, _super);
    function PerguntasFrequentesService(httpService, auth) {
        return _super.call(this, httpService, auth) || this;
    }
    PerguntasFrequentesService.prototype.pesquisar = function (perguntasFrequentes, pagina) {
        var recurso = "perguntas-frequentes/pesquisar";
        var params = this.getPagina(pagina);
        return this.requisitarServico(recurso, perguntasFrequentes, params);
    };
    PerguntasFrequentesService.prototype.pesquisarPorId = function (perguntasFrequentes) {
        var recurso = "perguntas-frequentes/editar";
        return this.requisitarServico(recurso, perguntasFrequentes);
    };
    PerguntasFrequentesService.prototype.salvar = function (perguntasFrequentes) {
        var recurso = "perguntas-frequentes/salvar";
        return this.requisitarServico(recurso, perguntasFrequentes);
    };
    PerguntasFrequentesService.prototype.remover = function (perguntasFrequentes) {
        var recurso = "perguntas-frequentes/remover";
        return this.requisitarServico(recurso, perguntasFrequentes);
    };
    PerguntasFrequentesService.ngInjectableDef = i0.defineInjectable({ factory: function PerguntasFrequentesService_Factory() { return new PerguntasFrequentesService(i0.inject(i1.HttpService), i0.inject(i2.AutorizacaoService)); }, token: PerguntasFrequentesService, providedIn: "root" });
    return PerguntasFrequentesService;
}(generic_service_1.GenericService));
exports.PerguntasFrequentesService = PerguntasFrequentesService;

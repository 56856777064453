<div class="row">
    <div class="col-sm-4 hidden-xs">
        <div aria-live="polite" role="status" id="datatable_tabletools_info" class="dataTables_info">
             <span class="txt-color-darken">{{ nrRegistros != undefined || nrRegistros > 0 ? "Encontrados " + nrRegistros + " registros" : "Nenhum registro encontrado" }}</span> 
        </div>
    </div>

    <div class="col-sm-6 col-xs-12" *ngIf="paginasRegistros > 1 && nrRegistros != undefined">
        
    <div id="datatable_tabletools_paginate" class="col-sm-12 col-xs-12" >

        <ul class="pagination flex-wrap">

            <ul class="pagination">

                <li id="datatable_tabletools_previous" tabindex="0" aria-controls="datatable_tabletools" class="page-item" [ngClass]="{disabled: pagina == 1}">
                    <a class="page-link" (click)="pesquisarPagina(pagina-1)">Anterior</a>
                </li>
                <li *ngIf="(pagina-2) >= 2"  tabindex="0" aria-controls="datatable_tabletools" class="page-item">
                    <a class="page-link" (click)="pesquisarPagina(1)">1</a>
                </li>
                <li *ngIf="(pagina-2) >= 3"  tabindex="0" aria-controls="datatable_tabletools" class="page-item">
                    <a class="page-link" (click)="pesquisarPagina(2)">2</a>
                </li>
                <li *ngIf="(pagina-2) > 3"  tabindex="0" aria-controls="datatable_tabletools" class="page-item">
                    <span>...</span>
                </li>

            </ul>

            <ul class="pagination" *ngFor="let item of colecao" >
                <ul class="pagination" *ngIf="item > 0 && item <= paginasRegistros">
                    <li *ngIf="pagina == item"  tabindex="0" aria-controls="datatable_tabletools" class="page-item active">
                        <span>{{item}}</span>
                    </li>
                    <li *ngIf="pagina != item" tabindex="0" aria-controls="datatable_tabletools" class="page-item">
                        <a  class="page-link" (click)="pesquisarPagina(item)">{{item}}</a>
                    </li>
                </ul>
            </ul>
        
            <ul class="pagination" *ngIf="((pagina + 2) <= paginasRegistros)">
                <li *ngIf="pagina + 2  <= paginasRegistros - 2"  tabindex="0" aria-controls="datatable_tabletools" class="page-item">
                    <span>...</span>
                </li>
                <li *ngIf="pagina + 2 <= paginasRegistros - 1"  tabindex="0" aria-controls="datatable_tabletools" class="page-item">
                    <a class="page-link" (click)="pesquisarPagina(paginasRegistros-1)">{{paginasRegistros - 1}}</a>
                </li>
                <li tabindex="0" aria-controls="datatable_tabletools" class="page-item">
                    <a class="page-link" (click)="pesquisarPagina(paginasRegistros)">{{paginasRegistros}}</a>
                </li>
            </ul>

            <ul class="pagination" *ngIf="paginasRegistros > 1" >
                <li id="datatable_tabletools_next" tabindex="0" aria-controls="datatable_tabletools" class="page-item next" [ngClass]="{disabled: pagina == paginasRegistros}">
                    <a class="page-link" (click)="pesquisarPagina(pagina+1)">Próximo</a>  
                </li>
            </ul>
        
        </ul>
        
        </div>

    </div>

    <div *ngIf="paginasRegistros == 1"><br/><br/><br/></div>

</div>


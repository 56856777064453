import { Injectable } from '@angular/core';
import { AutorizacaoService } from '../core/auth/_services';
import { HttpService } from './http.service';
import { Parametro } from '../models/parametro';

@Injectable({ providedIn: 'root' })
export class GenericService {

    constructor(private httpService: HttpService, private auth: AutorizacaoService) {
    }

    requisitarExcel(recurso: string, entidade?: any, params?: string, extensao?: string) {
        let parametro: Parametro = new Parametro();        
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);

        this.httpService.downloadExcel(parametro, this.auth.getToken(), extensao);
    }

    requisitarPDF(recurso: string, entidade?: any, params?: string) {
        let parametro: Parametro = new Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);

        this.httpService
        .downloadPDF(parametro, this.auth.getToken());
    }

    requisitarZip(recurso: string, entidade?: any, params?: string) {
        let parametro: Parametro = new Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);
        this.httpService.downloadZip(parametro, this.auth.getToken());
    }

    requisitarXml(recurso: string, entidade?: any, params?: string) {
        let parametro: Parametro = new Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);
        this.httpService.downloadXml(parametro, this.auth.getToken());
    }
    
    showPDF(recurso: string, entidade?: any, params?: string): any {
        let parametro: Parametro = new Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);

        this.httpService.showPDF(parametro, this.auth.getToken());
    }

    requisitarServicoWithoutLoading(recurso: string, entidade?: any, params?: string): Promise<any> {
        let parametro: Parametro = new Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);

        return this.httpService.sendWithoutLoading(parametro, this.auth.getToken()).then((res) => {
            return res as any;
        }).catch((res) => Promise.reject(res));
    }

    requisitarServico(recurso: string, entidade?: any, params?: string): Promise<any> {
        let parametro: Parametro = new Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);

        return this.httpService.send(parametro, this.auth.getToken()).then((res) => {
            return res as any;
        }).catch((res) => Promise.reject(res));
    }

    requisitarCertificado(recurso: string, entidade?: any, params?: string) {
        let parametro: Parametro = new Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.params = this.getParams(params);
        this.httpService.downloadCertificado(parametro, this.auth.getToken());
    }    

    requisitarServicoConcessionaria(recurso: string, entidade: any, idConcessionaria: number, params?: string): Promise<any> {
        let parametro: Parametro = new Parametro();
        parametro.acao = recurso;
        parametro.entidade = entidade;
        parametro.idConcessionaria = idConcessionaria;
        parametro.params = this.getParams(params);

        return this.httpService.send(parametro, this.auth.getToken()).then((res) => {
            return res as any;
        }).catch((res) => Promise.reject(res));
    }

    getPagina(pagina?: number): string {
        return (pagina != null) && (pagina != undefined) ? "&pagina=" + pagina.toString() : "&pagina=1";
    }

    getLimite(limite?: number): string {
        return (limite != null) && (limite != undefined) ? "&limite=" + limite.toString() : "&limite=15";
    }


    getParams(params?: string): string {
        return (params != null) && (params != undefined) ? params : "";
    }

}


"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
var AlertComponent = /** @class */ (function () {
    function AlertComponent() {
        this.duration = 0;
        this.showCloseButton = true;
        this.close = new core_1.EventEmitter();
        this.alertShowing = true;
    }
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */
    /**
     * On init
     */
    AlertComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.duration === 0) {
            return;
        }
        setTimeout(function () {
            _this.closeAlert();
        }, this.duration);
    };
    /**
     * close alert
     */
    AlertComponent.prototype.closeAlert = function () {
        this.close.emit();
    };
    return AlertComponent;
}());
exports.AlertComponent = AlertComponent;

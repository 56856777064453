"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./loading.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./loading.component");
var i4 = require("../../../../services/loading.service");
var styles_LoadingComponent = [i0.styles];
var RenderType_LoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingComponent, data: {} });
exports.RenderType_LoadingComponent = RenderType_LoadingComponent;
function View_LoadingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "loader"]], null, null, null, null, null))], null, null); }
function View_LoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_LoadingComponent_0 = View_LoadingComponent_0;
function View_LoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-loading", [], null, null, null, View_LoadingComponent_0, RenderType_LoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoadingComponent, [i4.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_LoadingComponent_Host_0 = View_LoadingComponent_Host_0;
var LoadingComponentNgFactory = i1.ɵccf("kt-loading", i3.LoadingComponent, View_LoadingComponent_Host_0, {}, {}, []);
exports.LoadingComponentNgFactory = LoadingComponentNgFactory;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
var layout_1 = require("../../../../../core/_base/layout");
var menuItem_1 = require("../../../../../models/menuItem");
var perfil_1 = require("../../../../../models/perfil");
var tipoMenuItem_1 = require("../../../../../models/tipoMenuItem");
var usuario_service_1 = require("../../../../../administracao/usuario/usuario.service");
var SearchDefaultComponent = /** @class */ (function () {
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */
    function SearchDefaultComponent(cdr, menuHorizontalService, usuarioService) {
        this.cdr = cdr;
        this.menuHorizontalService = menuHorizontalService;
        this.usuarioService = usuarioService;
        // Public properties
        // Set icon class name
        this.icon = 'flaticon2-search-1';
        this.result = [];
    }
    /**
     * On init
     */
    SearchDefaultComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.usuarioService.getUsuarioAutenticado().then(function (res) {
            var filtro = new menuItem_1.MenuItem();
            filtro.perfil = new perfil_1.Perfil();
            filtro.perfil.id = res.perfil.id;
            filtro.tipoMenuItem = new tipoMenuItem_1.TipoMenuItem();
            filtro.tipoMenuItem.id = 2;
            _this.menuHorizontalService.pesquisar(filtro).then(function (res) {
                _this.result = res;
            });
        });
    };
    /**
     * Search
     * @param e: Event
     */
    SearchDefaultComponent.prototype.search = function (e) {
        var _this = this;
        this.data = null;
        if (e.target.value.length >= 1) {
            this.loading = true;
            setTimeout(function () {
                _this.data = _this.result.filter(function (it) {
                    return it.nome.toLowerCase().includes(e.target.value.toLowerCase());
                });
                if (_this.data.length == 0) {
                    var filtro = new menuItem_1.MenuItem();
                    filtro.nome = "Tela não encontrada";
                    filtro.icone = "fa fa-ban";
                    _this.data = [filtro];
                }
                _this.loading = false;
                _this.cdr.detectChanges();
            }, 500);
        }
    };
    /**
     * Clear search
     *
     * @param e: Event
     */
    SearchDefaultComponent.prototype.clear = function (e) {
        this.data = null;
        this.searchInput.nativeElement.value = '';
    };
    return SearchDefaultComponent;
}());
exports.SearchDefaultComponent = SearchDefaultComponent;

"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/router");
var i2 = require("@angular/common");
var i3 = require("@ng-bootstrap/ng-bootstrap");
var i4 = require("./brand.component");
var i5 = require("../../../../../core/_base/layout/services/layout-config.service");
var i6 = require("../../html-class.service");
var i7 = require("../../../../../administracao/usuario/usuario.service");
var i8 = require("../../../../../core/_base/layout/services/menu-horizontal.service");
var styles_BrandComponent = [];
var RenderType_BrandComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BrandComponent, data: {} });
exports.RenderType_BrandComponent = RenderType_BrandComponent;
function View_BrandComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "li", [["class", "kt-nav__item"], ["routerLinkActive", "active"]], null, null, null, null, null)), i0.ɵdid(1, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 5, { links: 1 }), i0.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i0.ɵeld(4, 0, null, null, 6, "a", [["class", "kt-nav__link"], ["href", "javascript:;"]], [[8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 671744, [[6, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [["class", "kt-nav__link-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "i", [], null, null, null, null, null)), i0.ɵdid(8, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [["class", "kt-nav__link-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "active"; _ck(_v, 1, 0, currVal_0); var currVal_4 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.page, ""); _ck(_v, 5, 0, currVal_4); var currVal_5 = (_v.context.$implicit.icon ? _v.context.$implicit.icon : "fa fa-genderless"); _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.title, ""); var currVal_2 = i0.ɵnov(_v, 5).target; var currVal_3 = i0.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_6 = _v.context.$implicit.title; _ck(_v, 10, 0, currVal_6); }); }
function View_BrandComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "kt-header__brand-nav"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "div", [["class", "dropdown"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i0.ɵdid(2, 737280, null, 3, i3.NgbDropdown, [i0.ChangeDetectorRef, i3.NgbDropdownConfig, i2.DOCUMENT, i0.NgZone, i0.ElementRef, i0.Renderer2, [2, i3.ɵm]], null, null), i0.ɵqud(335544320, 1, { _menu: 0 }), i0.ɵqud(335544320, 2, { _menuElement: 0 }), i0.ɵqud(335544320, 3, { _anchor: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "btn btn-default dropdown-toggle kt-font-success dropdown-toggle"], ["ngbDropdownToggle", ""], ["type", "button"]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i0.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i0.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i3.NgbDropdownToggle, [i3.NgbDropdown, i0.ElementRef], null, null), i0.ɵprd(2048, [[3, 4]], i3.NgbDropdownAnchor, null, [i3.NgbDropdownToggle]), (_l()(), i0.ɵted(-1, null, [" Administra\u00E7\u00E3o "])), (_l()(), i0.ɵeld(10, 0, [[2, 0]], null, 5, "div", [["class", "dropdown-menu dropdown-menu-lg"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 16384, [[1, 4]], 1, i3.NgbDropdownMenu, [i3.NgbDropdown], null, null), i0.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i0.ɵeld(13, 0, null, null, 2, "ul", [["class", "kt-nav kt-nav--bold kt-nav--md-space kt-nav--v3 kt-margin-t-20 kt-margin-b-20"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BrandComponent_2)), i0.ɵdid(15, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_5 = _co.administracao; _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).isOpen(); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 7).dropdown.isOpen(); _ck(_v, 6, 0, currVal_1); var currVal_2 = true; var currVal_3 = i0.ɵnov(_v, 11).dropdown.isOpen(); var currVal_4 = i0.ɵnov(_v, 11).placement; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4); }); }
function View_BrandComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 41, "div", [["class", "kt-header__brand-nav"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 40, "div", [["class", "dropdown"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i0.ɵdid(2, 737280, null, 3, i3.NgbDropdown, [i0.ChangeDetectorRef, i3.NgbDropdownConfig, i2.DOCUMENT, i0.NgZone, i0.ElementRef, i0.Renderer2, [2, i3.ɵm]], null, null), i0.ɵqud(335544320, 7, { _menu: 0 }), i0.ɵqud(335544320, 8, { _menuElement: 0 }), i0.ɵqud(335544320, 9, { _anchor: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "btn btn-default dropdown-toggle kt-font-success dropdown-toggle"], ["ngbDropdownToggle", ""], ["type", "button"]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i0.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i0.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i3.NgbDropdownToggle, [i3.NgbDropdown, i0.ElementRef], null, null), i0.ɵprd(2048, [[9, 4]], i3.NgbDropdownAnchor, null, [i3.NgbDropdownToggle]), (_l()(), i0.ɵted(-1, null, [" Manuten\u00E7\u00E3o "])), (_l()(), i0.ɵeld(10, 0, [[8, 0]], null, 31, "div", [["class", "dropdown-menu dropdown-menu-lg"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i0.ɵnov(_v, 11).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 16384, [[7, 4]], 1, i3.NgbDropdownMenu, [i3.NgbDropdown], null, null), i0.ɵqud(603979776, 10, { menuItems: 1 }), (_l()(), i0.ɵeld(13, 0, null, null, 28, "ul", [["class", "kt-nav kt-nav--bold kt-nav--md-space kt-nav--v3 kt-margin-t-20 kt-margin-b-20"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 6, "li", [["class", "kt-nav__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 5, "a", [["class", "kt-nav__link"], ["href", "javascript:;"], ["routerLink", "/menu"], ["title", "Menu"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(16, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(17, 0, null, null, 1, "span", [["class", "kt-nav__link-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 0, "i", [["class", "flaticon2-menu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "span", [["class", "kt-nav__link-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Menu"])), (_l()(), i0.ɵeld(21, 0, null, null, 6, "li", [["class", "kt-nav__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 5, "a", [["class", "kt-nav__link"], ["href", "javascript:;"], ["routerLink", "/tela"], ["title", "Tela"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 23).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(23, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(24, 0, null, null, 1, "span", [["class", "kt-nav__link-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 0, "i", [["class", "flaticon2-website"]], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 1, "span", [["class", "kt-nav__link-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Tela"])), (_l()(), i0.ɵeld(28, 0, null, null, 6, "li", [["class", "kt-nav__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(29, 0, null, null, 5, "a", [["class", "kt-nav__link"], ["href", "javascript:;"], ["routerLink", "/acao"], ["title", "A\u00E7\u00E3o"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 30).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(30, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(31, 0, null, null, 1, "span", [["class", "kt-nav__link-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(32, 0, null, null, 0, "i", [["class", "flaticon2-settings"]], null, null, null, null, null)), (_l()(), i0.ɵeld(33, 0, null, null, 1, "span", [["class", "kt-nav__link-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["A\u00E7\u00E3o"])), (_l()(), i0.ɵeld(35, 0, null, null, 6, "li", [["class", "kt-nav__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(36, 0, null, null, 5, "a", [["class", "kt-nav__link"], ["href", "javascript:;"], ["routerLink", "/perfil"], ["title", "Perfil"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 37).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(37, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(38, 0, null, null, 1, "span", [["class", "kt-nav__link-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(39, 0, null, null, 0, "i", [["class", "flaticon2-avatar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(40, 0, null, null, 1, "span", [["class", "kt-nav__link-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Perfil"]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_7 = "/menu"; _ck(_v, 16, 0, currVal_7); var currVal_10 = "/tela"; _ck(_v, 23, 0, currVal_10); var currVal_13 = "/acao"; _ck(_v, 30, 0, currVal_13); var currVal_16 = "/perfil"; _ck(_v, 37, 0, currVal_16); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).isOpen(); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 7).dropdown.isOpen(); _ck(_v, 6, 0, currVal_1); var currVal_2 = true; var currVal_3 = i0.ɵnov(_v, 11).dropdown.isOpen(); var currVal_4 = i0.ɵnov(_v, 11).placement; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = i0.ɵnov(_v, 16).target; var currVal_6 = i0.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_5, currVal_6); var currVal_8 = i0.ɵnov(_v, 23).target; var currVal_9 = i0.ɵnov(_v, 23).href; _ck(_v, 22, 0, currVal_8, currVal_9); var currVal_11 = i0.ɵnov(_v, 30).target; var currVal_12 = i0.ɵnov(_v, 30).href; _ck(_v, 29, 0, currVal_11, currVal_12); var currVal_14 = i0.ɵnov(_v, 37).target; var currVal_15 = i0.ɵnov(_v, 37).href; _ck(_v, 36, 0, currVal_14, currVal_15); }); }
function View_BrandComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "kt-header__brand kt-grid__item"], ["id", "kt_header_brand"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "kt-header__brand-logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "a", [["href", "javascript:;"], ["routerLink", "/dashboard"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "img", [["alt", "logo"], ["class", "kt-header__brand-logo-default"]], [[1, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "img", [["alt", "logo"], ["class", "kt-header__brand-logo-sticky"]], [[1, "src", 4]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BrandComponent_1)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BrandComponent_3)), i0.ɵdid(10, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "kt-header__brand kt-grid__item"; var currVal_1 = _co.htmlClassService.getClasses("brand", true); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = "/dashboard"; _ck(_v, 4, 0, currVal_4); var currVal_7 = (((_co.usuario.perfil == null) ? null : ((_co.usuario.perfil.nivelPerfil == null) ? null : _co.usuario.perfil.nivelPerfil.id)) == 9); _ck(_v, 8, 0, currVal_7); var currVal_8 = ((_co.usuario.perfil == null) ? null : _co.usuario.perfil.manutencao); _ck(_v, 10, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵnov(_v, 4).target; var currVal_3 = i0.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_5 = _co.headerLogo; _ck(_v, 5, 0, currVal_5); var currVal_6 = _co.headerStickyLogo; _ck(_v, 6, 0, currVal_6); }); }
exports.View_BrandComponent_0 = View_BrandComponent_0;
function View_BrandComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-brand", [], null, null, null, View_BrandComponent_0, RenderType_BrandComponent)), i0.ɵdid(1, 4308992, null, 0, i4.BrandComponent, [i5.LayoutConfigService, i6.HtmlClassService, i7.UsuarioService, i8.MenuHorizontalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_BrandComponent_Host_0 = View_BrandComponent_Host_0;
var BrandComponentNgFactory = i0.ɵccf("kt-brand", i4.BrandComponent, View_BrandComponent_Host_0, {}, {}, []);
exports.BrandComponentNgFactory = BrandComponentNgFactory;

// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './views/pages/auth/auth.component';
import { BaseComponent } from './views/themes/demo2/base/base.component';
import { AcessoRestritoGuard } from './services/guard/acesso-restrito.guard';

const routes: Routes = [

	{
		path: '', component: BaseComponent,
		children: [
			{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
			{ path: 'dashboard', canActivate: [AcessoRestritoGuard], loadChildren: 'app/+dashboard/dashboard.module#DashboardModule' },
			{ path: 'perfil', canActivate: [AcessoRestritoGuard], loadChildren: 'app/manutencao/perfil/perfil.module#PerfilModule' },
			{ path: 'acao', canActivate: [AcessoRestritoGuard], loadChildren: 'app/manutencao/acao/acao.module#AcaoModule' },
			{ path: 'tela', canActivate: [AcessoRestritoGuard], loadChildren: 'app/manutencao/tela/tela.module#TelaModule' },
			{ path: 'menu', canActivate: [AcessoRestritoGuard], loadChildren: 'app/manutencao/menu/menu.module#MenuModule' },
			{ path: 'usuario', canActivate: [AcessoRestritoGuard], loadChildren: 'app/administracao/usuario/usuario.module#UsuarioModule' },
			{ path: 'aviso', canActivate: [AcessoRestritoGuard], loadChildren: 'app/administracao/aviso/aviso.module#AvisoModule' },
			{ path: 'perguntas-frequentes', canActivate: [AcessoRestritoGuard], loadChildren: 'app/administracao/perguntas-frequentes/perguntas-frequentes.module#PerguntasFrequentesModule' },
			{ path: 'contato', canActivate: [AcessoRestritoGuard], loadChildren: 'app/+sol/contato/contato.module#ContatoModule' },
			{ path: 'concessionaria', canActivate: [AcessoRestritoGuard], loadChildren: 'app/+sol/concessionaria/concessionaria.module#ConcessionariaModule' },
			{ path: 'grupo-concessionaria', canActivate: [AcessoRestritoGuard], loadChildren: 'app/administracao/grupo-concessionaria/grupo-concessionaria.module#GrupoConcessionariaModule' },
			{ path: 'solicitacao', canActivate: [AcessoRestritoGuard], loadChildren: 'app/+sol/solicitacao/solicitacao.module#SolicitacaoModule' },
			{ path: 'estoque', canActivate: [AcessoRestritoGuard], loadChildren: 'app/+sol/estoque/estoque.module#EstoqueModule' },
			{ path: 'consulta', canActivate: [AcessoRestritoGuard], loadChildren: 'app/+sol/consulta/consulta.module#ConsultaModule' },
			{ path: 'impressao-crlve', canActivate: [AcessoRestritoGuard], loadChildren: 'app/+sol/impressao-crlve/impressao-crlve.module#ImpressaoCrlveModule' },
			{ path: 'impressao-atpve', canActivate: [AcessoRestritoGuard], loadChildren: 'app/+sol/impressao-atpv-e/impressao-atpve.module#ImpressaoAtpveModule' },
			{ path: 'atpv-e', canActivate: [AcessoRestritoGuard], loadChildren: 'app/+sol/atpv-e/atpv-e.module#AtpvEModule' },
			{ path: 'importacao', canActivate: [AcessoRestritoGuard], loadChildren: 'app/+sol/importacao/importacao.module#ImportacaoModule' },
		]
	},
	{ path: 'auth', component: AuthComponent, loadChildren: 'app/+autorizacao/autorizacao.module#AutorizacaoModule' },
	{ path: '**', redirectTo: 'dashboard' },


	//{path: 'auth', loadChildren: 'app/views/pages/auth/auth.module#AuthModule'},

	// enable this router to set which default theme to load,
	// leave the path value empty to enter into nested router in ThemeModule
	// {path: '', loadChildren: 'app/views/themes/default/theme.module#ThemeModule'},

	/** START: remove this themes list on production */

	// list of routers specified by demos, for demo purpose only!
	//{path: 'default',  loadChildren: 'app/views/themes/default/theme.module#ThemeModule'},
	//{path: '', redirectTo: 'dashboard', pathMatch: 'full'},

	//{path: 'dashboard', component: BaseComponent, loadChildren: 'app/dashboard/dashboard.module#DashboardModule'},
	/** END: themes list end */


	// {path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TratarFalhaRetornoFiles = /** @class */ (function () {
    function TratarFalhaRetornoFiles() {
    }
    TratarFalhaRetornoFiles.prototype.tratarRetorno = function (toastService, codigo) {
        if (401 == codigo) {
            toastService.error("Usuário sem permissão");
        }
        else if (404 == codigo) {
            toastService.error("Arquivo não encontrado");
        }
        else if (10141 == codigo) {
            toastService.error("Falha ao obter conexão com o banco de dados do ERP. Tente novamente mais tarde.");
        }
        else if (412 == codigo) {
            toastService.error("Boleto indisponível, tente novamente em 5 minutos!");
        }
        else if (10143 == codigo) {
            toastService.error("Boleto disponível após aprovação do faturamento.");
        }
        else {
            toastService.error("Erro ao realizar o download do arquivo");
        }
    };
    return TratarFalhaRetornoFiles;
}());
exports.TratarFalhaRetornoFiles = TratarFalhaRetornoFiles;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
// RxJS
var rxjs_1 = require("rxjs");
// Object-Path
var objectPath = require("object-path");
// Lodash
var lodash_1 = require("lodash");
var PageConfigService = /** @class */ (function () {
    /**
     * Service Constructor
     *
     * @param router: Router
     */
    function PageConfigService(router) {
        this.router = router;
        // register on config changed event and set default config
        this.onConfigUpdated$ = new rxjs_1.Subject();
    }
    /**
     * Get current page config based on route
     */
    PageConfigService.prototype.getCurrentPageConfig = function (path) {
        var url = this.router.url;
        // remove first route (demo name) from url router
        if (new RegExp(/^\/de/).test(url)) {
            var urls = url.split('/');
            urls.splice(0, 2);
            url = urls.join('/');
        }
        var configPath = url.replace(/\//g, '.');
        if (path) {
            configPath += '.' + path;
        }
        // get page config by path
        return objectPath.get(this.pageConfig, configPath);
    };
    /**
     * Set existing config with a new value
     * @param value: any
     * @param sav: boolean?
     */
    PageConfigService.prototype.setConfig = function (value, save) {
        this.pageConfig = lodash_1.merge(this.pageConfig, value);
        if (save) {
            // not implemented
        }
        // fire off an event that all subscribers will listen
        this.onConfigUpdated$.next(this.pageConfig);
    };
    /**
     * Load confgis
     *
     * @param config: any
     */
    PageConfigService.prototype.loadConfigs = function (config) {
        this.pageConfig = config;
        this.onConfigUpdated$.next(this.pageConfig);
    };
    return PageConfigService;
}());
exports.PageConfigService = PageConfigService;

<!--begin: My Cart -->
<div ngbDropdown placement="bottom-right" autoClose="outside" class="kt-header__topbar-item">
	<div ngbDropdownToggle class="kt-header__topbar-wrapper">
		<span class="kt-header__topbar-icon">
			<i *ngIf="!useSVG" [ngClass]="icon"></i>
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
				height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon kt-svg-icon--success">
				<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<rect id="bound" x="0" y="0" width="24" height="24" />
					<circle id="Oval-5" fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
					<path
						d="M12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 Z M10.591,14.868 L10.591,13.209 L11.851,13.209 C13.447,13.209 14.602,11.991 14.602,10.395 C14.602,8.799 13.447,7.581 11.851,7.581 C10.234,7.581 9.121,8.799 9.121,10.395 L7.336,10.395 C7.336,7.875 9.31,5.922 11.851,5.922 C14.392,5.922 16.387,7.875 16.387,10.395 C16.387,12.915 14.392,14.868 11.851,14.868 L10.591,14.868 Z"
						id="Combined-Shape" fill="#000000" />
				</g>
			</svg>
		</span>
	</div>
	<div ngbDropdownMenu
		class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
		<!--begin: Head -->

		<div class="kt-user-card kt-user-card--skin-light kt-notification-item-padding-x"
			[ngStyle]="{'background-image': 'url(./assets/media/misc/bg-4.jpg)'}">
		
			<div class="kt-user-card__name">
				<span class="font-nome-usuario">Ajuda</span>
			</div>
		</div>
		<!--end: Head -->

		<!--begin: Navigation -->
		<div class="kt-notification">
			<a href="assets/pdf/contrato_prestacao_servico.pdf" target="_blank" class="kt-notification__item">
				<div class="kt-notification__item-icon">
					<i class="flaticon-info kt-font-success"></i>
				</div>
				<div class="kt-notification__item-details">
					<div class="kt-notification__item-title kt-font-bold">
						Contrato de adesão
					</div>
					<div class="kt-notification__item-time">
					</div>
				</div>
			</a>
			<a (click)="exibirManualTecnico()" href="javascript:;" class="kt-notification__item">
				<div class="kt-notification__item-icon">
					<i class="flaticon-menu-1 kt-font-warning"></i>
				</div>
				<div class="kt-notification__item-details">
					<div class="kt-notification__item-title kt-font-bold">
						Manual Técnico
					</div>
					<div class="kt-notification__item-time">
					</div>
				</div>
			</a>

			<a (click)="mostrarManualSite()" href="javascript:;" class="kt-notification__item">
				<div class="kt-notification__item-icon">
					<i class="flaticon-exclamation-1 kt-font-brand"></i>
				</div>
				<div class="kt-notification__item-details">
					<div class="kt-notification__item-title kt-font-bold">
						Manual Site
					</div>
					<div class="kt-notification__item-time">
					</div>
				</div>
			</a>
			<a (click)="mostrarPerguntasFrequentes()" href="javascript:;" class="kt-notification__item">
				<div class="kt-notification__item-icon">
					<i class="flaticon-info kt-font-brand"></i>
				</div>
				<div class="kt-notification__item-details">
					<div class="kt-notification__item-title kt-font-bold">
						Perguntas Frequentes
					</div>
					<div class="kt-notification__item-time">
					</div>
				</div>
			</a>
		</div>
	</div>
	<!--end: My Cart -->
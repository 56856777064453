import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../../services/toast.service';
import { DatePipe } from '@angular/common';
import { PesquisaGenerica } from '../../../util/pesquisa-generica';
import { PerguntasFrequentesService } from '../../../administracao/perguntas-frequentes/perguntas-frequentes.service';
import { PerguntasFrequentes } from '../../../models/perguntas-frequentes';
import { ModalRespostaPerguntasFrequentesComponent } from '../modal-resposta-perguntas-frequentes/modal-resposta-perguntas-frequentes.component';

@Component({
  selector: 'kt-modal-pesquisa-perguntas-frequentes',
  templateUrl: './modal-pesquisa-perguntas-frequentes.component.html'
})
export class ModalPesquisaPerguntasFrequentesComponent extends PesquisaGenerica implements OnInit {
  filtro: PerguntasFrequentes;
  listaPerguntasFrequentes: PerguntasFrequentes[];
  qtdPerguntasFrequentes: number;
  constructor(public activeModal: NgbActiveModal,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public toastService: ToastService,
    private perguntasFrequentesService: PerguntasFrequentesService,
    public date: DatePipe,
    private modalService: NgbModal) {
    super(router, activatedRoute, toastService);
    this.filtro = new PerguntasFrequentes();
  }


  ngOnInit() {
    this.pesquisar();
  }
  pesquisar(pagina?: number) {
    this.perguntasFrequentesService.pesquisar(this.filtro, pagina).then((res) => {
      this.listaPerguntasFrequentes = res.objeto as PerguntasFrequentes[];
      this.qtdPerguntasFrequentes = res.count;
    }).catch((error) => {
      this.tratarExcecao(error);
    });
  }

  mostrarResposta(obj){
    const modalRef = this.modalService.open(ModalRespostaPerguntasFrequentesComponent, { size: 'lg', windowClass: 'modal-padrao' });
    modalRef.componentInstance.pergunta  = obj
  }
}

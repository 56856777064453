"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
// ObjectPath
var objectPath = require("object-path");
/**
 * Configure Header
 */
var HeaderDirective = /** @class */ (function () {
    /**
     * Directive Constructor
     * @param el: ElementRef
     */
    function HeaderDirective(el) {
        this.el = el;
        this.options = {};
    }
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */
    /**
     * After view init
     */
    HeaderDirective.prototype.ngAfterViewInit = function () {
        this.setupOptions();
        var header = new KTHeader(this.el.nativeElement, this.options);
    };
    /**
     * Setup options to header
     */
    HeaderDirective.prototype.setupOptions = function () {
        if (this.el.nativeElement.getAttribute('data-ktheader-minimize') === '1') {
            objectPath.set(this.options, 'minimize.desktop.on', 'kt-header--minimize');
            objectPath.set(this.options, 'offset.desktop', 150);
        }
    };
    return HeaderDirective;
}());
exports.HeaderDirective = HeaderDirective;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ErrorComponent = /** @class */ (function () {
    function ErrorComponent() {
        // Public properties
        // type of error template to be used, accepted values; error-v1 | error-v2 | error-v3 | error-v4 | error-v5 | error-v6
        this.type = 'error-v1';
        // error code, some error types template has it
        this.code = '404';
        // error descriptions
        this.desc = 'Oops! Something went wrong!';
        // return back button title
        this.return = 'Return back';
        this.classes = 'kt-grid kt-grid--ver kt-grid--root';
    }
    return ErrorComponent;
}());
exports.ErrorComponent = ErrorComponent;

<!-- begin:: Footer -->
<div class="kt-footer kt-footer--extended kt-grid__item" id="kt_footer">

	<div class="kt-footer__bottom">
		<div class="kt-container">
			<div class="kt-footer__wrapper">
				<div class="kt-footer__logo">
					<a href="https://www.ncksa.com.br">
						<img alt="Logo" src="./assets/media/logos/logo-2-sm.png">
					</a>
					<div class="kt-footer__copyright">
						{{today | date:'yyyy'}}&nbsp;©&nbsp;
						<a href="https://www.ncksa.com.br" target="_blank" class="kt-font-success">NCK Todos os direitos
							reservados</a>
					</div>
				</div>
				<!-- <div class="kt-footer__menu">
					<a href="http://keenthemes.com/metronic" target="_blank">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
							height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon kt-svg-icon--success">
							<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
								<rect id="bound" x="0" y="0" width="24" height="24" />
								<circle id="Oval-5" fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
								<path
									d="M12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 Z M10.591,14.868 L10.591,13.209 L11.851,13.209 C13.447,13.209 14.602,11.991 14.602,10.395 C14.602,8.799 13.447,7.581 11.851,7.581 C10.234,7.581 9.121,8.799 9.121,10.395 L7.336,10.395 C7.336,7.875 9.31,5.922 11.851,5.922 C14.392,5.922 16.387,7.875 16.387,10.395 C16.387,12.915 14.392,14.868 11.851,14.868 L10.591,14.868 Z"
									id="Combined-Shape" fill="#000000" />
							</g>
						</svg>
					</a>
					<a href="http://keenthemes.com/metronic" target="_blank">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
							height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon kt-svg-icon--success">
							<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
								<rect id="bound" x="0" y="0" width="24" height="24" />
								<path
									d="M19,16 L19,12 C19,8.13400675 15.8659932,5 12,5 C8.13400675,5 5,8.13400675 5,12 L5,16 L19,16 Z M21,16 L3,16 L3,12 C3,7.02943725 7.02943725,3 12,3 C16.9705627,3 21,7.02943725 21,12 L21,16 Z"
									id="Combined-Shape" fill="#000000" fill-rule="nonzero" opacity="0.3" />
								<path
									d="M5,14 L6,14 C7.1045695,14 8,14.8954305 8,16 L8,19 C8,20.1045695 7.1045695,21 6,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,16 C3,14.8954305 3.8954305,14 5,14 Z M18,14 L19,14 C20.1045695,14 21,14.8954305 21,16 L21,19 C21,20.1045695 20.1045695,21 19,21 L18,21 C16.8954305,21 16,20.1045695 16,19 L16,16 C16,14.8954305 16.8954305,14 18,14 Z"
									id="Combined-Shape" fill="#000000" />
							</g>
						</svg>
					</a>
				</div> -->
			</div>
		</div>
	</div>
</div>
<!-- end:: Footer -->
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var DeleteEntityDialogComponent = /** @class */ (function () {
    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
     * @param data: any
     */
    function DeleteEntityDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        // Public properties
        this.viewLoading = false;
    }
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */
    /**
     * On init
     */
    DeleteEntityDialogComponent.prototype.ngOnInit = function () {
    };
    /**
     * Close dialog with false result
     */
    DeleteEntityDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    /**
     * Close dialog with true result
     */
    DeleteEntityDialogComponent.prototype.onYesClick = function () {
        var _this = this;
        /* Server loading imitation. Remove this */
        this.viewLoading = true;
        setTimeout(function () {
            _this.dialogRef.close(true); // Keep only this row
        }, 2500);
    };
    return DeleteEntityDialogComponent;
}());
exports.DeleteEntityDialogComponent = DeleteEntityDialogComponent;

import { Injectable } from '@angular/core';
import { GenericService } from '../../services/generic.service';
import { HttpService } from '../../services/http.service';
import { AutorizacaoService } from '../../core/auth/_services';
import { Retorno } from '../../models/retorno';
import { PerguntasFrequentes } from '../../models/perguntas-frequentes';

@Injectable({
  providedIn: 'root'
})
export class PerguntasFrequentesService extends GenericService {

  constructor(httpService: HttpService, auth: AutorizacaoService) {
    super(httpService, auth);
  }
  pesquisar(perguntasFrequentes: PerguntasFrequentes, pagina?: number): Promise<Retorno> {
    let recurso = "perguntas-frequentes/pesquisar";
    let params = this.getPagina(pagina);
    return this.requisitarServico(recurso, perguntasFrequentes, params);
  }

  pesquisarPorId(perguntasFrequentes: PerguntasFrequentes): Promise<PerguntasFrequentes> {
    let recurso = "perguntas-frequentes/editar";
    return this.requisitarServico(recurso, perguntasFrequentes);
  }

  salvar(perguntasFrequentes: PerguntasFrequentes): Promise<PerguntasFrequentes> {
    let recurso = "perguntas-frequentes/salvar";
    return this.requisitarServico(recurso, perguntasFrequentes);
  }
  remover(perguntasFrequentes: PerguntasFrequentes): Promise<PerguntasFrequentes> {
    let recurso = "perguntas-frequentes/remover";
    return this.requisitarServico(recurso, perguntasFrequentes);
  }
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular
var core_1 = require("@angular/core");
/**
 * Toggle
 */
var WizardDirective = /** @class */ (function () {
    /**
     * Directive constructor
     * @param el: ElementRef
     */
    function WizardDirective(el) {
        this.el = el;
    }
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */
    /**
     * After view init
     */
    WizardDirective.prototype.ngAfterViewInit = function () {
        this.wizard = new KTWizard(this.el.nativeElement, {
            startStep: 1,
            manualStepForward: false
        });
    };
    WizardDirective.prototype.goLast = function () {
        this.wizard.goLast();
    };
    WizardDirective.prototype.getStep = function () {
        this.wizard.getStep();
    };
    WizardDirective.prototype.goFirst = function () {
        this.wizard.goFirst();
    };
    WizardDirective.prototype.goNext = function () {
        this.wizard.goNext();
    };
    WizardDirective.prototype.goTo = function (t) {
        this.wizard.goTo(t);
    };
    WizardDirective.prototype.currentStep = function () {
        return this.wizard.currentStep;
    };
    WizardDirective.prototype.isFirstStep = function () {
        return this.wizard.isFirstStep();
    };
    WizardDirective.prototype.isLastStep = function () {
        return this.wizard.isLastStep();
    };
    WizardDirective.prototype.on = function () {
        this.wizard.on();
    };
    WizardDirective.prototype.stop = function () {
        this.wizard.stop();
    };
    WizardDirective.prototype.start = function () {
        this.wizard.start();
    };
    WizardDirective.prototype.totalSteps = function () {
        return this.wizard.totalSteps();
    };
    WizardDirective.prototype.one = function () {
        this.wizard.one();
    };
    return WizardDirective;
}());
exports.WizardDirective = WizardDirective;

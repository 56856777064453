"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var parametro_1 = require("../models/parametro");
var http_service_1 = require("../services/http.service");
var i0 = require("@angular/core");
var i1 = require("../services/http.service");
var AutorizacaoService = /** @class */ (function () {
    function AutorizacaoService(httpService) {
        this.httpService = httpService;
        this.tokenKey = 'app_token';
        this.isLoggedIn = false;
    }
    AutorizacaoService.prototype.efetuarLogin = function (formLogin) {
        var parametro = new parametro_1.Parametro();
        parametro.acao = "auth";
        parametro.entidade = formLogin;
        parametro.params = "";
        return this.httpService.send(parametro)
            .then(function (res) { return res; })
            .catch(function (res) { return Promise.reject(res); });
    };
    AutorizacaoService.prototype.enviarNovaSenha = function (usuario) {
        var parametro = new parametro_1.Parametro();
        parametro.acao = "auth/enviarNovaSenhaRenave";
        parametro.entidade = usuario;
        parametro.params = "";
        return this.httpService.send(parametro)
            .then(function (res) {
            return res;
        })
            .catch(function (res) { return Promise.reject(res); });
    };
    AutorizacaoService.prototype.enviarLinkRedefinirSenha = function (usuario) {
        var parametro = new parametro_1.Parametro();
        parametro.acao = "auth/enviarLinkRedefinirSenha";
        parametro.entidade = usuario;
        parametro.params = "";
        return this.httpService.send(parametro).then(function (res) {
            return res;
        }).catch(function (res) { return Promise.reject(res); });
    };
    AutorizacaoService.prototype.redefinirSenha = function (senha, token) {
        // let parametro: Parametro = new Parametro();
        // parametro.acao = "auth/redefinirSenha";
        // parametro.entidade = senha;
        // parametro.params = "";
        // return this.httpService.sendRedefinirSenha(parametro, token).then((res) => {
        //   this.armazenarTokenEncryptedStorage(res.token)
        //   return res as Usuario;
        // }).catch((res) => Promise.reject(res));
    };
    // armazenarTokenEncryptedStorage(token) {
    //   if (typeof (Storage) !== 'undefined') {
    //     var encrypted = this.encrDecrService.set('123456$#@$^@1ERF', token);
    //     sessionStorage.setItem(this.tokenKey, JSON.stringify(encrypted));
    //   }
    // }
    AutorizacaoService.prototype.isAutenticado = function () {
        if (typeof (Storage) !== 'undefined') {
            if (sessionStorage.getItem(this.tokenKey) === null
                || sessionStorage.getItem(this.tokenKey) === undefined
                || sessionStorage.getItem(this.tokenKey) === '') {
                return false;
            }
        }
        return true;
    };
    AutorizacaoService.prototype.download = function (referencia) {
        var parametro = new parametro_1.Parametro();
        parametro.acao = "auth/download";
        parametro.entidade = referencia;
        return this.httpService.send(parametro).then(function (res) {
            return res;
        }).catch(function (res) { return Promise.reject(res); });
    };
    AutorizacaoService.prototype.getToken = function () {
        return JSON.parse(sessionStorage.getItem(this.tokenKey));
    };
    AutorizacaoService.prototype.logout = function () {
        this.isLoggedIn = false;
    };
    AutorizacaoService.ngInjectableDef = i0.defineInjectable({ factory: function AutorizacaoService_Factory() { return new AutorizacaoService(i0.inject(i1.HttpService)); }, token: AutorizacaoService, providedIn: "root" });
    return AutorizacaoService;
}());
exports.AutorizacaoService = AutorizacaoService;

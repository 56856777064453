"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var perfil_1 = require("./perfil");
var Usuario = /** @class */ (function () {
    function Usuario() {
        this.perfil = new perfil_1.Perfil();
    }
    return Usuario;
}());
exports.Usuario = Usuario;

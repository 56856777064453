"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CustomersTable = /** @class */ (function () {
    function CustomersTable() {
    }
    CustomersTable.customers = [
        {
            id: 1,
            firstName: 'Sonni',
            lastName: 'Gabotti',
            email: 'sgabotti0@wsj.com',
            userName: 'sgabotti0',
            gender: 'Female',
            status: 0,
            dateOfBbirth: '10/14/1950',
            ipAddress: '251.237.126.210',
            type: 1,
            _userId: 1,
            _createdDate: '09/07/2016',
            _updatedDate: '05/31/2013'
        },
        {
            id: 2,
            firstName: 'Abie',
            lastName: 'Cowperthwaite',
            email: 'acowperthwaite1@storify.com',
            userName: 'acowperthwaite1',
            gender: 'Male',
            status: 1,
            dateOfBbirth: '12/31/1998',
            ipAddress: '239.176.5.218',
            type: 1,
            _userId: 2,
            _createdDate: '03/18/2014',
            _updatedDate: '08/17/2016'
        },
        {
            id: 3,
            firstName: 'Melody',
            lastName: 'Stodd',
            email: 'mstodd2@twitpic.com',
            userName: 'mstodd2',
            gender: 'Female',
            status: 0,
            dateOfBbirth: '7/3/1957',
            ipAddress: '14.80.25.15',
            type: 1,
            _userId: 1,
            _createdDate: '07/03/2015',
            _updatedDate: '01/28/2015'
        },
        {
            id: 4,
            firstName: 'Naomi',
            lastName: 'Galbreth',
            email: 'ngalbreth3@springer.com',
            userName: 'ngalbreth3',
            gender: 'Female',
            status: 2,
            dateOfBbirth: '12/30/1976',
            ipAddress: '239.198.18.122',
            type: 0,
            _userId: 2,
            _createdDate: '06/22/2013',
            _updatedDate: '01/31/2011'
        },
        {
            id: 5,
            firstName: 'Ashley',
            lastName: 'Jandl',
            email: 'ajandl4@mapy.cz',
            userName: 'ajandl4',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '11/23/1996',
            ipAddress: '11.19.64.48',
            type: 1,
            _userId: 1,
            _createdDate: '01/30/2018',
            _updatedDate: '05/22/2014'
        },
        {
            id: 6,
            firstName: 'Mildrid',
            lastName: 'Duplan',
            email: 'mduplan5@msn.com',
            userName: 'mduplan5',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '4/21/1954',
            ipAddress: '104.18.128.93',
            type: 1,
            _userId: 1,
            _createdDate: '03/27/2011',
            _updatedDate: '09/02/2015'
        },
        {
            id: 7,
            firstName: 'Dall',
            lastName: 'Stow',
            email: 'dstow6@vistaprint.com',
            userName: 'dstow6',
            gender: 'Male',
            status: 2,
            dateOfBbirth: '4/14/1998',
            ipAddress: '168.199.143.20',
            type: 1,
            _userId: 1,
            _createdDate: '09/05/2011',
            _updatedDate: '06/21/2012'
        },
        {
            id: 8,
            firstName: 'Burton',
            lastName: 'Dering',
            email: 'bdering7@europa.eu',
            userName: 'bdering7',
            gender: 'Male',
            status: 1,
            dateOfBbirth: '8/15/1963',
            ipAddress: '204.7.174.42',
            type: 0,
            _userId: 1,
            _createdDate: '09/09/2017',
            _updatedDate: '06/27/2011'
        },
        {
            id: 9,
            firstName: 'Wolf',
            lastName: 'Blackaller',
            email: 'wblackaller8@biblegateway.com',
            userName: 'wblackaller8',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '5/20/1997',
            ipAddress: '12.229.194.195',
            type: 0,
            _userId: 1,
            _createdDate: '07/16/2011',
            _updatedDate: '05/24/2014'
        },
        {
            id: 10,
            firstName: 'Adham',
            lastName: 'Hurtic',
            email: 'ahurtic9@friendfeed.com',
            userName: 'ahurtic9',
            gender: 'Male',
            status: 1,
            dateOfBbirth: '3/15/1971',
            ipAddress: '236.147.173.25',
            type: 0,
            _userId: 2,
            _createdDate: '03/25/2011',
            _updatedDate: '12/13/2015'
        },
        {
            id: 11,
            firstName: 'Carlina',
            lastName: 'Scudders',
            email: 'cscuddersa@shareasale.com',
            userName: 'cscuddersa',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '2/18/1970',
            ipAddress: '189.61.76.155',
            type: 0,
            _userId: 1,
            _createdDate: '11/01/2015',
            _updatedDate: '02/16/2013'
        },
        {
            id: 12,
            firstName: 'Roderich',
            lastName: 'Landsberg',
            email: 'rlandsbergb@deliciousdays.com',
            userName: 'rlandsbergb',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '12/7/1963',
            ipAddress: '75.200.18.136',
            type: 0,
            _userId: 2,
            _createdDate: '12/12/2017',
            _updatedDate: '02/22/2013'
        },
        {
            id: 13,
            firstName: 'Mychal',
            lastName: 'Tremblett',
            email: 'mtremblettc@printfriendly.com',
            userName: 'mtremblettc',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '7/14/1996',
            ipAddress: '85.132.155.112',
            type: 0,
            _userId: 1,
            _createdDate: '08/14/2014',
            _updatedDate: '08/03/2014'
        },
        {
            id: 14,
            firstName: 'Corine',
            lastName: 'Rajchert',
            email: 'crajchertd@google.com',
            userName: 'crajchertd',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '9/13/1966',
            ipAddress: '47.164.200.96',
            type: 0,
            _userId: 2,
            _createdDate: '12/07/2010',
            _updatedDate: '09/24/2012'
        },
        {
            id: 15,
            firstName: 'Worth',
            lastName: 'McFarlane',
            email: 'wmcfarlanee@cocolog-nifty.com',
            userName: 'wmcfarlanee',
            gender: 'Male',
            status: 2,
            dateOfBbirth: '10/13/1968',
            ipAddress: '56.209.4.81',
            type: 0,
            _userId: 1,
            _createdDate: '02/23/2013',
            _updatedDate: '09/24/2016'
        },
        {
            id: 16,
            firstName: 'Ogden',
            lastName: 'Danielski',
            email: 'odanielskif@goo.ne.jp',
            userName: 'odanielskif',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '10/5/1984',
            ipAddress: '181.198.149.89',
            type: 0,
            _userId: 1,
            _createdDate: '09/08/2012',
            _updatedDate: '10/29/2017'
        },
        {
            id: 17,
            firstName: 'Laurette',
            lastName: 'Tift',
            email: 'ltiftg@about.me',
            userName: 'ltiftg',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '7/29/1964',
            ipAddress: '218.236.252.255',
            type: 1,
            _userId: 2,
            _createdDate: '05/03/2016',
            _updatedDate: '08/02/2011'
        },
        {
            id: 18,
            firstName: 'Eleanora',
            lastName: 'Jackson',
            email: 'ejacksonh@geocities.jp',
            userName: 'ejacksonh',
            gender: 'Female',
            status: 0,
            dateOfBbirth: '1/1/1993',
            ipAddress: '166.210.135.66',
            type: 1,
            _userId: 1,
            _createdDate: '09/25/2017',
            _updatedDate: '01/08/2012'
        },
        {
            id: 19,
            firstName: 'Manon',
            lastName: 'Perott',
            email: 'mperotti@nyu.edu',
            userName: 'mperotti',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '11/6/1994',
            ipAddress: '44.43.119.22',
            type: 1,
            _userId: 2,
            _createdDate: '04/26/2013',
            _updatedDate: '02/24/2012'
        },
        {
            id: 20,
            firstName: 'Karney',
            lastName: 'Damerell',
            email: 'kdamerellj@booking.com',
            userName: 'kdamerellj',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '11/22/1996',
            ipAddress: '253.195.21.217',
            type: 1,
            _userId: 2,
            _createdDate: '11/28/2014',
            _updatedDate: '10/11/2012'
        },
        {
            id: 21,
            firstName: 'Chelsea',
            lastName: 'Bruce',
            email: 'cbrucek@icq.com',
            userName: 'cbrucek',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '12/1/1983',
            ipAddress: '28.184.81.169',
            type: 0,
            _userId: 1,
            _createdDate: '07/29/2013',
            _updatedDate: '12/01/2017'
        },
        {
            id: 22,
            firstName: 'Barny',
            lastName: 'Verny',
            email: 'bvernyl@bing.com',
            userName: 'bvernyl',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '8/13/1957',
            ipAddress: '7.26.116.49',
            type: 0,
            _userId: 1,
            _createdDate: '06/14/2011',
            _updatedDate: '06/28/2011'
        },
        {
            id: 23,
            firstName: 'Elita',
            lastName: 'Matuszewski',
            email: 'ematuszewskim@businesswire.com',
            userName: 'ematuszewskim',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '6/6/1985',
            ipAddress: '82.223.2.228',
            type: 0,
            _userId: 2,
            _createdDate: '05/19/2016',
            _updatedDate: '09/12/2011'
        },
        {
            id: 24,
            firstName: 'Chelsey',
            lastName: 'Scullard',
            email: 'cscullardn@buzzfeed.com',
            userName: 'cscullardn',
            gender: 'Female',
            status: 0,
            dateOfBbirth: '11/20/1960',
            ipAddress: '68.177.196.151',
            type: 0,
            _userId: 1,
            _createdDate: '02/09/2013',
            _updatedDate: '12/11/2011'
        },
        {
            id: 25,
            firstName: 'Jodi',
            lastName: 'Olphert',
            email: 'jolpherto@elpais.com',
            userName: 'jolpherto',
            gender: 'Female',
            status: 0,
            dateOfBbirth: '4/20/2000',
            ipAddress: '32.219.74.161',
            type: 0,
            _userId: 2,
            _createdDate: '07/28/2017',
            _updatedDate: '05/03/2017'
        },
        {
            id: 26,
            firstName: 'Micah',
            lastName: 'Searchfield',
            email: 'msearchfieldp@instagram.com',
            userName: 'msearchfieldp',
            gender: 'Male',
            status: 1,
            dateOfBbirth: '8/15/1993',
            ipAddress: '99.205.47.226',
            type: 1,
            _userId: 1,
            _createdDate: '07/05/2011',
            _updatedDate: '02/19/2013'
        },
        {
            id: 27,
            firstName: 'Freeland',
            lastName: 'O\'Dougherty',
            email: 'fodoughertyq@cbsnews.com',
            userName: 'fodoughertyq',
            gender: 'Male',
            status: 1,
            dateOfBbirth: '5/19/1956',
            ipAddress: '91.116.249.149',
            type: 0,
            _userId: 1,
            _createdDate: '06/09/2012',
            _updatedDate: '03/15/2014'
        },
        {
            id: 28,
            firstName: 'Marna',
            lastName: 'Dacre',
            email: 'mdacrer@tmall.com',
            userName: 'mdacrer',
            gender: 'Female',
            status: 0,
            dateOfBbirth: '10/26/1959',
            ipAddress: '127.23.103.102',
            type: 0,
            _userId: 2,
            _createdDate: '12/30/2011',
            _updatedDate: '03/24/2016'
        },
        {
            id: 29,
            firstName: 'Carlita',
            lastName: 'Calwell',
            email: 'ccalwells@1688.com',
            userName: 'ccalwells',
            gender: 'Female',
            status: 0,
            dateOfBbirth: '7/28/1961',
            ipAddress: '3.205.16.120',
            type: 0,
            _userId: 2,
            _createdDate: '02/28/2016',
            _updatedDate: '02/25/2013'
        },
        {
            id: 30,
            firstName: 'Deonne',
            lastName: 'Castellan',
            email: 'dcastellant@gizmodo.com',
            userName: 'dcastellant',
            gender: 'Female',
            status: 2,
            dateOfBbirth: '10/21/1955',
            ipAddress: '213.3.222.52',
            type: 1,
            _userId: 2,
            _createdDate: '03/12/2011',
            _updatedDate: '03/26/2017'
        },
        {
            id: 31,
            firstName: 'Cesare',
            lastName: 'Chestnutt',
            email: 'cchestnuttu@cornell.edu',
            userName: 'cchestnuttu',
            gender: 'Male',
            status: 2,
            dateOfBbirth: '4/14/1979',
            ipAddress: '218.107.75.113',
            type: 1,
            _userId: 1,
            _createdDate: '03/06/2012',
            _updatedDate: '09/08/2012'
        },
        {
            id: 32,
            firstName: 'Gauthier',
            lastName: 'Luparto',
            email: 'glupartov@wordpress.org',
            userName: 'glupartov',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '11/28/1952',
            ipAddress: '148.234.79.85',
            type: 1,
            _userId: 2,
            _createdDate: '04/28/2013',
            _updatedDate: '02/26/2016'
        },
        {
            id: 33,
            firstName: 'Kimmi',
            lastName: 'Corde',
            email: 'kcordew@kickstarter.com',
            userName: 'kcordew',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '8/7/1978',
            ipAddress: '94.85.235.54',
            type: 0,
            _userId: 2,
            _createdDate: '10/21/2012',
            _updatedDate: '10/31/2017'
        },
        {
            id: 34,
            firstName: 'Riordan',
            lastName: 'Rosenbush',
            email: 'rrosenbushx@wix.com',
            userName: 'rrosenbushx',
            gender: 'Male',
            status: 1,
            dateOfBbirth: '11/28/1957',
            ipAddress: '236.15.158.122',
            type: 0,
            _userId: 2,
            _createdDate: '08/28/2011',
            _updatedDate: '08/14/2015'
        },
        {
            id: 35,
            firstName: 'Daune',
            lastName: 'Sellek',
            email: 'dselleky@amazon.co.jp',
            userName: 'dselleky',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '10/12/1995',
            ipAddress: '163.229.197.138',
            type: 1,
            _userId: 2,
            _createdDate: '03/28/2018',
            _updatedDate: '03/01/2017'
        },
        {
            id: 36,
            firstName: 'Myriam',
            lastName: 'Marshland',
            email: 'mmarshlandz@army.mil',
            userName: 'mmarshlandz',
            gender: 'Female',
            status: 2,
            dateOfBbirth: '8/1/1961',
            ipAddress: '153.40.246.134',
            type: 0,
            _userId: 1,
            _createdDate: '05/15/2017',
            _updatedDate: '08/12/2016'
        },
        {
            id: 37,
            firstName: 'Laryssa',
            lastName: 'Hynard',
            email: 'lhynard10@squidoo.com',
            userName: 'lhynard10',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '1/6/1998',
            ipAddress: '186.155.125.145',
            type: 0,
            _userId: 2,
            _createdDate: '01/16/2012',
            _updatedDate: '10/09/2012'
        },
        {
            id: 38,
            firstName: 'Evelin',
            lastName: 'Poate',
            email: 'epoate11@cnet.com',
            userName: 'epoate11',
            gender: 'Male',
            status: 2,
            dateOfBbirth: '9/11/1957',
            ipAddress: '76.53.54.233',
            type: 0,
            _userId: 1,
            _createdDate: '01/07/2012',
            _updatedDate: '01/18/2018'
        },
        {
            id: 39,
            firstName: 'Cesar',
            lastName: 'D\'Orsay',
            email: 'cdorsay12@ezinearticles.com',
            userName: 'cdorsay12',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '10/20/1992',
            ipAddress: '101.137.215.28',
            type: 1,
            _userId: 1,
            _createdDate: '10/20/2010',
            _updatedDate: '07/14/2012'
        },
        {
            id: 40,
            firstName: 'Jareb',
            lastName: 'Luberto',
            email: 'jluberto13@apple.com',
            userName: 'jluberto13',
            gender: 'Male',
            status: 1,
            dateOfBbirth: '12/22/1951',
            ipAddress: '69.111.152.125',
            type: 0,
            _userId: 1,
            _createdDate: '01/11/2012',
            _updatedDate: '01/20/2018'
        },
        {
            id: 41,
            firstName: 'Kerry',
            lastName: 'Glawsop',
            email: 'kglawsop14@wp.com',
            userName: 'kglawsop14',
            gender: 'Male',
            status: 1,
            dateOfBbirth: '11/28/1953',
            ipAddress: '105.245.81.58',
            type: 1,
            _userId: 2,
            _createdDate: '02/23/2015',
            _updatedDate: '03/14/2012'
        },
        {
            id: 42,
            firstName: 'Brose',
            lastName: 'Conti',
            email: 'bconti15@smh.com.au',
            userName: 'bconti15',
            gender: 'Male',
            status: 2,
            dateOfBbirth: '8/13/1967',
            ipAddress: '109.92.204.48',
            type: 1,
            _userId: 1,
            _createdDate: '08/04/2012',
            _updatedDate: '11/01/2011'
        },
        {
            id: 43,
            firstName: 'Shannah',
            lastName: 'Ceeley',
            email: 'sceeley16@sogou.com',
            userName: 'sceeley16',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '6/10/1962',
            ipAddress: '57.218.60.216',
            type: 0,
            _userId: 2,
            _createdDate: '05/28/2014',
            _updatedDate: '03/16/2018'
        },
        {
            id: 44,
            firstName: 'Rudolf',
            lastName: 'Stallworthy',
            email: 'rstallworthy17@theatlantic.com',
            userName: 'rstallworthy17',
            gender: 'Male',
            status: 1,
            dateOfBbirth: '10/15/1966',
            ipAddress: '74.223.255.220',
            type: 1,
            _userId: 2,
            _createdDate: '06/01/2015',
            _updatedDate: '10/25/2013'
        },
        {
            id: 45,
            firstName: 'Egor',
            lastName: 'Scotson',
            email: 'escotson18@weather.com',
            userName: 'escotson18',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '1/17/1956',
            ipAddress: '70.189.158.38',
            type: 0,
            _userId: 1,
            _createdDate: '03/25/2011',
            _updatedDate: '12/05/2010'
        },
        {
            id: 46,
            firstName: 'Selie',
            lastName: 'Dugue',
            email: 'sdugue19@meetup.com',
            userName: 'sdugue19',
            gender: 'Female',
            status: 0,
            dateOfBbirth: '11/9/1981',
            ipAddress: '54.129.221.70',
            type: 1,
            _userId: 1,
            _createdDate: '06/09/2013',
            _updatedDate: '08/10/2016'
        },
        {
            id: 47,
            firstName: 'Gene',
            lastName: 'Kirkbright',
            email: 'gkirkbright1a@wp.com',
            userName: 'gkirkbright1a',
            gender: 'Female',
            status: 0,
            dateOfBbirth: '5/9/1972',
            ipAddress: '217.224.216.105',
            type: 0,
            _userId: 1,
            _createdDate: '02/02/2016',
            _updatedDate: '03/30/2014'
        },
        {
            id: 48,
            firstName: 'Reagan',
            lastName: 'Colloby',
            email: 'rcolloby1b@usgs.gov',
            userName: 'rcolloby1b',
            gender: 'Male',
            status: 2,
            dateOfBbirth: '9/21/1997',
            ipAddress: '32.53.20.172',
            type: 0,
            _userId: 2,
            _createdDate: '04/08/2016',
            _updatedDate: '01/25/2014'
        },
        {
            id: 49,
            firstName: 'Saidee',
            lastName: 'Kivelle',
            email: 'skivelle1c@newsvine.com',
            userName: 'skivelle1c',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '10/23/1994',
            ipAddress: '79.152.227.238',
            type: 1,
            _userId: 1,
            _createdDate: '12/26/2015',
            _updatedDate: '09/17/2011'
        },
        {
            id: 50,
            firstName: 'Mychal',
            lastName: 'Karlowicz',
            email: 'mkarlowicz1d@opera.com',
            userName: 'mkarlowicz1d',
            gender: 'Male',
            status: 2,
            dateOfBbirth: '10/20/1971',
            ipAddress: '70.175.193.245',
            type: 0,
            _userId: 2,
            _createdDate: '10/10/2011',
            _updatedDate: '03/02/2012'
        },
        {
            id: 51,
            firstName: 'Nessie',
            lastName: 'Casassa',
            email: 'ncasassa1e@pinterest.com',
            userName: 'ncasassa1e',
            gender: 'Female',
            status: 2,
            dateOfBbirth: '3/9/1983',
            ipAddress: '17.128.173.230',
            type: 1,
            _userId: 1,
            _createdDate: '01/09/2018',
            _updatedDate: '08/05/2015'
        },
        {
            id: 52,
            firstName: 'Myrvyn',
            lastName: 'Coots',
            email: 'mcoots1f@sourceforge.net',
            userName: 'mcoots1f',
            gender: 'Male',
            status: 2,
            dateOfBbirth: '4/5/1972',
            ipAddress: '253.21.191.36',
            type: 0,
            _userId: 1,
            _createdDate: '01/13/2015',
            _updatedDate: '04/30/2011'
        },
        {
            id: 53,
            firstName: 'Liuka',
            lastName: 'Monard',
            email: 'lmonard1g@wikipedia.org',
            userName: 'lmonard1g',
            gender: 'Female',
            status: 2,
            dateOfBbirth: '6/6/1999',
            ipAddress: '33.46.177.2',
            type: 1,
            _userId: 1,
            _createdDate: '11/17/2012',
            _updatedDate: '02/18/2011'
        },
        {
            id: 54,
            firstName: 'Alis',
            lastName: 'Nozzolii',
            email: 'anozzolii1h@google.com.br',
            userName: 'anozzolii1h',
            gender: 'Female',
            status: 2,
            dateOfBbirth: '7/25/1953',
            ipAddress: '242.76.135.219',
            type: 1,
            _userId: 1,
            _createdDate: '10/16/2012',
            _updatedDate: '09/17/2012'
        },
        {
            id: 55,
            firstName: 'Gage',
            lastName: 'Slamaker',
            email: 'gslamaker1i@people.com.cn',
            userName: 'gslamaker1i',
            gender: 'Male',
            status: 1,
            dateOfBbirth: '3/19/1959',
            ipAddress: '179.85.197.219',
            type: 0,
            _userId: 2,
            _createdDate: '04/20/2015',
            _updatedDate: '07/02/2014'
        },
        {
            id: 56,
            firstName: 'Jamal',
            lastName: 'Glasard',
            email: 'jglasard1j@google.ru',
            userName: 'jglasard1j',
            gender: 'Male',
            status: 2,
            dateOfBbirth: '9/21/1968',
            ipAddress: '163.99.109.106',
            type: 0,
            _userId: 1,
            _createdDate: '08/24/2017',
            _updatedDate: '12/09/2012'
        },
        {
            id: 57,
            firstName: 'Antone',
            lastName: 'Christophersen',
            email: 'achristophersen1k@slashdot.org',
            userName: 'achristophersen1k',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '8/5/1953',
            ipAddress: '42.207.110.255',
            type: 1,
            _userId: 1,
            _createdDate: '12/30/2012',
            _updatedDate: '04/07/2013'
        },
        {
            id: 58,
            firstName: 'Glenn',
            lastName: 'Gentle',
            email: 'ggentle1l@imdb.com',
            userName: 'ggentle1l',
            gender: 'Female',
            status: 0,
            dateOfBbirth: '8/4/1959',
            ipAddress: '82.135.105.157',
            type: 1,
            _userId: 2,
            _createdDate: '01/16/2017',
            _updatedDate: '10/04/2017'
        },
        {
            id: 59,
            firstName: 'Jerrilyn',
            lastName: 'Prujean',
            email: 'jprujean1m@csmonitor.com',
            userName: 'jprujean1m',
            gender: 'Female',
            status: 2,
            dateOfBbirth: '12/22/1961',
            ipAddress: '45.111.98.145',
            type: 1,
            _userId: 2,
            _createdDate: '08/22/2014',
            _updatedDate: '09/01/2014'
        },
        {
            id: 60,
            firstName: 'Becki',
            lastName: 'Mucklestone',
            email: 'bmucklestone1n@wiley.com',
            userName: 'bmucklestone1n',
            gender: 'Female',
            status: 0,
            dateOfBbirth: '10/15/1970',
            ipAddress: '208.63.250.181',
            type: 0,
            _userId: 1,
            _createdDate: '03/08/2018',
            _updatedDate: '08/09/2016'
        },
        {
            id: 61,
            firstName: 'Kingsly',
            lastName: 'Sawday',
            email: 'ksawday1o@patch.com',
            userName: 'ksawday1o',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '1/20/1978',
            ipAddress: '196.38.7.53',
            type: 0,
            _userId: 2,
            _createdDate: '07/15/2016',
            _updatedDate: '10/28/2012'
        },
        {
            id: 62,
            firstName: 'Austen',
            lastName: 'Possel',
            email: 'apossel1p@blogtalkradio.com',
            userName: 'apossel1p',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '5/8/1960',
            ipAddress: '162.0.150.104',
            type: 1,
            _userId: 2,
            _createdDate: '12/20/2017',
            _updatedDate: '04/20/2018'
        },
        {
            id: 63,
            firstName: 'Rudie',
            lastName: 'Southouse',
            email: 'rsouthouse1q@mapy.cz',
            userName: 'rsouthouse1q',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '12/2/1950',
            ipAddress: '67.123.229.124',
            type: 1,
            _userId: 2,
            _createdDate: '09/24/2012',
            _updatedDate: '07/02/2014'
        },
        {
            id: 64,
            firstName: 'Julienne',
            lastName: 'Le Noury',
            email: 'jlenoury1r@cnet.com',
            userName: 'jlenoury1r',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '10/30/1976',
            ipAddress: '208.192.238.241',
            type: 1,
            _userId: 1,
            _createdDate: '06/29/2012',
            _updatedDate: '11/26/2016'
        },
        {
            id: 65,
            firstName: 'Bernard',
            lastName: 'Levicount',
            email: 'blevicount1s@angelfire.com',
            userName: 'blevicount1s',
            gender: 'Male',
            status: 2,
            dateOfBbirth: '12/6/1982',
            ipAddress: '123.229.51.62',
            type: 1,
            _userId: 2,
            _createdDate: '04/11/2013',
            _updatedDate: '05/14/2013'
        },
        {
            id: 66,
            firstName: 'Katine',
            lastName: 'Glazyer',
            email: 'kglazyer1t@unblog.fr',
            userName: 'kglazyer1t',
            gender: 'Female',
            status: 2,
            dateOfBbirth: '8/20/1954',
            ipAddress: '165.87.178.12',
            type: 0,
            _userId: 1,
            _createdDate: '03/20/2015',
            _updatedDate: '06/18/2015'
        },
        {
            id: 67,
            firstName: 'Rem',
            lastName: 'Crichten',
            email: 'rcrichten1u@patch.com',
            userName: 'rcrichten1u',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '6/19/1950',
            ipAddress: '184.177.108.6',
            type: 1,
            _userId: 1,
            _createdDate: '11/12/2014',
            _updatedDate: '04/12/2011'
        },
        {
            id: 68,
            firstName: 'Colman',
            lastName: 'Simnor',
            email: 'csimnor1v@reddit.com',
            userName: 'csimnor1v',
            gender: 'Male',
            status: 1,
            dateOfBbirth: '1/3/2000',
            ipAddress: '19.3.156.170',
            type: 0,
            _userId: 2,
            _createdDate: '05/06/2013',
            _updatedDate: '05/23/2014'
        },
        {
            id: 69,
            firstName: 'Arin',
            lastName: 'Hanscombe',
            email: 'ahanscombe1w@blogspot.com',
            userName: 'ahanscombe1w',
            gender: 'Male',
            status: 2,
            dateOfBbirth: '9/21/1955',
            ipAddress: '154.59.243.61',
            type: 0,
            _userId: 2,
            _createdDate: '06/23/2013',
            _updatedDate: '11/10/2011'
        },
        {
            id: 70,
            firstName: 'Emogene',
            lastName: 'Gruszka',
            email: 'egruszka1x@cmu.edu',
            userName: 'egruszka1x',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '7/3/1971',
            ipAddress: '105.138.138.125',
            type: 1,
            _userId: 2,
            _createdDate: '07/26/2016',
            _updatedDate: '10/28/2013'
        },
        {
            id: 71,
            firstName: 'Rosina',
            lastName: 'Kauffman',
            email: 'rkauffman1y@google.com.hk',
            userName: 'rkauffman1y',
            gender: 'Female',
            status: 2,
            dateOfBbirth: '11/21/1953',
            ipAddress: '131.87.248.164',
            type: 0,
            _userId: 1,
            _createdDate: '10/16/2016',
            _updatedDate: '07/24/2015'
        },
        {
            id: 72,
            firstName: 'Fran',
            lastName: 'MacGill',
            email: 'fmacgill1z@pinterest.com',
            userName: 'fmacgill1z',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '3/16/1991',
            ipAddress: '155.41.152.146',
            type: 1,
            _userId: 1,
            _createdDate: '11/23/2012',
            _updatedDate: '09/03/2016'
        },
        {
            id: 73,
            firstName: 'Marinna',
            lastName: 'Allington',
            email: 'mallington20@icio.us',
            userName: 'mallington20',
            gender: 'Female',
            status: 0,
            dateOfBbirth: '3/15/1964',
            ipAddress: '120.145.107.78',
            type: 0,
            _userId: 2,
            _createdDate: '09/07/2011',
            _updatedDate: '12/26/2017'
        },
        {
            id: 74,
            firstName: 'Irv',
            lastName: 'Rishbrook',
            email: 'irishbrook21@tripod.com',
            userName: 'irishbrook21',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '11/25/1966',
            ipAddress: '127.115.28.247',
            type: 1,
            _userId: 1,
            _createdDate: '08/25/2014',
            _updatedDate: '06/16/2013'
        },
        {
            id: 75,
            firstName: 'Riordan',
            lastName: 'Durran',
            email: 'rdurran22@hubpages.com',
            userName: 'rdurran22',
            gender: 'Male',
            status: 1,
            dateOfBbirth: '3/3/1952',
            ipAddress: '246.102.6.88',
            type: 1,
            _userId: 1,
            _createdDate: '04/02/2017',
            _updatedDate: '01/30/2011'
        },
        {
            id: 76,
            firstName: 'Phillie',
            lastName: 'Elward',
            email: 'pelward23@parallels.com',
            userName: 'pelward23',
            gender: 'Female',
            status: 2,
            dateOfBbirth: '9/15/1965',
            ipAddress: '75.220.58.105',
            type: 1,
            _userId: 1,
            _createdDate: '06/23/2014',
            _updatedDate: '08/20/2016'
        },
        {
            id: 77,
            firstName: 'Hadlee',
            lastName: 'Haddow',
            email: 'hhaddow24@nationalgeographic.com',
            userName: 'hhaddow24',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '10/2/1973',
            ipAddress: '155.40.18.171',
            type: 0,
            _userId: 2,
            _createdDate: '03/05/2016',
            _updatedDate: '07/03/2017'
        },
        {
            id: 78,
            firstName: 'Luciano',
            lastName: 'Pittford',
            email: 'lpittford25@europa.eu',
            userName: 'lpittford25',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '6/14/1963',
            ipAddress: '131.175.149.47',
            type: 0,
            _userId: 2,
            _createdDate: '05/01/2014',
            _updatedDate: '08/02/2011'
        },
        {
            id: 79,
            firstName: 'Zelma',
            lastName: 'Cumberpatch',
            email: 'zcumberpatch26@multiply.com',
            userName: 'zcumberpatch26',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '7/16/1956',
            ipAddress: '145.155.37.45',
            type: 1,
            _userId: 1,
            _createdDate: '11/07/2015',
            _updatedDate: '12/03/2014'
        },
        {
            id: 80,
            firstName: 'Thornton',
            lastName: 'Smuth',
            email: 'tsmuth27@go.com',
            userName: 'tsmuth27',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '5/9/1989',
            ipAddress: '146.151.187.52',
            type: 1,
            _userId: 2,
            _createdDate: '12/25/2011',
            _updatedDate: '12/31/2010'
        },
        {
            id: 81,
            firstName: 'Bunnie',
            lastName: 'Ealden',
            email: 'bealden28@gravatar.com',
            userName: 'bealden28',
            gender: 'Female',
            status: 0,
            dateOfBbirth: '10/2/1999',
            ipAddress: '184.116.180.222',
            type: 1,
            _userId: 2,
            _createdDate: '04/09/2015',
            _updatedDate: '12/07/2013'
        },
        {
            id: 82,
            firstName: 'Pearl',
            lastName: 'Robeson',
            email: 'probeson29@moonfruit.com',
            userName: 'probeson29',
            gender: 'Female',
            status: 0,
            dateOfBbirth: '8/20/1980',
            ipAddress: '235.213.39.68',
            type: 0,
            _userId: 1,
            _createdDate: '07/13/2016',
            _updatedDate: '03/07/2012'
        },
        {
            id: 83,
            firstName: 'Charlie',
            lastName: 'Marvelley',
            email: 'cmarvelley2a@archive.org',
            userName: 'cmarvelley2a',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '11/22/1969',
            ipAddress: '158.224.240.173',
            type: 0,
            _userId: 2,
            _createdDate: '03/25/2012',
            _updatedDate: '11/20/2012'
        },
        {
            id: 84,
            firstName: 'Vere',
            lastName: 'McCartan',
            email: 'vmccartan2b@about.me',
            userName: 'vmccartan2b',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '3/24/1956',
            ipAddress: '235.57.57.54',
            type: 0,
            _userId: 2,
            _createdDate: '06/21/2016',
            _updatedDate: '05/17/2015'
        },
        {
            id: 85,
            firstName: 'Deni',
            lastName: 'Frostdicke',
            email: 'dfrostdicke2c@jigsy.com',
            userName: 'dfrostdicke2c',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '7/31/1964',
            ipAddress: '108.183.237.176',
            type: 1,
            _userId: 1,
            _createdDate: '03/13/2013',
            _updatedDate: '11/12/2017'
        },
        {
            id: 86,
            firstName: 'Poppy',
            lastName: 'Pech',
            email: 'ppech2d@wsj.com',
            userName: 'ppech2d',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '2/24/1976',
            ipAddress: '221.35.18.213',
            type: 1,
            _userId: 1,
            _createdDate: '09/10/2015',
            _updatedDate: '02/13/2013'
        },
        {
            id: 87,
            firstName: 'Dasya',
            lastName: 'O\'Nion',
            email: 'donion2e@addthis.com',
            userName: 'donion2e',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '7/5/1993',
            ipAddress: '28.25.44.40',
            type: 0,
            _userId: 1,
            _createdDate: '04/12/2013',
            _updatedDate: '08/24/2012'
        },
        {
            id: 88,
            firstName: 'Marco',
            lastName: 'Izhakov',
            email: 'mizhakov2f@si.edu',
            userName: 'mizhakov2f',
            gender: 'Male',
            status: 1,
            dateOfBbirth: '2/28/1999',
            ipAddress: '155.123.153.36',
            type: 0,
            _userId: 1,
            _createdDate: '06/04/2012',
            _updatedDate: '03/06/2017'
        },
        {
            id: 89,
            firstName: 'Jane',
            lastName: 'Meneux',
            email: 'jmeneux2g@bluehost.com',
            userName: 'jmeneux2g',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '3/15/1962',
            ipAddress: '216.200.223.149',
            type: 1,
            _userId: 2,
            _createdDate: '11/24/2015',
            _updatedDate: '02/08/2012'
        },
        {
            id: 90,
            firstName: 'Audry',
            lastName: 'Glasheen',
            email: 'aglasheen2h@booking.com',
            userName: 'aglasheen2h',
            gender: 'Female',
            status: 2,
            dateOfBbirth: '10/24/1962',
            ipAddress: '186.134.95.243',
            type: 0,
            _userId: 1,
            _createdDate: '09/13/2016',
            _updatedDate: '04/28/2014'
        },
        {
            id: 91,
            firstName: 'Florida',
            lastName: 'Lawleff',
            email: 'flawleff2i@livejournal.com',
            userName: 'flawleff2i',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '2/3/1960',
            ipAddress: '88.32.51.173',
            type: 0,
            _userId: 1,
            _createdDate: '03/22/2018',
            _updatedDate: '11/28/2010'
        },
        {
            id: 92,
            firstName: 'Christoforo',
            lastName: 'Pinchon',
            email: 'cpinchon2j@bloglovin.com',
            userName: 'cpinchon2j',
            gender: 'Male',
            status: 2,
            dateOfBbirth: '2/26/1995',
            ipAddress: '244.162.142.84',
            type: 0,
            _userId: 2,
            _createdDate: '11/14/2017',
            _updatedDate: '11/19/2016'
        },
        {
            id: 93,
            firstName: 'Bertie',
            lastName: 'Antognazzi',
            email: 'bantognazzi2k@ow.ly',
            userName: 'bantognazzi2k',
            gender: 'Male',
            status: 2,
            dateOfBbirth: '6/7/1955',
            ipAddress: '108.174.189.179',
            type: 0,
            _userId: 2,
            _createdDate: '08/03/2016',
            _updatedDate: '01/27/2018'
        },
        {
            id: 94,
            firstName: 'Clevey',
            lastName: 'Grima',
            email: 'cgrima2l@cmu.edu',
            userName: 'cgrima2l',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '7/7/1981',
            ipAddress: '255.186.178.211',
            type: 1,
            _userId: 2,
            _createdDate: '03/23/2014',
            _updatedDate: '02/10/2011'
        },
        {
            id: 95,
            firstName: 'Herbie',
            lastName: 'Symper',
            email: 'hsymper2m@ca.gov',
            userName: 'hsymper2m',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '7/16/1993',
            ipAddress: '54.240.157.6',
            type: 0,
            _userId: 1,
            _createdDate: '07/27/2011',
            _updatedDate: '05/28/2013'
        },
        {
            id: 96,
            firstName: 'Kizzie',
            lastName: 'Clapson',
            email: 'kclapson2n@linkedin.com',
            userName: 'kclapson2n',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '1/26/1977',
            ipAddress: '171.243.142.104',
            type: 1,
            _userId: 2,
            _createdDate: '09/03/2011',
            _updatedDate: '10/14/2014'
        },
        {
            id: 97,
            firstName: 'Sigvard',
            lastName: 'Edwin',
            email: 'sedwin2o@mlb.com',
            userName: 'sedwin2o',
            gender: 'Male',
            status: 0,
            dateOfBbirth: '4/24/1966',
            ipAddress: '78.224.218.52',
            type: 0,
            _userId: 1,
            _createdDate: '05/20/2017',
            _updatedDate: '09/17/2013'
        },
        {
            id: 98,
            firstName: 'Boniface',
            lastName: 'Rodd',
            email: 'brodd2p@123-reg.co.uk',
            userName: 'brodd2p',
            gender: 'Male',
            status: 2,
            dateOfBbirth: '10/24/1978',
            ipAddress: '250.44.55.222',
            type: 1,
            _userId: 2,
            _createdDate: '04/21/2012',
            _updatedDate: '07/30/2012'
        },
        {
            id: 99,
            firstName: 'Dorena',
            lastName: 'Oliva',
            email: 'doliva2q@europa.eu',
            userName: 'doliva2q',
            gender: 'Female',
            status: 1,
            dateOfBbirth: '6/16/1987',
            ipAddress: '94.196.238.12',
            type: 0,
            _userId: 1,
            _createdDate: '10/23/2016',
            _updatedDate: '06/28/2014'
        },
        {
            id: 100,
            firstName: 'Sibylla',
            lastName: 'O\'Cahey',
            email: 'socahey2r@paginegialle.it',
            userName: 'socahey2r',
            gender: 'Female',
            status: 2,
            dateOfBbirth: '10/13/1958',
            ipAddress: '139.240.194.212',
            type: 1,
            _userId: 1,
            _createdDate: '11/25/2014',
            _updatedDate: '08/18/2015'
        }
    ];
    return CustomersTable;
}());
exports.CustomersTable = CustomersTable;

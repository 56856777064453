import { FormGroup, FormControl } from "@angular/forms";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";

import { PadraoException } from './../models/padrao-exception';
import { ToastService } from "../services/toast.service";
import { Subscription } from "rxjs";
import Swal, { SweetAlertOptions } from "sweetalert2";

export class ControlGeneric {

  form: FormGroup;
  public sub: Subscription;
  dtOptions: DataTables.Settings = {};

  alertConfirmConfigurationDefault: SweetAlertOptions = {
    title: "Deseja excluir esse registro?",
    type: 'warning',
    confirmButtonText: "Sim",
    confirmButtonColor: "#1dc9b7",
    cancelButtonText: "Não",
    cancelButtonColor: "#dc3545",
    showCancelButton: true
  };
  alertErrorConfigurationDefault: SweetAlertOptions = {
    type: 'error',
    confirmButtonColor: "#dc3545"
  };
  alertSuccessConfigurationDefault: SweetAlertOptions = {
    title: "Operação realizada com sucesso",
    type: 'success',
    confirmButtonColor: "#1dc9b7"
  };

  constructor(public router: Router,
    public activatedRoute: ActivatedRoute,
    public toastService: ToastService) {
    this.dtOptions = {
      paging: false,
      responsive: true,
      searching: false,
      ordering: false,
      info: false,
      language: {
        emptyTable: "",
        zeroRecords: ""
      }
    };

  }
  ngOnDestroy() {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }

  tratarExcecao(error: PadraoException, voltar?: boolean) {
    if (error.message != '') {
      this.toastService.error(error.message);
    }
    if (error.codigoErro == 511) {
      this.router.navigate(['/auth/login']);
    }
    if (voltar) {
      this.toastService.goBack();
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  swalQuestion(pergunta: string): Promise<boolean> {
    this.alertConfirmConfigurationDefault.title = pergunta;
    return Swal.fire(this.alertConfirmConfigurationDefault).then((resposta) => {
      return Promise.resolve(resposta.value);
    });
  }

  swalError(mensagem: string, icone?: string) {
    this.alertErrorConfigurationDefault.title = mensagem;
    Swal.fire(this.alertErrorConfigurationDefault);
  }

  enviarParametros(path: string, parametro: any) {
    let navigationExtras: NavigationExtras = {
      state: {
        param1: parametro
      }
    };
    this.router.navigate([path], navigationExtras);
  }

  alertConfirmImpressaoDefault: SweetAlertOptions = {
    type: 'warning',
    confirmButtonText: "Imprimir",
    confirmButtonColor: "#1dc9b7",
    cancelButtonText: "Cancelar",
    cancelButtonColor: "#dc3545",
    showCancelButton: true
  };

  swalQuestionWithObs(pergunta: string, obs: string): Promise<boolean> {
    this.alertConfirmConfigurationDefault.title = pergunta;
    this.alertConfirmConfigurationDefault.footer = obs;
    return Swal.fire(this.alertConfirmConfigurationDefault).then((resposta) => {
      return Promise.resolve(resposta.value);
    });
  }
}
"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var toast_service_1 = require("../../../services/toast.service");
var common_1 = require("@angular/common");
var pesquisa_generica_1 = require("../../../util/pesquisa-generica");
var perguntas_frequentes_service_1 = require("../../../administracao/perguntas-frequentes/perguntas-frequentes.service");
var perguntas_frequentes_1 = require("../../../models/perguntas-frequentes");
var modal_resposta_perguntas_frequentes_component_1 = require("../modal-resposta-perguntas-frequentes/modal-resposta-perguntas-frequentes.component");
var ModalPesquisaPerguntasFrequentesComponent = /** @class */ (function (_super) {
    __extends(ModalPesquisaPerguntasFrequentesComponent, _super);
    function ModalPesquisaPerguntasFrequentesComponent(activeModal, router, activatedRoute, toastService, perguntasFrequentesService, date, modalService) {
        var _this = _super.call(this, router, activatedRoute, toastService) || this;
        _this.activeModal = activeModal;
        _this.router = router;
        _this.activatedRoute = activatedRoute;
        _this.toastService = toastService;
        _this.perguntasFrequentesService = perguntasFrequentesService;
        _this.date = date;
        _this.modalService = modalService;
        _this.filtro = new perguntas_frequentes_1.PerguntasFrequentes();
        return _this;
    }
    ModalPesquisaPerguntasFrequentesComponent.prototype.ngOnInit = function () {
        this.pesquisar();
    };
    ModalPesquisaPerguntasFrequentesComponent.prototype.pesquisar = function (pagina) {
        var _this = this;
        this.perguntasFrequentesService.pesquisar(this.filtro, pagina).then(function (res) {
            _this.listaPerguntasFrequentes = res.objeto;
            _this.qtdPerguntasFrequentes = res.count;
        }).catch(function (error) {
            _this.tratarExcecao(error);
        });
    };
    ModalPesquisaPerguntasFrequentesComponent.prototype.mostrarResposta = function (obj) {
        var modalRef = this.modalService.open(modal_resposta_perguntas_frequentes_component_1.ModalRespostaPerguntasFrequentesComponent, { size: 'lg', windowClass: 'modal-padrao' });
        modalRef.componentInstance.pergunta = obj;
    };
    return ModalPesquisaPerguntasFrequentesComponent;
}(pesquisa_generica_1.PesquisaGenerica));
exports.ModalPesquisaPerguntasFrequentesComponent = ModalPesquisaPerguntasFrequentesComponent;
